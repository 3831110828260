<div class="header_container">
    <div class="filter_container">
        <ng-container *ngFor="let status of offerStatus, let i = index">
            <button class="filter_button customised" (click)="getOffersByStatus(status.title)"
                [attr.id]="status.title.replace(' ', '-')" [attr.disabled]="status.enable ? null : ''"
                [class.is-active]="status.selected">
                {{ status.title }}<ng-container
                    *ngIf="status.title == 'Archivée' || status.title == 'Suspendue'">s</ng-container>
            </button>
        </ng-container>
    </div>
</div>
<table class="datatable customised">
    <thead>
        <tr>
            <th>Intitulé des offres postées</th>
            <th>Nombre de<br>candidatures reçues</th>
            <th>Nombre de<br> candidatures non traitées</th>
            <th>Match</th>
            <th>Nombre de<br> candidats rencontrés</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let offer of displayedOffers">
            <td>{{ offer.name }}
                <ng-container *ngIf="offer.status === 'En ligne' && offer.matchmaking === false && isOlderThanOneMonth(offer.publicationDate)">
                    <br><br>
                   <span class="tag">Cette offre est en ligne depuis plus d'un mois</span>
                    <br>
                </ng-container>
            </td>
            <td>{{ offer.nbApplications }}</td>
            <td>{{ offer.nbApplicationsPending }}</td>
            <td>
                <div class="radioboxes">
                    <label class="radiobox">
                        <input type="radio" [value]="true" [checked]="offer.matchmaking"
                            (click)="setMatchmakingTrue(offer)" />
                        <span>Oui</span>
                    </label>
                    <label class="radiobox">
                        <input type="radio" [value]="false" [checked]="!offer.matchmaking"
                            (click)="setMatchmakingFalse(offer)" />
                        <span>Non</span>
                    </label>
                </div>
            </td>
            <td>
                <form class="form_container" [ngStyle]="{'gap': offer.isEditing ? '30px' : '60px' }">
                    <ng-container *ngIf="!offer.isEditing; else editingMode">
                        <span>{{ offer.nbCandidatesMet }}</span>
                        <button (click)="startEditing(offer)" class="btn-edit">
                            <img src="/assets/images/icones/edit.svg" alt="edit icon" class="customised">
                        </button>
                    </ng-container>
                    <ng-template #editingMode>
                        <input type="number" [(ngModel)]="offer.nbCandidatesMet" class="input-number customised"
                            pattern="\d*" min="0">
                        <button (click)="saveChanges(offer)" class="btn-submit">Ok</button>
                    </ng-template>
                </form>
            </td>
        </tr>
    </tbody>
</table>

<div class="pagination" *ngIf="showPagination">
    <button [disabled]="currentPage === 1" (click)="prevPage()">
        <dep-icon [icon]="'icon-arrow_back_ios'" class="icon-arrow"></dep-icon>
    </button>
    <button *ngIf="visiblePages[0] > 1" (click)="goToPage(1)" class="customised">1</button>
    <button *ngIf="visiblePages[0] > 2" disabled>...</button>
    <button *ngFor="let page of visiblePages" [class.active]="page === currentPage" class="customised"
        (click)="goToPage(page)">{{ page }}</button>
    <button *ngIf="visiblePages[visiblePages.length - 1] < pages.length - 1" disabled>...</button>
    <button *ngIf="visiblePages[visiblePages.length - 1] < pages.length" (click)="goToPage(pages.length)"
        class="customised">{{ pages.length }}</button>
    <button [disabled]="currentPage === pages.length" (click)="nextPage()">
        <dep-icon [icon]="'icon-arrow_forward_ios'" class="icon-arrow"></dep-icon>
    </button>
</div>