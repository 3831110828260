<ul
  class="article-image-carousel"
  aria-label="Galerie d’images"
  aria-live="polite"
  tabindex="0"
  (scroll)="scrollEvent()"
  #carousel
>
  <ng-container *ngFor="let slide of carouselSlides">
    <li class="article-image-carousel__slide-wrapper">
      <ng-container [ngTemplateOutlet]="slide.slideTemplate"></ng-container>
    </li>
  </ng-container>
</ul>
<div class="article-image-carousel__footer">
  <p class="article-image-carousel__title">{{carouselTitle}}</p>
  <p class="article-image-carousel__source">{{carouselSource}}</p>
  <ng-container *ngIf="carouselSlides.length > 1">
    <!-- Go to previous image -->
    <button
      class="article-image-carousel__button article-image-carousel__previous"
      (click)="scrollToDirection(-1)"
      #buttonPrevious
    >
      <dep-icon [icon]="'icon-arrow_back'"></dep-icon>
      <span class="sr-only">Image précédente</span>
    </button>
    <!-- Carousel scroll indicator -->
    <div class="article-image-carousel__progressbar-container" #progressBarContainer>
      <div
        class="article-image-carousel__progressbar"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        #progressBar
      ></div>
    </div>
    <!-- Go to next image -->
    <button
      class="article-image-carousel__button article-image-carousel__next"
      (click)="scrollToDirection(1)"
      #buttonNext
    >
      <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
      <span class="sr-only">Image suivante</span>
    </button>
  </ng-container>
</div>
