import { Component, Input, OnInit } from '@angular/core';
import { Video } from 'src/app/models/videos/videos.model';

@Component({
  selector: 'dep-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {

  
  @Input() videos: Video[];

  constructor() { }

  ngOnInit(): void {
   
  }

}
