import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Membership } from 'src/app/models/memberships/membership.model';
import { Notification } from 'src/app/models/memberships/notification.model';
import { SuDetails } from 'src/app/models/observatory/su-details/SuDetails';
import { MembershipService } from 'src/app/services/memberships/membership.service';
import { SuDetailsService } from 'src/app/services/observatory/su-details.service';
import { environment } from 'src/environments/environment';
declare const dsLib: any;
declare var grecaptcha: any;
@Component({
  selector: 'dep-create-membership',
  templateUrl: './create-membership.component.html',
  styleUrls: ['./create-membership.component.scss'],
  providers: [MembershipService],
})
export class CreateMembershipComponent implements OnInit {

  membership: Membership;
  captchaError = false;
  responseCaptcha: string;
  siteKeyCaptcha: string;
  selectLanguage: string;
  hasOrganisme: boolean;
  enabled: boolean = true;
  techFieldOptions = [];
  organizationOptions = [];
  public startups: SuDetails[] = [];
  public sirenChecked: boolean;
  public isNotification: boolean;
  sectors = [
    'Energie et Greentech',
    'Numérique : IA et ordinateur quantique',
    'Industrie 4.0 : Robotique et IoT',
    'Agritech et Foodtech',
    'Biotech, Medtech et e-santé'
  ];
  messageEmailFound: string;
  emailFound: boolean;
  toasterSuccess = false;
  toasterFailure = false;

  public communityKnowledgeSources: string[] = [
    "Un autre entrepreneur deeptech",
    "Votre structure d'accompagnement",
    "Le site LesDeeptech.fr",
    "La presse, médias ou réseaux sociaux",
    "Votre chargé d'affaires ou autre interlocuteur Bpifrance",
    "Autre (veuillez préciser)"
  ];

  public communityKnowledge: string = "";
  public otherCommunityValue: string;


  membershipForm: FormGroup = this.formBuilder.group({
    startup: ['', Validators.required],
    yearOfCreation: ['', Validators.required],
    postalCode: ['', Validators.required],
    siren: ['', [Validators.required,Validators.pattern('^[0-9]{9}$')]],
    website: ['', [Validators.required,Validators.pattern('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')]],
    isClient: ['', Validators.required],

    techField: ['', Validators.required],
    activity: ['', Validators.required],
    researchLink: ['', Validators.required],
    isOTT: ['', Validators.required],
    tto: ['', Validators.required],
    isPatent: ['', Validators.required],
    patent: ['', Validators.required],
    technology: ['', Validators.required],    
    addedValue: ['', Validators.required],
    cks: ['', Validators.required],
    otherCommunity: ['', Validators.required], 

    lastname: ['', Validators.required],
    firstname: ['', Validators.required],
    job: ['', Validators.required],
    email: ['', [Validators.required,Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
    phone: [''],

    newsletter: [false]
  });
     

  get startup() { return this.membershipForm.get('startup'); }
  get yearOfCreation() { return this.membershipForm.get('yearOfCreation'); }
  get postalCode() { return this.membershipForm.get('postalCode'); }
  get siren() { return this.membershipForm.get('siren'); }
  get website() { return this.membershipForm.get('website'); }
  get isClient() { return this.membershipForm.get('isClient'); }

  get techField() { return this.membershipForm.get('techField'); }
  get activity() { return this.membershipForm.get('activity'); }
  get researchLink() { return this.membershipForm.get('researchLink'); }
  get isOTT() { return this.membershipForm.get('isOTT'); }
  get tto() { return this.membershipForm.get('tto'); }
  get isPatent() { return this.membershipForm.get('isPatent'); }
  get patent() { return this.membershipForm.get('patent'); }
  get technology() { return this.membershipForm.get('technology'); }
  get addedValue() { return this.membershipForm.get('addedValue'); }
  get cks() { return this.membershipForm.get('cks'); }
  get otherCommunity() { return this.membershipForm.get('otherCommunity'); }

  get lastname() { return this.membershipForm.get('lastname'); }
  get firstname() { return this.membershipForm.get('firstname'); }
  get job() { return this.membershipForm.get('job'); }
  get email() { return this.membershipForm.get('email'); }
  get phone() { return this.membershipForm.get('phone'); }

  get newsletter() { return this.membershipForm.get('newsletter'); }

  constructor(
    private membershipService: MembershipService,
    private meta: Meta,
    private titleService: Title,
    private suDetailsService: SuDetailsService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder
  ) {
    this.membership = new Membership();
    this.techFieldOptions = this.getTechFieldOptions();
    this.organizationOptions = this.getOrganizationOptions();
    this.renderer.addClass(document.body, 'v2021');
    this.siteKeyCaptcha = environment.siteKeyCaptcha;
    window["getResponseCaptcha"] = this.getResponseCaptcha.bind(this);

  }

  getTechFieldOptions(): any[] {
    return [
      'Technologies médicales',
      'Pharmacie et biotechnologies',
      'Numérique, technologies logicielles et communication',
      'Matériaux, mécanique et procédés industriels',
      'Electronique, traitement du signal et instrumentation',
      'Chimie et environnement',
      'Autre',
    ];
  }

  getOrganizationOptions(): any[] {
    return [
      'APHP',
      'CEA',
      'CIRAD',
      'CNES',
      'CNRS',
      'Erganeo',
      'GENOPOLE',
      'I.R.D',
      'IFPEN IFP',
      'IFREMER',
      'IFSTTAR',
      'INRAE',
      'INRIA',
      'INSERM',
      'Institut Curie',
      'Institut Pasteur',
      'Linksium',
      'ONERA',
      'PSL',
      'Pulsalys',
      'SATT Aquitaine Sciences Transfert',
      'SATT AxLR',
      'SATT Conectus',
      'SATT LUTECH',
      'SATT Nord',
      'SATT Ouest Valorisation',
      'SATT Paris Saclay',
      'SATT Sayens',
      'SATT Sud Est',
      'SATT Toulouse Tech Transfer',
      'Autre',
    ];
  }

  ngOnInit(): void {
    new dsLib.InputPhone('phone');
    this.initRecaptcha();
    this.titleService.setTitle(
      "Formulaire d'inscription à la communauté deeptech"
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Pour rejoindre la communauté des startups deeptech, décrivez-nous votre technologie de rupture, sa valeur ajoutée et vos liens avec la recherche publique ou privée.',
    });
    this.tto.disable();
    this.patent.disable();
    this.otherCommunity.disable();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
  }

  onSubmit(): void {
    
    this.getSubscriber();
    this.getResponseCaptcha();
    this.getStartup();
    this.getDomain();
    this.subscribeStartup();

    if(!this.membershipForm.valid){
      Object.keys(this.membershipForm.controls).forEach(field => { 
        const control = this.membershipForm.get(field);  
        control.markAsDirty();
      });
    }
  }

  getResponseCaptcha(): void {
    this.responseCaptcha = grecaptcha.getResponse();
    if (this.responseCaptcha.length === 0) {
      this.captchaError = true;
    } else {
      this.captchaError = false;
      this.membership.recaptchaResponse = this.responseCaptcha;
    }
  }

  private initRecaptcha(): void {
    setTimeout(() => {
      grecaptcha?.render('captcha_element2', {
        sitekey: this.siteKeyCaptcha,
        hl: 'fr'
      });
    }, 1000);
  }

  getSubscriber(): void {
    /*data user*/
    this.membership.firstname = this.firstname.value;
    this.membership.lastname = this.lastname.value;
    this.membership.phone = this.phone.value;
    this.membership.email = this.email.value;
    this.membership.job = this.job.value;
  }
  getStartup(): void {
    /*data startup*/
    this.membership.startup = this.startup.value;
    this.membership.siren = this.siren.value;
    this.membership.website = this.website.value;
    this.membership.yearOfCreation = Number.parseInt(this.yearOfCreation.value);
    this.membership.postalCode = this.postalCode.value;
    this.membership.bpiClient = this.isClient.value;
    this.membership.newsletter = this.newsletter.value;

  }
  getDomain(): void {
    /*data dep*/

    this.membership.techField = this.techField.value;
    this.membership.activity = this.activity.value;
    this.membership.addedValue = this.addedValue.value;
    this.membership.researchLink = this.researchLink.value;
    this.membership.beneficiaryTTO = this.isOTT.value;
    this.membership.organization = this.isOTT.value ? this.tto.value : "";
    this.membership.technology = this.technology.value;
    this.membership.patent = this.isPatent.value;
    this.membership.patentOrg = this.patent.value ? this.patent.value : "";
    this.membership.communityKnowledge = this.cks.value === "Autre (veuillez préciser)" ? "Autre: " + this.otherCommunity.value : this.cks.value;

  }

  subscribeStartup(): void {
    if (this.membershipForm.valid && this.membership.recaptchaResponse.length > 0) {

      this.isSUExistant();

    }
  }

  closeToaster(): void {
    this.toasterFailure = false;
    this.toasterSuccess = false;
  }

  itTTO(hasOrganisme: boolean) {
    if (hasOrganisme) {
      this.tto.enable();
    }
    else {
      this.tto.disable();
      this.tto.setValue("");
    }
  }

  itPatent(hasOrganisme: boolean) {
    if (hasOrganisme) {
      this.patent.enable();
    }
    else {
      this.patent.disable();
      this.patent.setValue("");
    }

  }

  private isSUExistant(): void {
    this.suDetailsService.getSuDetails().subscribe(
      results => {
        results.forEach(s => {
          if (this.membership.siren === s.siren.toString()) {
            this.membership.observatory = true;
          }
        })
      }
      ,
      (error) => console.error(error),
      () => this.putMembership()
    );
  }

  private putMembership() {
    this.membershipService.createMembership(this.membership).subscribe(
      (data) => {
        if (data.emailFound || !data.subscriptionDone) {
          this.messageEmailFound = data.message;
          this.toasterFailure = true;
          this.membership.recaptchaResponse = "";
        } else {
          this.contactStartup();
          this.toasterSuccess = true;
          this.membershipForm.reset();
        }
        grecaptcha.reset();
      },
      (error) => {
        this.messageEmailFound = 'Votre demande n’a pas pu être envoyée, veuillez réessayer ultérieurement.';
        this.toasterFailure = true;
        console.log('error :: ' + error);
      }
    );
  }

  contactStartup() {
    const dataNotification: Notification = {
      firstname: this.membership.firstname,
      lastname: this.membership.lastname,
      email: this.membership.email
    };

    if (this.membership.observatory) {
      this.membershipService.contactExistingStartup(dataNotification).subscribe();
    }
    else {
      this.membershipService.contactNewStartup(dataNotification).subscribe();
    }
  }

  enableOthersTextArea() {
    if (this.cks.value === "Autre (veuillez préciser)") {
      this.otherCommunity.enable();
      this.otherCommunity.addValidators([Validators.required])
      this.otherCommunity.updateValueAndValidity();
    } else {
      this.otherCommunity.disable();
      this.otherCommunity.clearValidators();
      this.otherCommunity.setValue("");
    }
  }

}


