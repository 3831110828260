import { DatePipe, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { polyfill } from 'smoothscroll-polyfill';
import { EventCalendar } from 'src/app/models/events/events-calendar.model';
import { Tag } from 'src/app/models/tags/tag.model';
import { EventService } from 'src/app/services/events/event.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';

// Polyfill for smooth scrolling on Safari and older browsers
polyfill();

@Component({
  selector: 'dep-page-header-timeline',
  templateUrl: './page-header-timeline.component.html',
  styleUrls: ['./page-header-timeline.component.scss'],
})
export class PageHeaderTimelineComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() displayTimeline: boolean = true;
  @ViewChild('timeline') timelineView: ElementRef;
  private destroy$ = new Subject<void>();
  // Scroll buttons
  @ViewChild('buttonPrevious') buttonPrevious: ElementRef;
  @ViewChild('buttonNext') buttonNext: ElementRef;
  @Output() newDateEvent = new EventEmitter();
  @Output() internationalTagEmitter=new EventEmitter();

  public timelineTotalWidth: number;
  public timelineVisibleWidth: number;
  public timelineScrollLeft: number;
  public isShowPrevious: boolean = false;
  public isShowNext: boolean = true;
  public count: number = 0;
  public date: string = new Date().toString();

  private limit: number = 4;
  public eventsCount: number;
  public eventsCalendar: EventCalendar[] = [];
  public selectedIndexMonth: number = 0;
  private internationalTag:Tag;

  constructor(
    @Inject(EventService) private eventService: EventService,
    private datePipe: DatePipe,
    private location: Location,
    private sharedService:SharedService
  ) {
    this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.detectDevice();
    if (this.displayTimeline)
      this.getInternationalTag();
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   * To get the next months
   */
  public getNextMonths() {
    this.count++;
    this.checkCalendarToShow();
    setTimeout(() => {
      this.scrollToDirection(1);
    }, 100);
  }

  /**
   * To get the previous month
   */
  public getPreviousMonths() {
    this.count--;
    this.checkCalendarToShow();
    setTimeout(() => {
      this.scrollToDirection(-1);
    }, 100);
  }

  scrollToDirection(direction: number): void {
    const timeline = this.timelineView.nativeElement;
    timeline.scrollBy({
      left: direction * timeline.clientWidth,
      behavior: 'smooth',
    });
  }

  public selectEventMonth(event: EventCalendar, index?: number) {
    if (index >= 0) {
      this.selectedIndexMonth = index;
    }
    this.newDateEvent.emit(event);
  }

  /**
   * Get events calendar
   */
  private getEventsCalendar(excludedTagId?:number): void {
    this.eventService
      .getEventsCalendar(this.date, excludedTagId, undefined, undefined, ["EPub"])
      .subscribe((result) => {
        this.eventsCalendar = result;
        this.selectEventMonth(result[0]);
      });
  }

  checkCalendarToShow():void{
    if(this.count<=0){
      this.count = 0;
      this.isShowPrevious = false;
    }else{
      this.isShowPrevious = true;
    }
    if(this.eventsCalendar.length < this.count*this.limit){
      this.isShowNext = false;
    }
  }

  /**
   * Go back
   */
  back(): void {
    this.location.back();
  }
  /**
   * detect swipe in mobile
   */
  public swipeRight() {
    this.getPreviousMonths();
  }
  /**
   * detect swipe in mobile
   */
  public swipeLeft() {
    this.getNextMonths();
  }

  /**
   * detect Duvace if mobile or descktop
   */
  private detectDevice() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.limit = 3;
      // document.write("mobile device");
    } else {
      // false for not mobile device
      this.limit = 4;
      // document.write("not mobile device");
    }
  }

  private async getInternationalTag() {
    this.internationalTag = this.sharedService.tag;
    if (this.internationalTag) {
      this.getEventsCalendar(this.internationalTag?.id);
      this.emitIntTag(this.internationalTag);
    } else {
      await this.sharedService.retrieveTag().then(
        (result) => {
          this.getEventsCalendar(result?.id);
          this.emitIntTag(result);
        }
      );
    }
  }

  public emitIntTag(intTag:Tag):void{
    this.internationalTagEmitter.emit(intTag);
  }
}
