import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-article-quote',
  templateUrl: './article-quote.component.html',
  styleUrls: ['./article-quote.component.scss']
})
export class ArticleQuoteComponent implements OnInit {
  @Input() quote: string;
  @Input() quoteSource: string;

  constructor() { }

  ngOnInit(): void {
  }

}
