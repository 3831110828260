import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.scss']
})
export class PartnersListComponent implements OnInit {
  @Input() partners: any[];
  @Input() isHomePage: boolean;
  @Input() responsiveDisplay: string;

  constructor() { }

  ngOnInit(): void {
  }

}
