<div
  class="actualities-list"
  [class.actualities-list_responsive-carousel]="responsiveDisplay == 'carousel'"
  aria-live="polite"
>
  <ng-container *ngFor="let actuality of actualities; let i = index">
    <dep-actuality-thumbnail
      [class.is-highlighted]="highlightFirst && i == 0"
      [actuality]="actuality"
      [actualityIndex]="i"
    >
    </dep-actuality-thumbnail>
  </ng-container>
</div>
