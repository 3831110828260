import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-numbers-grid',
  templateUrl: './numbers-grid.component.html',
  styleUrls: ['./numbers-grid.component.scss']
})
export class NumbersGridComponent implements OnInit {
  @Input() numbersColumns: number;

  constructor() { }

  ngOnInit(): void {
  }

}
