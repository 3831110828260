import { Injectable } from '@angular/core'
import * as FeatureFlags from 'src/app/shared/utils/feature-flags.json';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlippingService {
    private featureFlags: any;

    constructor(){
        this.featureFlags = FeatureFlags !== null? FeatureFlags : {};
    }

    getFeatureFlagsByEnv(env:string) {
        return this.featureFlags[env];
    }
}