import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TrainingFilter } from 'src/app/models/filters/filter.model';
import { Tag } from 'src/app/models/tags/tag.model';
import { TagRequest } from 'src/app/models/tags/tagRequest.model';
import { TrainingRequest } from 'src/app/models/training/training-request.model';
import { Training } from 'src/app/models/training/training.module';
import { TagService } from 'src/app/services/tags/tag.service';
import { TrainingService } from 'src/app/services/training/training.service';
import { PreviewCardService } from 'src/app/shared/services/preview-card/preview-card.service';

@Component({
  selector: 'dep-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent implements OnInit, OnDestroy {
  @ViewChild('progressbar') progressbarRef: ElementRef;
  trainingsDisplayed = 0;
  trainingsTotal = 0;
  trainingsProgress = 0;
  public limit: number = 9;
  trainings: Training[] = [];
  tags: Tag[] = [];
  filtered: boolean = false;
  isValidated: boolean = true;
  private trainingRequest: TrainingRequest = new TrainingRequest();
  private trainingType = 'porteurs_de_projet';

  constructor(
    private renderer: Renderer2,
    private trainingService: TrainingService,
    private tagService: TagService,
    private titleService: Title,
    private meta: Meta) {
    this.renderer.addClass(document.body, 'v2021');
  }


  ngOnInit(): void {
    this.titleService.setTitle("Les formations à l'entrepreneuriat deeptech");
    this.meta.addTag({
      name: 'description',
      content: 'Découvrez toutes les formations pour apprendre gratuitement et en ligne les bases de la création de startup.'
    });
    this.initTrainingRequest();
    this.getCountTrainings(this.trainingType);
    
  }


  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
    
  }

  /**
   * Load more formations when clicking the button
   */
  loadMoreformations(): void {
    this.trainingRequest.start += this.limit;
    if (this.trainingRequest.start > this.trainingsTotal) {
      this.trainingRequest.start = this.trainingsTotal;
    }
    this.getTrainings(this.trainingRequest);
  }

  /**
   * Update the progress bar dimension to reflect the percentage of displayed formations
   */
  actualizeProgressBar(): void {
    this.trainingsProgress = this.trainingsDisplayed / this.trainingsTotal * 100;
    this.progressbarRef.nativeElement.style.setProperty('--progress', this.trainingsProgress);
  }

  getTrainings(trainingRequest: TrainingRequest): void {
    this.trainingService.getTrainings(trainingRequest).subscribe(
      results => {
        results.forEach(element => {
          this.trainings.push(element);
        });
        this.trainingsDisplayed += results.length;
        this.isValid(results.length);
      },
      error => console.log(error)
    );
    if (!this.filtered) {
      setTimeout(() => {
        this.actualizeProgressBar();
      }, 1000);
    }

  }
  getFilteredTrainings(trainingFilter: TrainingFilter): void {
    this.filtered = trainingFilter.tagIds.length === 0 ? false : true;
    this.trainings = [];
    this.trainingsDisplayed = 0;
    this.initTrainingRequest();
    this.trainingRequest.tagsIds = trainingFilter.tagIds;
    this.getTrainings(this.trainingRequest);

  }

  private initTrainingRequest(): void {
    this.trainingRequest.limit = this.limit;
    this.trainingRequest.start = 0;
    this.trainingRequest.type = this.trainingType;
  }

  getCountTrainings(type: string): void {
    this.trainingService.getTrainingsCount(type).subscribe(result => {
      this.trainingsTotal = result;
    },
      error => console.log(error),
      () => {
        this.getTrainings(this.trainingRequest);
        this.getTrainingsTagsByType('porteurs_de_projet');
      }
    );

  }
  getTrainingsTagsByType(type: string): void {
    let tagRequest: TagRequest = new TagRequest();
    tagRequest.collectionName = "formations";
    tagRequest.collectionType = type;
    this.tagService.getTags(tagRequest).subscribe(
      results => {
        this.tags = results;
      }
    );
  }
  /**
  * Check if the training count is superior to progress bar value
  * @returns boolean
  */
  isValid(resultslength: number): void {
    if (this.filtered)
      this.isValidated = this.trainingsDisplayed % this.limit === 0 && resultslength != 0;
    else
      this.isValidated = this.trainings.length < this.trainingsTotal;
  }
}
