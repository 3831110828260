import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { Category } from 'src/app/models/categories/category.model';
import { Tag } from 'src/app/models/tags/tag.model';
import { TagService } from 'src/app/services/tags/tag.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {



  /**
   * Category Service
   */
  private categories: Category[];
  public eventTypeSubject: Subject<string> = new Subject();;
  
  constructor(private tagService: TagService, private oidcSecurityService: OidcSecurityService) { }


  getSecureHeaders(body?:any,params?:HttpParams) {
    let httpOptions = {
      headers: new HttpHeaders(),
      body: body,
      params:params
    };
    
    this.oidcSecurityService.getIdToken(sessionStorage.getItem('configId')).subscribe(
      result => {
        httpOptions.headers = httpOptions.headers.append('Custom-Auth', 'Bearer ' + result)
      },
      (error) => console.error(error)
    )
    return httpOptions;
  }
  /**
   * get categories
   * @returns 
   */
  public getCategories() {
    return this.categories;

  }
  /**
   * setcategories in app
   * @param categories 
   */
  public setCategories(categories: Category[]) {
    this.categories = categories
  }

  /**
   * International Tag Service
   */
  private internationalTag: Tag;
  private tags: Tag[] = [];
  /**getter */
  public get tag(): Tag {
    return this.internationalTag;
  }

  /**setter */
  public async retrieveTag(): Promise<Tag> {

    this.tags = await this.tagService.getTags().toPromise();

    this.internationalTag = this.tags.find(tag => tag.name.toLocaleLowerCase().match('international'));

    return this.internationalTag;

  }

  public setEventType(v: string) {
    this.eventTypeSubject.next(v);
  }

  public formatPreviewImage(imageSource: string) {
    if (imageSource)
      return imageSource.substring(0, imageSource.lastIndexOf("/")) + "/small_" + imageSource.substring(imageSource.lastIndexOf("/") + 1)
  }

}
