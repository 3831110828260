import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  @Input() legend: string;

  constructor() { }

  ngOnInit(): void {
  }

}
