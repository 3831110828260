import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Region } from 'src/app/models/region/region.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  private REST_API_SERVER = '';
  private REGIONS_URL = '';

  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
    this.REGIONS_URL = this.ressourcePath.getRegions();
  }

  getRegions(index?: number, limit?: number): Observable<Region[]> {

    let params: HttpParams = new HttpParams();
    if(index){
      params = params.append('index', index?.toString());
    }
    if(limit){
      params = params.append('limit', limit?.toString());
    }
    
    this.REGIONS_URL = this.ressourcePath.getRegions();
    return this.http.get<Region[]>(this.REST_API_SERVER + this.REGIONS_URL, { params });
  }
  getRegionById(regionId: number): Observable<Region> {
    this.REGIONS_URL = this.ressourcePath.getRegionById(regionId);
    return this.http.get<Region>(this.REST_API_SERVER + this.REGIONS_URL);
  }

}
