import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { interval,Subscription } from 'rxjs';

@Component({
  selector: 'dep-image-card-carousel',
  templateUrl: './image-card-carousel.component.html',
  styleUrls: ['./image-card-carousel.component.scss']
})
export class ImageCardCarouselComponent implements OnInit,OnDestroy {
  @Input() contents : any[] = [];
  currentIndex = 0;
  containerWidth : number; 
  innerWidth: number;
  isMobile : boolean = false;


  public slideConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: null,
    prevArrow: null,
  };

  constructor(){

  }
  ngOnInit() {
    this.detectWindowSize();
  }

  ngOnDestroy() {

  }

  detectWindowSize(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 599) {
       this.isMobile = true;
    }else{
      this.isMobile = false;
    }
  };
}