<div
  class="icon-text"
  [ngClass]="{'icon-text--left': alignContent === 'left',
              'icon-text--center': alignContent === 'center'}"
  >
  <img src="{{icon}}" class="icon-text__icon" alt="">
  <h3 class="icon-text__title" *ngIf="iconTitle">{{iconTitle}}</h3>
  <p class="icon-text__text">
    <ng-content></ng-content>
  </p>
</div>
