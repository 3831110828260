import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {
  @Input('buttonLabel') label: string;

  constructor() { }
  
  ngOnInit(): void {
  }

}
