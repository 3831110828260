export class EventRequest{
    page?:number;
    pageSize?:number;
    startDate:Date;
    endDate?:Date;
    sectorIds?:number[];
    monthly:boolean;
    regionalBranchIds?:number[];
    tagId?:number;
    excludeTagId?:number;
    technicalsTagCodes?:string[];
    regionEspacePartenaireFilterIds?:number[];
    formatIds?:number[]
}