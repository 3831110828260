import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dep-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit,OnChanges {
  @Input() status: string;
  @Input() redirection: boolean = false;
  @Input() redirectionUrl: string;
  @Input() toasterTitle: string;
  @Input() toasterText: string;
  @Input() show: boolean;
  @Output() closeToaster = new EventEmitter();

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.show == true) {
      setTimeout(() => {
        this.close();
        if( this.redirection && this.status === 'success'){
          window.location.href = this.redirectionUrl;
        }
      }, 3000);
    }
  }

  ngOnInit(): void {

  }

  public close(): void {
    this.show = false;
    this.closeToaster.emit();

  }

}
