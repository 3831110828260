import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-declare-invention',
  templateUrl: './declare-invention.component.html',
  styleUrls: ['./declare-invention.component.scss'],
})
export class DeclareInventionComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Envie d\'entreprendre - la déclaration d\'invention'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Les notions clés à connaître pour lancer une startup en lien avec un laboratoire de recherche: déclarer une invention.',
    });
  }
}
