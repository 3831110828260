import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-transfer-technologies',
  templateUrl: './transfer-technologies.component.html',
  styleUrls: ['./transfer-technologies.component.scss'],
})
export class TransferTechnologiesComponent implements OnInit {
  constructor(
    private viewportScroller: ViewportScroller,
    private meta: Meta,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Envie d\'entreprendre - licence de brevet technologique'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Comprendre le contrat de licence de propriété intellectuelle pour négocier sereinement le transfert de technologie vers votre startup',
    });
  }

  // Pour le scroll vers l'ancre
  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
