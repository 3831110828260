<div #contactContainer>
<div class="member_container" *ngIf="!isSelected">
    <input type="checkbox" class="save-btn" [(ngModel)]="isChecked" (click)="toggle()">
    <div class="member_wrapper">
        <div class="member_content">
            <div>
                <img src="{{photo}}" alt="profil" class="img-member customised" />
            </div>
            <div class="member_infos">
                <p>{{contact.firstName}} {{contact.lastName}}</p>
                <p>{{contact.organization.name}}</p>
            </div>
        </div>
        <div class="btn_cta">
            <button (click)="showDetails()">Consulter</button>
        </div>
    </div>
</div>
<!-- Details contact -->
<div *ngIf="isSelected" class="wrapper">
    <div class="member_details" [ngStyle]="{'width.px': containerWidth }">
        <div class="member_details-wrapper">
            <div class="btn_container">
                <input type="checkbox" class="save-btn save-details" [(ngModel)]="isChecked" (click)="toggle()">
                <button class="btn_close" (click)="closeDetails()">
                    <img src="/assets/images/icones/cancel.svg" alt="icon-cancel" class="icon customised" />
                </button>
            </div>
            <div class="member_content">
                <div>
                    <img src="{{photo}}" alt="profil" class="img-member customised" />
                </div>
                <div class="member_infos">
                    <p>{{contact.firstName}} {{contact.lastName}}</p>
                    <p>{{contact.organization.name}}</p>
                </div>
            </div>
            <div class="member_detail">
                <div class="info_wrapper" *ngIf="contact.email">
                    <strong>Adresse E-mail :</strong>
                    <div class="copy-info">
                        <input value="{{contact.email}}" type="text" readonly="readonly">
                        <span class="btn-copy" (click)="copyText(contact.email, true)">
                            <img src="/assets/images/icones/icon-copy.svg" alt="icon copy" class="customised" />
                            Copier
                        </span>
                    </div>
                    <div *ngIf="clipboardCopySuccess && isEmail" class="tooltip">
                        <span class="info-copied">Copié !</span>
                    </div>
                </div>
                <!-- pas de telephone pour l'instant -->
                <!-- <div class="info_wrapper" *ngIf="contact.phone">
                    <strong>Numéro de téléphone :</strong>
                    <div class="copy-info">
                        <p>{{contact.phone}}</p>
                        <span class="btn-copy" (click)="copyText(contact.phone , false)">
                            <img src="/assets/images/icones/icon-copy.svg" alt="icon copy" class="customised" />
                            Copier
                        </span>
                    </div>
                    <div *ngIf="clipboardCopySuccess && !isEmail" class="tooltip">
                        <span class="info-copied">Copié !</span>
                    </div>
                </div> -->
            </div>
            <!-- pas de linkedin pour l'instant -->
            <!-- <div class="btn_contact">
                <a class="ds-btn ds-btn--primary" [routerLink]="">Voir le profil Linkedin
                </a>
            </div> -->
        </div>
    </div>
</div>
</div>