import { Component, Input } from '@angular/core';
import { BftProvider } from 'src/app/models/bftlab/bftprovider.model';

@Component({
  selector: 'dep-card-structure',
  templateUrl: './card-structure.component.html',
  styleUrls: ['./card-structure.component.scss']
})
export class CardStructureComponent {
  @Input() structure: BftProvider;
  isEmail : boolean;
  clipboardCopySuccess = false;
  clipboardmessageDuration = 500;

  public copyText(text : string , isEmail : boolean): void {
    this.isEmail = isEmail;
    navigator.clipboard.writeText(text)
      .then(() => {
        this.clipboardCopySuccess = true;
        setTimeout(() => {
          this.clipboardCopySuccess = false;
        }, this.clipboardmessageDuration);
      })
      .catch(() => this.clipboardCopySuccess = false);
  }
}
