import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { Category } from '../../models/categories/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private REST_API_SERVER = '';
  private CATEGORIES = '';

  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getCategories(): Observable<Category[]> {
    this.CATEGORIES = this.ressourcePath.getCategories();
    return this.http.get<Category[]>(this.REST_API_SERVER + this.CATEGORIES);
  }
}
