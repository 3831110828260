import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Tag } from 'src/app/models/tags/tag.model';

@Component({
  selector: 'dep-article-sticky-header',
  templateUrl: './article-sticky-header.component.html',
  styleUrls: ['./article-sticky-header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.2s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ArticleStickyHeaderComponent implements OnInit, OnDestroy {
  @Input() articleTitle: string;
  @Input() articleCategories: Tag[];
  @Input() buttonIcon: string;
  @Input() buttonText: string;
  @Input() buttonMessage: string;
  @Input() showButtonMessage: boolean;
  @Output('buttonAction') buttonAction: EventEmitter<any> = new EventEmitter();
  @ViewChild('progressbar') progressbar: ElementRef;
  private eventOptions: boolean | { capture?: boolean, passive?: boolean };
  windowTotalHeight: number;
  windowVisibleHeight: number;
  windowScrollTop: number;
  showStickyHeaderTrigger = 400;
  showStickyHeader = false;

  constructor(private renderer: Renderer2, private ngZone: NgZone, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initiateScrollListener();
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll,  this.eventOptions as any);
  }

  initiateScrollListener(): void {
    let passiveSupported = false;
    try {
      const options = {
        get passive() { // This function will be called when the browser
          //   attempts to access the passive property.
          passiveSupported = true;
          return false;
        }
      };
    } catch (err) {
      passiveSupported = false;
    }
    if (passiveSupported) { // use the implementation on mozilla
      this.eventOptions = {
        capture: true,
        passive: true
      };
    } else {
      this.eventOptions = true;
    }
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scroll,  this.eventOptions as any);
    });
  }

  scroll = (): void => {
    this.windowScrollTop = window.document.documentElement.scrollTop;
    this.toggleHeaderDisplay();
    this.setProgressbarValues();
  }

  toggleHeaderDisplay(): void {
    // Show/ hide the header
    if (!this.showStickyHeader && (this.windowScrollTop >= this.showStickyHeaderTrigger)) {
      this.ngZone.run(() => {
        this.showStickyHeader = true;
        this.ref.detectChanges();
      });
    } else if (this.showStickyHeader && (this.windowScrollTop < this.showStickyHeaderTrigger)) {
      this.ngZone.run(() => {
        this.showStickyHeader = false;
        this.ref.detectChanges();
      });
    }
  }

  setProgressbarValues(): void {
    let timeOutId: any;
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      const roundedPercentage = Math.round(this.calcScrollPercent());
      if (this.progressbar) {
        this.renderer.setStyle(this.progressbar.nativeElement, '--scale', roundedPercentage / 100, 2);
        this.renderer.setAttribute(this.progressbar.nativeElement, 'aria-valuenow', roundedPercentage.toString());
      }
    }, 150);
  }

  calcScrollPercent(): number {
    this.windowTotalHeight = this.getScrollHeight();
    this.windowVisibleHeight = window.innerHeight;
    const windowHeightNotInViewPort =
      this.windowTotalHeight - this.windowVisibleHeight <= 0
        ? 0.1
        : this.windowTotalHeight - this.windowVisibleHeight;
    return (this.windowScrollTop / windowHeightNotInViewPort) * 100;
  }

  getScrollHeight(): number {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }

}
