<!-- Link to the glossary -->
<div class="form-link">
    <h2 class="form-link__title">{{title}}</h2>
    <p class="form-link__text">
        {{description}}
    </p>
    <a [href]="externalLink" target="_blank" rel="noopener noreferrer" class="form-link__button ds-btn ds-btn--primary">
        {{linkLabel}}
        <dep-icon class="icon" [icon]="'icon-east'"></dep-icon>
    </a>
</div>