import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appCarouselSlide]'
})
export class CarouselSlideDirective {
  public slideTemplate: TemplateRef<any>;

  constructor(private templateRef: TemplateRef<any>) {
    this.slideTemplate = this.templateRef;
  }
}
