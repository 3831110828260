import { Component, OnInit,Input, OnChanges, SimpleChanges } from '@angular/core';
import { Video } from 'src/app/models/videos/videos.model';

@Component({
  selector: 'dep-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss']
})
export class VideoCarouselComponent implements OnInit {

  @Input() videos:Video[];
  constructor() { }

  ngOnInit(): void {
  }

}
