import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'dep-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit {
  @Input() title : string ;
  @Input() selector : string;
  public enabled : boolean = true ;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  toggleArrow(){
    this.enabled=!this.enabled;
    let elements= document.querySelectorAll(this.selector);
    if(this.enabled && document.querySelectorAll(this.selector)){
      elements.forEach((element) => {
        this.renderer.addClass(element, "show");
        this.renderer.removeClass(element, "hide");
      })
     
    }else {
      elements.forEach((element) => {
        this.renderer.addClass(element, "hide");
        this.renderer.removeClass(element, "show");
      }) 
    }
  }

}
