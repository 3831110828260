import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-autres-ressources',
  templateUrl: './autres-ressources.component.html',
  styleUrls: ['./autres-ressources.component.scss']
})
export class AutresRessourcesComponent implements OnInit {
  @Input('ideaTitle') title: string;
  @Input('ideaAlignOffset') offset: string;
  @Input() redirection: string;
  @Input() externalRedirection: string;
  @Input() titleLink: string;

  constructor() { }

  ngOnInit(): void {
  }

}
