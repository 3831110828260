import { Component, OnInit, Input } from '@angular/core';
import { BandeDessinee } from 'src/app/models/bande-dessinee/bd.model';

@Component({
  selector: 'dep-bd-list-item',
  templateUrl: './bd-list-item.component.html',
  styleUrls: ['./bd-list-item.component.scss']
})
export class BdListItemComponent implements OnInit {
  @Input() bds : BandeDessinee[];
  constructor() { }

  ngOnInit(): void {
  }

}
