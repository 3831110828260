import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'dep-popup-event',
  templateUrl: './popup-event.component.html',
  styleUrls: ['./popup-event.component.scss']
})
export class PopupEventComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() btnLibelle: string;
  @Input() btnUrl: string;
  @Input() source: string;
  public isPopup: boolean;
  constructor(private cookieService: CookieService) {
    
  }

  ngOnInit(): void {
    if (this.source)
      this.isPopup = !this.cookieService.check(this.source)
  }

  closePopup(): void {
    if (this.source === 'popupPUB')
      this.cookieService.set("popupPUB", "true", { path: "/", sameSite: "Lax" });
    else
      this.cookieService.set("popupPAR", "true", { path: "/", sameSite: "Lax" });

    this.isPopup = false;
  }

}
