export class SelectedFilter {
    trainingFilter: TrainingFilter = new TrainingFilter();
    jobFilter: JobFilter = new JobFilter();
}

export class JobFilter {
    cities: string[] = [];
    //profile = '';
    tags: string[] = [];
}

export class TrainingFilter{
    tagIds: number[] = [];
    levels: string[] = [];
}
