import { AfterViewInit, Component, Input } from '@angular/core';
import Swiper, { Navigation, Pagination } from 'swiper';

@Component({
  selector: 'dep-startups-carousel',
  templateUrl: './startups-carousel.component.html',
  styleUrls: ['./startups-carousel.component.scss']
})
export class StartupsCarouselComponent implements AfterViewInit {
  @Input() startups;

  ngAfterViewInit(): void {
    Swiper.use([Navigation, Pagination]);
    this.initializeSwiper();
  }

  private initializeSwiper() {
    new Swiper('.swiper-container', {
      spaceBetween: 24,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.5,
          slidesPerGroup: 1
        },
        768: {
          slidesPerView: 2.5,
          slidesPerGroup: 2
        },
        1320: { 
          slidesPerView: 3.5,
          slidesPerGroup: 3
        }
      }
     
    });
  }

}
