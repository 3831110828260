<ul aria-label="Galerie d’images" aria-live="polite" tabindex="0" class="image-carousel" #carousel>
    <ng-container *ngFor="let slide of carouselSlides;let i=index">
        <li class=" image-carousel__slide">
            <ng-container [ngTemplateOutlet]="slide.slideTemplate"></ng-container>
            <p class="image-carousel__slide-title" *ngIf="carouselContent" >{{carouselContent[i].title}}</p>
            <p class="image-carousel__slide-text" *ngIf="carouselContent" [innerHTML]="carouselContent[i].text| markdown"></p>
            <p class="image-carousel__slide-title" *ngIf="carouselTitle" >{{carouselTitle}}</p>
            <p class="image-carousel__slide-text" *ngIf="carouselSource" [innerHTML]="carouselSource| markdown"></p>
        </li>
    </ng-container>
</ul>

<section class="circle-container" id="circle-container">
    <ul>
        <ng-container *ngFor="let slide of carouselSlides;let i=index">
            <li style="list-style-type: none;" (click)='scrollToDirection(i)'>
                <div class="circle" [ngClass]="{'circle-fill':currentButton===i}"></div>
            </li>
        </ng-container>
    </ul>
</section>

<button class="left-arrow" (click)="scrollToDirection(-1)" area-label="previous" id="btnPrevious" #buttonPrevious>
    <span class="chevron left">
        <dep-icon [icon]="'icon-arrow_back_ios'"></dep-icon>
    </span>
</button>
<button class="right-arrow" (click)="scrollToDirection(1)" area-label="next" id="btnNext" #buttonNext>
    <span class="chevron right">
        <dep-icon [icon]="'icon-arrow_forward_ios'"></dep-icon>
    </span>
</button>