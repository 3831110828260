<article class="actualite">
  <div class="actualite__image-container">
    <div class="actualite__image-wrapper">
      <img src="{{image}}" class="actualite__image" alt="">
    </div>

    <div class="actualite__tags-wrapper">
      <div class="actualite__tag" *ngIf="showTag">#{{tag}}</div>
      <div class="actualite__category" *ngIf="showCategory">
        <img src="/assets/images/icones-observatoire.svg" class="actualite__category-icon" alt="">
        {{category}}
      </div>
    </div>
  </div>

  <div class="actualite__type">{{type}}</div>
  <h2 class="actualite__title">{{title}}</h2>
  <p class="actualite__extract"><ng-content></ng-content></p>
  <div class="actualite__meta-info">
    <img src="/assets/images/icones-calendrier.svg" class="icon" alt="">
    {{date}}
  </div>

  <footer class="actualite__link-en-savoir-plus">
    <img src="/assets/images/icones-fleche.svg" class="icon" alt="">
    <a [href]="linkEnSavoirPlus" target="_blank" rel="noopener noreferrer">{{titleEnSavoirPlus}}</a>
  </footer>
</article>
