import { Component, Input, OnInit } from '@angular/core';
import { DirectAccess } from 'src/app/models/direct-access/directAccess.model';
import { Link } from 'src/app/models/direct-access/link.model';
import { Favorite } from 'src/app/models/favorites/favorite.model';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';

@Component({
  selector: 'dep-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: ['./link-card.component.scss']
})
export class LinkCardComponent implements OnInit {

  @Input() link: Link;
  @Input() directAccess: DirectAccess;

  favorite:Favorite<any> = new Favorite();
  isChecked: boolean = true;
  disabled: boolean = true;


  constructor(private favoriteService: FavoriteService) { }

  ngOnInit(): void {

    this.favoriteService.favoritesDirectAccessTemp$.subscribe(
      directAccess => {
        this.isChecked = directAccess.find(da=>da.directAccessLinks.map(link=>link.linkId).includes(this.link.linkId)) != null;
        this.disabled = !this.isChecked && directAccess.length>3 ;
      }
    );

  }

  toggle(): void{

    let fav = new DirectAccess();
    fav.title = this.directAccess.title;
    fav.directAccessLinks = [this.link]; 
    this.favoriteService.directAccessChangeBehaviour.next(false);
    if(this.isChecked){
      this.favoriteService.removeToDirectAccessFavorite(fav);
    }
    else{
      this.favoriteService.addToDirectAccessFavorite(fav);
    }  
  }

}
