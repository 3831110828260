<div class="filter" (clickOutside)="closeFilter()">
  <button class="filter-button" (click)="toggleFilter()" #filterButton>
    <h3 class="filter-button__title">{{ filterName }}</h3>
    <p class="filter-button__value">
      <span>{{ filterValue }}</span>
      <dep-icon class="icon" [icon]="'icon-keyboard_arrow_down'"></dep-icon>
    </p>
  </button>

  <div class="filter-dropdown">
    <form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
      <div id="Taleez sections" *ngIf="jobOfferFilter">
        <!-- Section 1 -->
        <section class="filter-section" *ngIf="tagsControl.length>0">
          <h4 class="filter-section__title">Secteur</h4>
          <ng-container *ngFor="let tag of tags; let i = index">
            <label class="filter-option">
              <input type="checkbox" [formControl]="toControl(tagsControl.controls[i])" />
              <div class="filter-option__label">
                {{ tag }}
                <span class="filter-option__counter"></span>
                <dep-icon
                  class="
                    filter-option__icon filter-option__icon_status_unchecked
                  "
                  [icon]="'icon-add'"
                >
                </dep-icon>
                <dep-icon
                  class="filter-option__icon filter-option__icon_status_checked"
                  [icon]="'icon-check'"
                >
                </dep-icon>
              </div>
            </label>
          </ng-container>
        </section>
        <!-- FIN de la section 1 -->

        <!-- Section 2 -->
        <!-- <section class="filter-section">
          <h4 class="filter-section__title">Type de poste</h4>
          <ng-container *ngFor="let profile of profiles">
            <label class="filter-option">
              <input
                type="radio"
                name="profileControl"
                [value]="profile.value"
                formControlName="profileControl"
              />
              <div class="filter-option__label">
                {{ profile.value }} -->
                <!-- <span class="filter-option__counter">({{profile.count}})</span> -->
                <!-- <dep-icon
                  class="
                    filter-option__icon filter-option__icon_status_unchecked
                  "
                  [icon]="'icon-add'"
                >
                </dep-icon>
                <dep-icon
                  class="filter-option__icon filter-option__icon_status_checked"
                  [icon]="'icon-check'"
                >
                </dep-icon>
              </div>
            </label>
          </ng-container>
        </section> -->
        <!-- FIN de la section 2 -->

        <!-- Section 3 -->
        <section class="filter-section">
          <h4 class="filter-section__title">Localisation</h4>
          <ng-container *ngFor="let city of cities; let i = index">
            <label class="filter-option">
              <input
                type="checkbox"
                [formControl]="toControl(citiesControl.controls[i])"
              />
              <div class="filter-option__label">
                {{ city.description }}
                <!-- <span class="filter-option__counter">({{city.count}})</span> -->
                <dep-icon
                  class="
                    filter-option__icon filter-option__icon_status_unchecked
                  "
                  [icon]="'icon-add'"
                >
                </dep-icon>
                <dep-icon
                  class="filter-option__icon filter-option__icon_status_checked"
                  [icon]="'icon-check'"
                >
                </dep-icon>
              </div>
            </label>
          </ng-container>
        </section>
        <!-- FIN de la section 3 -->
      </div>
      <div id="TagFilterSection" *ngIf="tagsList">
        <section class="filter-section">
          <h4 class="filter-section__title">Thématiques</h4>
          <label
            class="filter-option"
            formArrayName="tagsToCheck"
            *ngFor="let tag of tagsFormArray.controls; let i = index"
          >
            <input type="checkbox" [formControlName]="i" />
            <div class="filter-option__label">
              {{ tagsList[i].name }}
              <!-- <span class="filter-option__counter">(23)</span> -->
              <dep-icon
                class="filter-option__icon filter-option__icon_status_unchecked"
                [icon]="'icon-add'"
              >
              </dep-icon>
              <dep-icon
                class="filter-option__icon filter-option__icon_status_checked"
                [icon]="'icon-check'"
              >
              </dep-icon>
            </div>
          </label>
        </section>
      </div>
      <br />
      <div id="levelFilterSection" *ngIf="levelList">
        <section class="filter-section">
          <h4 class="filter-section__title">Niveaux</h4>
          <label
            class="filter-option"
            formArrayName="levelToCheck"
            *ngFor="let level of levelFormArray.controls; let i = index"
          >
            <input type="checkbox" [formControlName]="i" />
            <div class="filter-option__label">
              <div [ngSwitch]="levelList[i].level">
                <div *ngSwitchCase="levelEnum.BEGINNER">
                  {{ levelEnum.BEGINNER }}
                  <dep-icon [icon]="'icon-star_full'"></dep-icon>
                </div>
                <div *ngSwitchCase="levelEnum.INTERMEDIATE">
                  {{ levelEnum.INTERMEDIATE }}
                  <dep-icon [icon]="'icon-star_full'"></dep-icon>
                  <dep-icon [icon]="'icon-star_full'"></dep-icon>
                </div>
                <div *ngSwitchDefault>
                  {{ levelEnum.EXPERT }}
                  <dep-icon [icon]="'icon-star_full'"></dep-icon>
                  <dep-icon [icon]="'icon-star_full'"></dep-icon>
                  <dep-icon [icon]="'icon-star_full'"></dep-icon>
                </div>
              </div>
              <dep-icon
                class="filter-option__icon filter-option__icon_status_unchecked"
                [icon]="'icon-add'"
              >
              </dep-icon>
              <dep-icon
                class="filter-option__icon filter-option__icon_status_checked"
                [icon]="'icon-check'"
              >
              </dep-icon>
            </div>
          </label>
        </section>
      </div>
      <!--Button a rajouter de reset-->
      <button class="filter-dropdown__button_reset" (click)="resetFilter()">
        Réinitialiser les filtres
      </button>
      <button class="filter-dropdown__button">Appliquer</button>
    </form>
  </div>
</div>
