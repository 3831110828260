import { Component, Input, OnInit } from '@angular/core';
import { Video } from 'src/app/models/videos/videos.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';


@Component({
  selector: 'dep-video-list-item',
  templateUrl: './video-list-item.component.html',
  styleUrls: ['./video-list-item.component.scss']
})
export class VideoListItemComponent implements OnInit {

  @Input() video: Video;
  public url: string;

  constructor(private urlFormatter: FormatUrlPipe) { }

  ngOnInit(): void {
  }

  replaceUrl(): string {
    
    this.video.tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        this.url = "/videos/Autres/" + this.video.id + '-' + this.urlFormatter.transform(this.video.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1)
      } else {
        this.url = "/videos/" + this.urlFormatter.transform(array[0].name) + "/" + this.video.id + '-' + this.urlFormatter.transform(this.video.url) + '.html'
      }
    });
    return this.url;
  }


}
