import { Component, OnInit, Input } from '@angular/core';
import { Brief } from 'src/app/models/briefs/brief.model';

@Component({
  selector: 'dep-briefs-carousel',
  templateUrl: './briefs-carousel.component.html',
  styleUrls: ['./briefs-carousel.component.scss']
})
export class BriefsCarouselComponent implements OnInit {

  @Input() briefs: Brief[];
  constructor() { }

  ngOnInit(): void {
  }

}
