<section class="page-header">
    <div class="page-header__container">
      <div class="page-header__content">
        <button type="button" class="page-header__back" area-label="back button" (click)="back()">
          <dep-icon class="icon" [icon]="'icon-arrow_back_ios'"></dep-icon>
          Retour
        </button>
  
        <div class="page-header__breadcrumb">
          <ng-content select="[breadcrumb]"></ng-content>
        </div>

        <h1 class="page-header__title">Envie d'entreprendre</h1>
        <nav aria-label="Menu secondaire">
          <ul class="page-header__links">
            <li *ngFor="let faqCategory of faqCategories">
              <a
                id="faq{{faqCategory.id}}"
                class="page-header__link"
                (click)="setFaqCategory(faqCategory)"
              >
                <dep-icon [icon]="faqCategory.icon" class="icon"></dep-icon>
                <div class="faq-name">
                 <span>{{faqCategory?.name}}</span>
               </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="page-header__description">
       <div class="faq-image" *ngIf="(selectedFaqCategory$|async)?.imageUrl">
         <img src="{{(selectedFaqCategory$|async)?.imageUrl}}" class="customised" alt="image-faq">
       </div>
       <p class="faq-description" [innerHTML]="(selectedFaqCategory$|async)?.description | markdown"></p>
      </div>
    </div>
  </section>
  