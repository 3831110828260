import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-startups',
  templateUrl: './create-startups.component.html',
  styleUrls: ['./create-startups.component.scss'],
})
export class CreateStartupsComponent implements OnInit {
  constructor(private meta: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Les Deeptech - la création de startup issue de la recherche publique'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Toutes les notions clés à comprendre avant de créer une startup innovante qui exploite des résultats de recherche scientifique.',
    });
  }
}
