import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'darkenColor'
})
export class DarkenColorPipe implements PipeTransform {

  transform(color: string): string {
    // Extraire les composantes RGB de la couleur
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);

    // Calculer la luminance de la couleur de fond
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Assombrir chaque composante en réduisant sa valeur
    r = Math.round(r * 0.4);
    g = Math.round(g * 0.4);
    b = Math.round(b * 0.4);

    // Si la luminance est inférieure à 0.5 (couleur sombre), retourner blanc
    if (luminance < 0.5) {
      return '#fff';
    } else {
      // Sinon, retourner la nouvelle couleur assombrie
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
}
