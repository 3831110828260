<article class="partner" *ngIf="partner">
    <div class="partner__container">
      <div class="partner__image">
          <a class="partner__image-wrapper" [routerLink]="['/mon-espace-partenaire/acteurs/',partner.id+'-'+(partner.name.toLowerCase() | formatUrl)+'.html']">
           <img src="{{partner.imageUrl}}" alt="{{partner.altImage}}" loading="lazy">
          </a>
      </div>
      <a class="partner__link title__container" [routerLink]="['/mon-espace-partenaire/acteurs/',partner.id+'-'+(partner.name.toLowerCase() | formatUrl)+'.html']">
      <p class="partner__title">{{partner.name}}</p>
      </a>
      <div class="partner__save-btn">
      <input type="checkbox" class="save-btn" [(ngModel)]="isChecked" (click)="toggle()">
      </div>
      <a class="partner__content partner__link transparent" [routerLink]="['/mon-espace-partenaire/acteurs/',partner.id+'-'+(partner.name.toLowerCase() | formatUrl)+'.html']">
      <p class="partner__description transparent">{{description}}</p>
      </a>
    </div>
  </article>
