import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dep-article-podcast',
  templateUrl: './article-podcast.component.html',
  styleUrls: ['./article-podcast.component.scss']
})
export class ArticlePodcastComponent implements OnInit {

  @Input('podcast') podcast: string;

  /*********VARIABLES *****/
  public safeUrl: any;

  constructor(private _sanitizer: DomSanitizer) { }

  /************************** */
  //LIFE CYCLE
  /************************* */
  ngOnInit(): void {
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.podcast); 
  }

}
