import { Component, Input, OnInit } from '@angular/core';
import { RegionDetails } from 'src/app/models/observatory/regions-details/regions-details';

@Component({
  selector: 'dep-startup-card',
  templateUrl: './startup-card.component.html',
  styleUrls: ['./startup-card.component.scss']
})
export class StartupCardComponent implements OnInit {

  @Input() startup:RegionDetails ;

  constructor() { }

  ngOnInit(): void {
  }

}
