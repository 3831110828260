export class TrainingLevel {
    constructor( public level: string){
        this.level = level;
    }
}

export enum LevelEnum {
    BEGINNER = 'initiation',
    INTERMEDIATE = 'perfectionnement',
    EXPERT = 'expertise'
}
