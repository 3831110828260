import { Component, OnInit, Input } from '@angular/core';
import { Event } from 'src/app/models/events/events.model';

@Component({
  selector: 'dep-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  @Input() events: Event[];
  @Input() responsiveDisplay: string;
  @Input() eventsCount: number;

  constructor() { }

  ngOnInit(): void {
  }

}
