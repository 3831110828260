import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-coach-ecosystem',
  templateUrl: './coach-ecosystem.component.html',
  styleUrls: ['./coach-ecosystem.component.scss'],
})
export class CoachEcosystemComponent implements OnInit {
  constructor(
    private viewportScroller: ViewportScroller,
    private meta: Meta,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Envie d\'entreprendre - Accompagner la création et la croissance de son entreprises'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Pour entreprendre avec succès il est conseillé de s\'entourer de spécialistes du transfert de tech, de l\'incubation ou de l\'accélération, voici quelques clés pour les identifier.',
    });
  }

  // Pour le scroll vers l'ancre
  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
