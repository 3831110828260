<div class="pagination" *ngIf="(witnessResponse$|async) !=null && (witnessResponse$|async).temoins.length > 0">
    <div class="pagination_info">{{ (((curentPage$|async)-1) * (pageSize$|async))+1 }}-{{
        (witnessResponse$|async).temoins.length + ((curentPage$|async)-1) * (pageSize$|async) }} sur {{
        (witnessResponse$|async).total }} membres</div>
    <div class="numbers_container">
        <button [disabled]="(curentPage$|async) === 1" (click)="prevPage()">
            <dep-icon [icon]="'icon-arrow_back_ios'" class="icon-arrow"></dep-icon>
        </button>
        <button *ngIf="(visiblePages | async)[0] > 1" (click)="goToPage(1)" class="customised">1</button>
        <button *ngIf="(visiblePages | async)[0] > 2" disabled>...</button>
        <button *ngFor="let page of visiblePages | async" [class.active]="page === (curentPage$|async)"
            class="customised" (click)="goToPage(page)">{{ page }}</button>
        <button *ngIf="(visiblePages | async)[(visiblePages | async).length - 1] < pages.length - 1"
            disabled>...</button>
        <button *ngIf="(visiblePages | async)[(visiblePages | async).length - 1] < pages.length"
            (click)="goToPage(pages.length)" class="customised">{{ pages.length }}</button>
        <button [disabled]="(curentPage$|async) === (pages$|async).length" (click)="nextPage()">
            <dep-icon [icon]="'icon-arrow_forward_ios'" class="icon-arrow"></dep-icon>
        </button>
    </div>
</div>

<div class="temoins_container" aria-live="polite" *ngIf="(witnessResponse$|async) !=null">
    <ng-container *ngFor="let temoin of (witnessResponse$|async).temoins">
        <dep-member [temoin]="temoin"></dep-member>
    </ng-container>
</div>
<ng-container *ngIf="(witnessResponse$|async) !=null && (witnessResponse$|async).temoins.length == 0">
    <div class="results_msg">
        <img src="/assets/images/not-found.png" alt="not-found" class="customised" />
        <h1>Nous n'avons pas de résultat pour votre recherche....</h1>
        <p>Mais vous pouvez toujours retenter votre chance : vérifiez l'orthographe ou essayez avec des filtres de
            recherche moins spécifiques</p>
    </div>
</ng-container>

<div class="pagination" *ngIf="(witnessResponse$|async) !=null && (witnessResponse$|async).temoins.length > 0">
    <div class="pagination_info">{{ (((curentPage$|async)-1) * (pageSize$|async))+1 }}-{{
        (witnessResponse$|async).temoins.length + ((curentPage$|async)-1) * (pageSize$|async) }} sur {{
        (witnessResponse$|async).total }} membres</div>
    <div class="numbers_container">
        <button [disabled]="(curentPage$|async) === 1" (click)="prevPage()">
            <dep-icon [icon]="'icon-arrow_back_ios'" class="icon-arrow"></dep-icon>
        </button>
        <button *ngIf="(visiblePages | async)[0] > 1" (click)="goToPage(1)" class="customised">1</button>
        <button *ngIf="(visiblePages | async)[0] > 2" disabled>...</button>
        <button *ngFor="let page of visiblePages | async" [class.active]="page === (curentPage$|async)"
            class="customised" (click)="goToPage(page)">{{ page }}</button>
        <button *ngIf="(visiblePages | async)[(visiblePages | async).length - 1] < pages.length - 1"
            disabled>...</button>
        <button *ngIf="(visiblePages | async)[(visiblePages | async).length - 1] < pages.length"
            (click)="goToPage(pages.length)" class="customised">{{ pages.length }}</button>
        <button [disabled]="(curentPage$|async) === (pages$|async).length" (click)="nextPage()">
            <dep-icon [icon]="'icon-arrow_forward_ios'" class="icon-arrow"></dep-icon>
        </button>
    </div>
</div>