
export class WitnessRequest {
    index: number
    limit: number
    search: string
    jobs: string[]
    activitySector: string[]
    expertiseSector: string[]
    locations: string[]
    profiles: string[]
    contests: string[]
    presential: boolean
    distancial: boolean
}