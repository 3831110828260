<section
  class="page-section-bpi"
  [ngClass]="{
    'page-section-bpi_theme_dark': sectionTheme === 'dark',
    'page-section-bpi_theme_medium': sectionTheme === 'medium',
    'page-section-bpi_theme_light': sectionTheme === 'light',
    'page-section-bpi_overflow_none': sectionOverflow === 'none',
    'page-section-bpi_overflow_right': sectionOverflow === 'right',
    'page-section-bpi_overflow_both': sectionOverflow === 'both',
    'page-section_grid': isPartnerSpace
  }"
  #section
>
  <div class="page-section-bpi__headerwrapper" *ngIf="!hideHeader">
    <header class="page-section-bpi__header">
      <h2 class="page-section-bpi__title">
        <ng-content select="[slot='title']"></ng-content>
      </h2>
      <div class="page-section-bpi__content-info">
        <ng-content select="[slot='content-info']"></ng-content>
      </div>
      <a [routerLink]="sectionRouterLink" class="page-section-bpi__see-more-title" *ngIf="sectionRouterLink">
        <dep-icon [icon]="sectionLinkIcon" class="icon" *ngIf="sectionLinkIcon"></dep-icon>
        {{sectionLinkLabel}}
      </a>
      <!-- alternative link in place of the 'see more' link -->
      <div class="page-section-bpi__alternative-link">
        <ng-content select="[slot='alternative-link']"></ng-content>
      </div>
    </header>
  </div>
  <div [ngClass]="{'page-section-bpi__partner-space': isPartnerSpace}" class="page-section-bpi__container">
    <div class="page-section-bpi__inner-container">
      <ng-content></ng-content>

      <a [routerLink]="sectionRouterLink" class="page-section-bpi__see-more-content" *ngIf="sectionRouterLink">
        {{sectionLinkLabel}}
      </a>
    </div>
  </div>
</section>
