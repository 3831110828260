<article class="project" *ngIf="contest">
    <a
      href="{{contest.externalLink}}"
      target="_blank" rel="noopener noreferrer"
      class="project__container"
      [attr.aria-labelledby]="'project-' + contestIndex" 
      *ngIf="contest.externalLink"
    >
      <p class="project__categories">
        <span class="project__type">{{ contest.type| underscoreRemover }}</span>
        <span class="project__category" *ngFor="let tag of contest.tags">
          <ng-container *ngIf="tag?.name !== 'France' && tag?.name !== 'Europe'">{{tag?.name}}</ng-container>
          <ng-container *ngIf="(tag?.name == 'France' || tag?.name == 'Europe') && contest.tags.length == 1"> Autres </ng-container>
        </span>
      </p>
      <div class="project__image">
        <div class="project__image-wrapper">
          <img
            src="{{ contest.imageUrl }}"
            alt="{{ contest.altImage }}"
            loading="lazy"
          />
        </div>
      </div>
      <div *ngIf="diffDate <= 7" class="project__remaining-days">
        {{(contest.endDate | dateRemainingdays)}}
      </div>
     
  
      <h3 id="project-{{ contestIndex }}" class="project__title">
        {{ contest.title }}
        <dep-icon class="icon" [icon]="'icon-call_made'"></dep-icon>
      </h3>
      <div class="project__dates">
        <dep-icon class="icon" [icon]="'icon-date_range'"></dep-icon>
        <p>Se déroule du {{ contest.startDate | date:'dd MMMM yyyy' }} au {{ contest.endDate | date:'dd MMMM yyyy'}}</p>
      </div>
      <p class="project__intro">{{ contest.description }}</p>
    </a>
  </article>
  