import { ActualitiesType } from './actualities-type';

export const HEADER_CATEGORIES: ActualitiesType[] = [
  {
    id: 1,
    name: 'Brèves',
    icon: 'icon-message',
    route: 'breves'
  },
  {
    id: 2,
    name: 'Actualités',
    icon: 'icon-news',
    route: 'actualites'
  },
  {
    id: 3,
    name: 'Tribunes',
    icon: 'icon-spotlight',
    route: 'tribunes'
  },
  {
    id: 4,
    name: 'Vidéos',
    icon: 'icon-film',
    route: 'videos'
  },
  {
    id: 5,
    name: 'Podcasts',
    icon: 'icon-podcast',
    route: 'podcasts'
  },
  {
    id: 6,
    name: 'Études',
    icon: 'icon-microscope',
    route: 'etudes'
  },
];
