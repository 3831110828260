import { Component, Input } from '@angular/core';

@Component({
  selector: 'dep-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input() status : string;
  showDropdown: boolean = false;

  constructor(){};

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  selectOption(option: string) {
    this.status= option;
    this.showDropdown = false;
  }
}
