import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectedFilter, TrainingFilter } from 'src/app/models/filters/filter.model';
import { JobOfferFilter } from 'src/app/models/job-offers/job-offer-filter.model';
import { SelectComponent } from 'src/app/models/witness/selectComponent.model';
import { Tag } from 'src/app/models/tags/tag.model';
import { LevelEnum, TrainingLevel } from 'src/app/models/training/training-level.model';

@Component({
  selector: 'dep-list-filter-combined',
  templateUrl: './list-filter-combined.component.html',
  styleUrls: ['./list-filter-combined.component.scss'],
})
export class ListFilterCombinedComponent implements OnInit, OnChanges {
  // Integration
  @ViewChild('filterButton') filterButton: ElementRef;
  filterIsOpen = false;

  /*
   **Traitement
   */
  // Inputs from parent
  // input TANDEM
  @Input() jobOfferFilter?: JobOfferFilter;
  // input TAGS
  @Input() tagsList?: Tag[];
  @Input() levelList?: TrainingLevel[];
  @Input() filterName?: string;
  @Input() filterValue?: string;

  // Outputs to parent component
  @Output() eventEmitter = new EventEmitter<SelectedFilter>();
  @Output() trainingFilterEvent = new EventEmitter<TrainingFilter>();
  @Output() resetFilterEmitter = new EventEmitter();

  public selectedFilter = new SelectedFilter();
  public levelEnum = LevelEnum;

  /**
   * Taleez Params
   */
  public cities: SelectComponent[] ;
  //public profiles: ValueCount[];
  public tags: string[];
  public offerCategoriesToSisplay: string[] = [];
  private CATECORY_SECTOR = 'secteur';
  // Filter form
  public filterForm: UntypedFormGroup;
  public tagsControl = new UntypedFormArray([]);
  public citiesControl = new UntypedFormArray([]);
  public levelsControl = new UntypedFormArray([]);
  
  constructor(private renderer: Renderer2, private formBuilder: UntypedFormBuilder) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.jobOfferFilter) {
      this.initFilterData();
      this.initCheckedFilters();
    }
    this.initFilterForm();
  }

  ngOnInit(): void { }
  initCheckedFilters(): void {
    this.selectedFilter.jobFilter.tags = [];
    this.selectedFilter.jobFilter.cities = [];
    //this.selectedFilter.jobFilter.profile = null;
  }
  // change if methodes
  initFilterForm(): void {
    this.filterForm = new UntypedFormGroup({});
    if (this.jobOfferFilter) {
      this.initJobOfferFilterForm();
    }
    else {
      this.initTrainingFilterForm();
    }
  }

  private initFilterData(): void {
    this.cities = this.jobOfferFilter.cities;
    //this.profiles = this.jobOfferFilter.profiles;
    if (this.jobOfferFilter.tags)
      this.tags = this.jobOfferFilter.tags[this.CATECORY_SECTOR];
  }

  toggleFilter(): void {
    this.filterIsOpen = !this.filterIsOpen;
    this.setFilterState();
  }

  closeFilter(): void {
    this.filterIsOpen = false;

    this.setFilterState();
  }

  applyFilter(): void {
    /*
     **Integration
     */
    this.filterIsOpen = false;
    this.setFilterState();

    /*
     **Traitement
     */
    if (this.jobOfferFilter) {
      this.applyJobOfferFilter();
    } else {
      this.applyTrainingFilter();
    }
  }

  setFilterState(): void {
    const filterButton = this.filterButton.nativeElement;

    if (this.filterIsOpen === true) {
      this.renderer.setAttribute(filterButton, 'aria-expanded', 'true');
    } else {
      this.renderer.removeAttribute(filterButton, 'aria-expanded');
    }
  }

  get tagsFormArray(): UntypedFormArray {
    return this.filterForm.controls.tagsToCheck as UntypedFormArray;
  }

  get levelFormArray(): UntypedFormArray {
    return this.filterForm.controls.levelToCheck as UntypedFormArray;
  }

  getSelectedTrainingFilter(): void {
    this.selectedFilter.trainingFilter.tagIds = this.filterForm.value.tagsToCheck
      .map((checked, i) => (checked ? this.tagsList[i].id : null))
      .filter((v) => v !== null);

    this.selectedFilter.trainingFilter.levels = this.filterForm.value.levelToCheck
      .map((checked, i) => (checked ? this.levelList[i].level : null))
      .filter((v) => v !== null);
  }

  resetFilter(): void {
    this.initFilterForm();
  }

  applyJobOfferFilter(): void {
    this.initCheckedFilters();
    //this.selectedFilter.jobFilter.profile = this.filterForm.controls.profileControl.value;
    this.tagsControl.controls.forEach((c, i) => {
      if (c.value) {
        this.selectedFilter.jobFilter.tags.push(
          this.CATECORY_SECTOR + ':' + this.tags[i]
        );
      }
    });
    this.citiesControl.controls.forEach((c, i) => {
      if (c.value) {
        this.selectedFilter.jobFilter.cities.push(this.cities[i].description);
      }
    });

    this.eventEmitter.emit(this.selectedFilter);
  }
  applyTrainingFilter(): void {
    this.getSelectedTrainingFilter();
    this.trainingFilterEvent.emit(this.selectedFilter.trainingFilter);
  }

  initTrainingFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      tagsToCheck: new UntypedFormArray([]),
      levelToCheck: new UntypedFormArray([])
    });
    this.tagsList?.forEach(() =>
      this.tagsFormArray.push(new UntypedFormControl(false))
    );
    this.levelList?.forEach(() =>
      this.levelFormArray.push(new UntypedFormControl(false))
    );

  }

  initJobOfferFilterForm(): void {
    this.tagsControl.clear();
    this.citiesControl.clear();
    // this.filterForm = this.formBuilder.group({
    //   profileControl: new FormControl(),
    // });
    if (this.tags)
      this.tags.forEach((t: string) =>
        this.tagsControl.push(new UntypedFormControl(false))
      );
    this.cities.forEach((c) => this.citiesControl.push(new UntypedFormControl(false)));
  }

  toControl(absCtrl: AbstractControl): UntypedFormControl {
    const ctrl = absCtrl as UntypedFormControl;
    // if(!ctrl) throw;
    return ctrl;
  }
}
