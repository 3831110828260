<div class="tabs">
  <div
    class="tabs__tablist"
    role="tablist"
    [attr.aria-label]="tabsLabel"
    aria-orientation="horizontal"
    #tablist
  >
    <ng-container *ngFor="let tab of tabs; let i = index">
      <button
        area-label="tab"
        id="tab-{{i}}"
        class="tabs__tab"
        role="tab"
        [attr.aria-controls]="'tab-' + i + '-panel'"
        [attr.aria-selected]="tab.active"
        (click)="selectTab(tab, i)"
      >
        {{tab.tabTitle}}
      </button>
    </ng-container>
  </div>

  <ng-content></ng-content>
</div>
