import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { DirectAccess } from 'src/app/models/direct-access/directAccess.model';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectAccessService {

  private REST_API_SERVER = '';
  private DIRECT_ACCESS = '';



  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs,
    private sharedService: SharedService
  ) {
    this.REST_API_SERVER = environment.apiUrl;

  }


  public getDirectAccesses(): Observable<DirectAccess[]> {
    this.DIRECT_ACCESS = this.ressourcePath.getDirectAccesses();
    const httpOptions = this.sharedService.getSecureHeaders();
    return this.http.get<DirectAccess[]>(this.REST_API_SERVER + this.DIRECT_ACCESS, httpOptions);
  }


  public getFavoriteDirectAccesses(): Observable<DirectAccess[]> {


    const httpOptions = this.sharedService.getSecureHeaders();
    this.DIRECT_ACCESS = this.ressourcePath.getFavoriteDirectAccesses();

    return this.http.get<DirectAccess[]>(this.REST_API_SERVER + this.DIRECT_ACCESS, httpOptions).pipe(
      tap(response => response.sort(function (a, b) {
        return a.title.localeCompare(b.title) || a.directAccessLinks[0].linkTitle.localeCompare(b.directAccessLinks[0].linkTitle);
      })
      )
    );
  }

  public updateFavoriteDirectAccesses(directAccesses: DirectAccess[]): Observable<DirectAccess[]> {
    const httpOptions = this.sharedService.getSecureHeaders();

    this.DIRECT_ACCESS = this.ressourcePath.getFavoriteDirectAccesses();

    return this.http.put<DirectAccess[]>(this.REST_API_SERVER + this.DIRECT_ACCESS, directAccesses, httpOptions).pipe(
      tap(response => response.sort(function (a, b) {
        return a.title.localeCompare(b.title) || a.directAccessLinks[0].linkTitle.localeCompare(b.directAccessLinks[0].linkTitle);
      })
      )
    );
  }




}
