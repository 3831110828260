import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { TrainingRequest } from 'src/app/models/training/training-request.model';
import { Training } from 'src/app/models/training/training.module';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  private REST_API_SERVER = '';
  private TRAINING_PATH = '';
  private TRAININGS_COUNT_PATH = '';

  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs
  ) {
    this.REST_API_SERVER = environment.apiUrl;
    this.TRAINING_PATH = this.ressourcePath.getTrainings();
    this.TRAININGS_COUNT_PATH = this.ressourcePath.getTrainingsCount();
  }

  getTrainings(trainingRequest: TrainingRequest): Observable<Training[]> {
    return this.http.post<Training[]>(this.REST_API_SERVER + this.TRAINING_PATH, trainingRequest);
  }

  getTrainingsCount(type: string): Observable<number> {
    const params: HttpParams = new HttpParams()
      .set('type', type);
    return this.http.get<number>(this.REST_API_SERVER + this.TRAININGS_COUNT_PATH, { params });
  }
}
