import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'dep-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
  }

}
