<div class="search-bar_wrapper" *ngIf="searchBar" id="search-bar">
  <div class="search-bar_container customised">
      <input type="text" (keyup)="change($event)" id="search-input" placeholder="Saisissez vos termes de recherche">
      <button id="searchButton" (click)="navigateToSearchPage()" aria-label="search button" [attr.disabled]="suggestions == null || suggestions.length == 0 || this.filledInput == false ? '' : null">
        <img  src="/assets/images/icones/search.png" alt="search icon" class="customised" />
      </button>
  </div>

  <!-- Suggestions container -->

  <div *ngIf="filledInput" class="suggestion_container" id="suggestion_container">
    <ul class="suggestion_list customised">
      <li class="suggestion_card" *ngFor="let suggestion of suggestions | slice:0:5">
        <div class="suggestion_content">
          <p>
            <span *ngIf="suggestion?.highlight?.title; then useHighlightTitle else useDefaultTitle"></span>
            <ng-template #useHighlightTitle>
              <span class="suggestion_content_title" [innerHTML]="suggestion?.highlight?.title[0]"></span> &nbsp;- <span>{{ suggestion._source.type | titlecase }}</span>
            </ng-template>
            <ng-template #useDefaultTitle>
              <span class="suggestion_content_title" [innerHTML]="suggestion._source.title"></span> &nbsp;- <span>{{ suggestion._source.type | titlecase }}</span>
            </ng-template>
          </p>
          <p>
            <span *ngIf="suggestion.highlight?.description; then useHighlightDescription else useDefaultDescription"></span>
            <ng-template #useHighlightDescription>
              <span *ngIf="suggestion.highlight?.description[0][0] != '<'">...</span>
              <span [innerHTML]="suggestion.highlight?.description[0]"></span>
              <span *ngIf="suggestion.highlight?.description[0][suggestion.highlight?.description[0].length-1] != '>'">...</span>
            </ng-template>
            <ng-template #useDefaultDescription>
              <span [innerHTML]="suggestion._source.short_desc"></span>
            </ng-template>
          </p>
        </div>
        <div class="suggestion_link" [ngSwitch]="suggestion._source.type">           
          <a [href]="suggestion._source.url" *ngSwitchCase="'glossaire'" >
            <p>Lire plus</p>
          </a>
          <a [href]="suggestion._source.url" *ngSwitchCase="'TRIBUNE'" >
            <p>Lire plus</p>
          </a>
          <a [href]="suggestion._source.url" *ngSwitchCase="'ETUDE'">
            <p>Lire plus</p>
          </a>
          <a [href]="suggestion._source.url" *ngSwitchCase="'ACTUALITE'">
            <p>Lire plus</p>
          </a>
          <a [href]="suggestion._source.url" target="_blank" rel="noopener noreferrer" *ngSwitchCase="'ressource'">
            <p>Télécharger</p>
          </a>
          <a [href]="suggestion._source.url" *ngSwitchCase="'FAQ'">
            <p>Lire plus</p>
          </a>
        </div>
      </li>
      <li *ngIf="suggestions != null && suggestions.length > 0" class="results">
        <a (click)="navigateToSearchPage()" class="pointer"> Voir tous les résultats</a>
      </li>
      <li *ngIf="suggestions != null && suggestions.length == 0" class="results">
        <p>Aucun résultat trouvé</p>
      </li>
      <li *ngIf="suggestions == null" class="results">
        <p>Recherche en cours...</p>
      </li>
    </ul>
  </div>
</div>