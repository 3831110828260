import { Component, Input } from '@angular/core';

@Component({
  selector: 'dep-btn-icon',
  templateUrl: './btn-icon.component.html',
  styleUrls: ['./btn-icon.component.scss']
})
export class BtnIconComponent {
 @Input() linkUrl : string;
 @Input() btnTitle : string;
 @Input() btnIcon : string;
 @Input() reverseIcon : boolean = false;
 @Input() newTab : boolean = false;

 constructor() { }

 ngOnInit(): void {
 }

}
