import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  @Input() document: string;
  @Input('documentName') name: string;
  @Input('documentSize') size: string;

  constructor() { }

  ngOnInit(): void {
  }

}
