import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Region } from 'src/app/models/region/region';
import { RegionService } from 'src/app/services/region/region.service';

@Component({
  selector: 'dep-dynamic-map',
  templateUrl: './dynamic-map.component.html',
  styleUrls: ['./dynamic-map.component.scss']
})
export class DynamicMapComponent implements OnInit {
  public displayPopup: boolean = false;
  public close: boolean = false;
  public selectedRegion: string;
  @Input() regionFiltres: Region[];
  public regions: Region[] = [
    { name: "Provence-Alpes-Côte d'Azur" },
    { name: "Bourgogne-Franche-Comté" },
    { name: "Bretagne" },
    { name: "Normandie" },
    { name: "Hauts-de-France" },
    { name: "Pays de la Loire" },
    { name: "Centre-Val de Loire" },
    { name: "Lyon" },
    { name: "Grenoble" },
    { name: "Clermont-Ferrand" },
    { name: "Nancy" },
    { name: "Strasbourg" },
    { name: "Reims" },
    { name: "Ile-de-France Est & Ouest" }, //13
    { name: "Paris" },
    { name: "Bordeaux" },
    { name: "Poitiers" },
    { name: "Montpellier" },
    { name: "Toulouse" },
    { name: "Nice" },
    { name: "Marseille" },
    { name: "La Réunion"}
  ];

  constructor(private renderer: Renderer2, private regionService: RegionService) { }

  ngOnInit(): void {
    this.setIdsDrGroup();
  }

  changeColor(selector: string): void {
    this.renderer.addClass(document.getElementById(selector), "light-yellow");
    let departments = document.getElementsByName(selector);
    for (let i = 0; i < departments.length; i++) {
      this.renderer.addClass(departments[i], "light-yellow");
    }
  }

  resetColor(selector: string): void {
    this.renderer.removeClass(document.getElementById(selector), "light-yellow");
    let departments = document.getElementsByName(selector);
    for (let i = 0; i < departments.length; i++) {
      this.renderer.removeClass(departments[i], "light-yellow");
    }
  }

  closePopup(): void {
    this.close = true;
  }

  setIdsDrGroup(): void {
    for (let dr of this.regionFiltres) {
      if (this.regions.find(dReg => (dReg.name === dr.name))) {
        this.regions.find(dReg => (dReg.name === dr.name)).id = dr.id;
      }
    }

  }

  setSelectedRegion(region: string): void {
    window.scroll(0, 0);
    this.selectedRegion = region;
    this.displayPopup = true;
    this.close = false;
  }
}


