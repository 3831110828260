import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'boldstring'
})
export class BoldStringPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: any, regex): SafeHtml {
    return this.sanitize(this.replace(value, regex));
  }

  replace(str, regex) {
    return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
  }

  sanitize(str) {
    return this._sanitizer.bypassSecurityTrustHtml(str);
  }
}
