import { Component, Input, OnInit } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-actuality-bloc',
  templateUrl: './actuality-bloc.component.html',
  styleUrls: ['./actuality-bloc.component.scss']
})
export class ActualityBlocComponent implements OnInit {

  @Input() actuality: Actuality;

  public url: string;

  constructor(private urlFormatter: FormatUrlPipe) { }

  ngOnInit(): void {
  }

  replaceUrl(): string {
    this.actuality.tags.forEach((tag,index,array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length==1) {
        this.url = "/mon-espace-partenaire/les-actualites/Autres/" + this.actuality.id + '-' + this.urlFormatter.transform(this.actuality.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length >1){
        array.splice(index,1)
      } else{
        this.url = "/mon-espace-partenaire/les-actualites/" + this.urlFormatter.transform(array[0].name) + "/" + this.actuality.id + '-' + this.urlFormatter.transform(this.actuality.url) + '.html'
      }
      
    });
    
    return this.url;
  }

}
