import { Component, Input, OnInit } from '@angular/core';
import { Brief } from 'src/app/models/briefs/brief.model';

@Component({
  selector: 'dep-briefs-list-item',
  templateUrl: './briefs-list-item.component.html',
  styleUrls: ['./briefs-list-item.component.scss'],
})
export class BriefsListItemComponent implements OnInit {
  @Input() brief: Brief;

  constructor() {}

  ngOnInit(): void {}
}
