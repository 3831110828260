<div class="pagination_container">
    <div class="pagination_legend">
      <p>{{totalList}} Résultats sur {{listCount}}</p>
    </div>
    <div class="btn_container btn_previous">
      <button *ngIf="page>0" (click)="previous()" area-label="previous">
        <img src="/assets/images/icones/previous.png" class="pagination_icon" alt="icon previous">
      </button>
    </div>
    <ul class="pagination_content">
      <li *ngFor="let pagination of pagination;let i= index">
        <button [ngClass]="{'active': i==0 }" id="pagination{{i}}" area-label="pagination" class="pagination_page"
          (click)="showList(i)">{{i+1}}</button>
      </li>
    </ul>
    <div class="btn_container btn_next">
      <button *ngIf="page+1 < totalPages" (click)="next()" area-label="next">
        <img src="/assets/images/icones/next.png" class="pagination_icon" alt="icon next">
      </button>
    </div>
  </div>
