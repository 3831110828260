import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Tag } from 'src/app/models/tags/tag.model';

@Component({
  selector: 'dep-article-categories',
  templateUrl: './article-categories.component.html',
  styleUrls: ['./article-categories.component.scss']
})
export class ArticleCategoriesComponent implements OnInit,OnChanges {
  @Input() articleCategories:Tag[];
  replaceTag : boolean =true ;

  constructor() { 
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.articleCategories){
      this.replaceTags(); 
      }
  }
  ngOnInit(): void {
    
  }

  replaceTags(){

   this.articleCategories.forEach( tag => {
    if(tag.name !== 'France' && tag.name !== 'Europe'){
      this.replaceTag = false ;
    }
  });
  }

}
