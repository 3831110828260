import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Witness } from 'src/app/models/witness/witness.model';
import { WitnessService } from 'src/app/services/witness/witness.service';


@Component({
  selector: 'dep-temoin',
  templateUrl: './temoin.component.html',
  styleUrls: ['./temoin.component.scss']
})
export class TemoinComponent implements OnInit {
  @Input() temoin : Witness;
  @Input() addCircle: boolean = true;

  public iconClose$ : Observable<boolean> = this.witnessService.witnessSelected$.pipe(map(witnessSelected=>{
    return witnessSelected?.id == this.temoin?.id ? true : false
  }));

  public photo = "/assets/images/espace-partenaire/personas/persona4.png";

  constructor(
    private witnessService: WitnessService
  ) { }

  ngOnInit(): void {
    if(this.temoin && this.temoin.photo){
      this.photo = this.temoin.photo;
    }
  }

  closeDetails(): void {
    this.witnessService.witnessSelectedBehavior.next(null);
    this.witnessService.witnessScrollBehavior.next(null);
  }

  showDetails(): void {
    this.witnessService.witnessScrollBehavior.next(null);
    this.witnessService.witnessSelectedBehavior.next(null);
    setTimeout(()=>{
      this.witnessService.witnessScrollBehavior.next("temoin-"+this.temoin.id);
      this.witnessService.witnessSelectedBehavior.next(this.temoin);
    },10)
  }

}

