import { Component, Input, OnInit } from '@angular/core';
import { Tag } from 'src/app/models/tags/tag.model';

@Component({
  selector: 'dep-structure-thumbnail',
  templateUrl: './structure-thumbnail.component.html',
  styleUrls: ['./structure-thumbnail.component.scss']
})
export class StructureThumbnailComponent implements OnInit {
  
  @Input() structureTags: Tag[];
  @Input() structureImage: string;
  @Input() structureTitle: string;
  @Input() structureText: string;
  @Input() structureLocation: string;
  @Input() structureExternalLink:string;
  


  constructor() { }

  ngOnInit(): void {
  }

}
