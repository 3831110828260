import { AfterViewInit, Component, Input } from '@angular/core';
import Swiper, { Navigation, Pagination } from 'swiper';

@Component({
  selector: 'dep-rss-carousel',
  templateUrl: './rss-carousel.component.html',
  styleUrls: ['./rss-carousel.component.scss']
})
export class RssCarouselComponent implements AfterViewInit {
  @Input() actualities ;
  private slidesPerView: number = 3.5;

  ngAfterViewInit(): void {
    Swiper.use([Navigation, Pagination]);
    this.initializeSwiper();
  }

  private initializeSwiper() {
    new Swiper('.swiper-container', {
      spaceBetween: 16,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1024: { 
          slidesPerView: this.slidesPerView,
        },
        768: {
          slidesPerView: 1.5,
        }
      }
     
    });
  }

}