import { Component, Input, OnInit } from '@angular/core';
import { Training } from 'src/app/models/training/training.module';

@Component({
  selector: 'dep-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss']
})
export class TrainingListComponent {
  // trainingsType: defines the layout of the list based on the type of the trainings. Values = null or 'ott"
  @Input() trainingType: string;
  @Input() trainings: Training[];

  constructor() { }
}
