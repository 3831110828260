<div class="ds-input-group">
  <label for="{{name}}" class="ds-input-group__label">
    {{label}}
  </label>

  <div class="ds-input-group__select ds-input-group__select--with-icon">
    <select id="{{name}}" name="{{name}}" [attr.required]="required">
      <ng-content></ng-content>
    </select>
  </div>

  <ng-container *ngIf="info">
    <i class="ds-input-group__icon ds-input-group__icon--info fa fa-question-circle"></i>
    <div class="ds-input-group__info">
        <div class="ds-input-group__info--inner">
            {{info}}
        </div>
    </div>
  </ng-container>

  <ng-container *ngIf="error">
    <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
    <div class="ds-input-group__feedback">
        {{errorMessage}}
    </div>
  </ng-container>
</div>
