import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-research-result',
  templateUrl: './research-result.component.html',
  styleUrls: ['./research-result.component.scss'],
})
export class ResearchResultComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Envie d\'entreprendre - identifier un résultat de recherche'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Comprendre les notions clés pour créer une startup qui exploite une technologie scientifique : identifier un résultat de recherche exploitable.',
    });
  }
}
