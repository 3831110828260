<div class="idea" [ngClass]="{'idea--offset-medium': offset === 'medium',
            'idea--offset-large': offset === 'large'}">
  <h2 class="idea__title">{{title}}</h2>

  <p class="resource__content">
    <ng-content></ng-content>
  </p>
  
  <!-- <div class="redirection__action"> -->
  <!-- Si c'est un lien interne au site  -->
  <a *ngIf="redirection" class="ds-btn ds-btn--primary" [routerLink]="redirection">{{titleLink}}</a>

  <!-- Si c'est un lien externe au site  -->
  <a *ngIf="externalRedirection" class="ds-btn ds-btn--primary" [href]="externalRedirection"
    target="_blank" rel="noopener noreferrer">{{titleLink}}</a>
  <!-- </div> -->
</div>