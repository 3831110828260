import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { HEADER_CATEGORIES } from 'src/app/mocks/actualities-types/mock-header-categories';
import { Category } from 'src/app/models/categories/category.model';
import { CategoryService } from 'src/app/services/categories/category.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';

@Component({
  selector: 'dep-page-header-nav',
  templateUrl: './page-header-nav.component.html',
  styleUrls: ['./page-header-nav.component.scss']
})
export class PageHeaderNavComponent implements OnInit {
  actualitiesTypes = HEADER_CATEGORIES;

  public category: Category[] = [];

  constructor(@Inject(CategoryService) private categoryService: CategoryService, private location: Location, private sharedService : SharedService) { }

  ngOnInit(): void {
    if (this.sharedService.getCategories()){
      this.category = this.sharedService.getCategories();
    }else{
      this.getCategories();
    }    
  }

  /**
  * Get all categories
  */
  getCategories(): void {
    this.categoryService.getCategories().subscribe(
      (data) => {
        this.category = data;
        this.sharedService.setCategories(this.category);
      },
      (error) => {
        console.log('There is an error : ', error);
      }
    );
  }

  back(): void {
    this.location.back()
  }

}
