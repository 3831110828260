<div class="card card-full-width"
[ngClass]="{'page-section--dark': theme === 'dark',
              'page-section--medium': theme === 'medium',
              'page-section--light': theme === 'light'}">
  <img src="{{icon}}" class="card__icon" alt="">

  <div class="card__content">
    <ng-content></ng-content>
  </div>
</div>
