import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-vagues-bleu-clair',
  templateUrl: './vagues-bleu-clair.component.html',
  styleUrls: ['./vagues-bleu-clair.component.scss']
})
export class VaguesBleuClairComponent implements OnInit {
  @Input('titleVaguesBleu') titleVaguesBleu: string;

  constructor() { }

  ngOnInit(): void {
  }

}
