import { Component, OnInit, Input } from '@angular/core';
import { Brief } from 'src/app/models/briefs/brief.model';

@Component({
  selector: 'dep-news-thumbnail',
  templateUrl: './news-thumbnail.component.html',
  styleUrls: ['./news-thumbnail.component.scss']
})
export class NewsThumbnailComponent implements OnInit {
  @Input() brief: Brief;

  constructor() { }

  ngOnInit(): void {
  }

}
