<div class="carousel_container"  [ngClass]="{'carousel_margin': isActuality}">
<div class="slick_container"  [ngClass]="{'size-l': isVideo || isPodcast}">
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
  <!-- Startup cards -->
   <ng-container *ngIf="isStartup">
      <ng-container *ngFor="let startup of startups; let i = index">  
        <dep-startup-card 
          ngxSlickItem
          class="slick_item"
          [startup]="startup">
        </dep-startup-card>
      </ng-container>
    </ng-container>

  <!-- Actualities -->
  <ng-container *ngIf="isActuality">
    <ng-container *ngFor="let actuality of actualities; let i = index">  
      <dep-actuality-thumbnail
      ngxSlickItem
      class="slick_item-s slick_item"
      [actuality]="actuality" 
      [showDesc]="showDesc"
      [isRegionTag]="true">
      </dep-actuality-thumbnail>
    </ng-container>
  </ng-container>

    <!-- Videos -->
  <ng-container *ngIf="isVideo">
    <ng-container *ngFor="let video of videos; let i = index">  
      <dep-video-thumbnail
        ngxSlickItem
        class="slick_item-s slick-item_video"
        [video]="video">
      </dep-video-thumbnail>
    </ng-container>
  </ng-container>

   <!-- Videos -->
  <ng-container *ngIf="isPodcast">
      <ng-container *ngFor="let podcast of podcasts; let i = index">  
        <dep-podcast-thumbnail
            ngxSlickItem
            class="slick_item-s slick-item_video"
            [podcast]="podcast">
        </dep-podcast-thumbnail>
      </ng-container>
  </ng-container>

  </ngx-slick-carousel>
</div>
<button (click)="slickModal.slickPrev()" class="btn btn_previous" area-label="previous"><img src="/assets/images/icones/previous.png" alt="button previous"></button> 
<button (click)="slickModal.slickNext()" class="btn btn_next" area-label="next"><img src="/assets/images/icones/next.png" alt="button next"></button>
</div>
