import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ACTUALITIESTYPES } from 'src/app/mocks/actualities-types/mock-actualities-types';
import { NEWSLETTER_TYPES } from 'src/app/mocks/newsletter-type/mock-newsletter.model';
import { Podcast } from 'src/app/models/podcasts/podcast.model';
import { CookieNewsletterService } from 'src/app/services/cookie-newsletter/cookie-newsletter.service';
import { PodcastService } from 'src/app/services/podcasts/podcast.service';
import { ServerErrorInterceptorService } from 'src/app/shared/services/servererrorinterceptor.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';

@Component({
  selector: 'dep-podcast-detail',
  templateUrl: './podcast-detail.component.html',
  styleUrls: ['./podcast-detail.component.scss']
})
export class PodcastDetailComponent implements OnInit, OnDestroy {
  actualitiesTypes = ACTUALITIESTYPES;
  clipboardCopySuccess = false;
  clipboardmessageDuration = 4000;
  @Input() public selectedPodcast: number;
  public podcast: Podcast;
  public isOpen = true;
  private title: string;
  private metaDescription: string;
  public fullUrl: string;

  public newsletter = NEWSLETTER_TYPES[1];

  constructor(
    private meta: Meta, private titleService: Title,
    private renderer: Renderer2,
    @Inject(PodcastService) private podcastService: PodcastService,
    private activatedRoute: ActivatedRoute,
    private cookieNewsletterService: CookieNewsletterService,
    private serverErrorInterceptor: ServerErrorInterceptorService,
    private sharedService:SharedService
  ) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.selectedPodcast = params.selectedPodcast.split('-')[0];
      this.getPodcastById();
    });
    this.isOpen = this.cookieNewsletterService.checkCookie(this.newsletter.cookieName);
    this.fullUrl = window.location.href;
  }

  /**
   * Get Podcast by id
   */
  private getPodcastById(): void {
    this.podcastService.getPodcastById(this.selectedPodcast)
      .subscribe(
        (data) => {
          this.podcast = data;
          this.title = data.altTitle;
          this.metaDescription = data.altMetaDescription;

          this.titleService.setTitle(this.title);
          this.meta.addTag({
            name: 'description',
            content: this.metaDescription
          });
        },
        (error) => {
          this.serverErrorInterceptor.intercept(error)
        },
        () => {
          const imagePreview = this.sharedService.formatPreviewImage(this.podcast.imageUrl);
    
          this.meta.addTags([
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: this.podcast.title },
            { name: 'twitter:image', content: imagePreview },
            { name: 'twitter:image:src', content: imagePreview },
            { property: 'og:type', content: 'article' },
            { name:'url', property: 'og:url', content: this.fullUrl },
            { name:'title', property: 'og:title', content: this.podcast.title },
            { name:'image', property: 'og:image', content: imagePreview }
          ]);
        }

      );
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
    this.meta.removeTag("name='twitter:card'")
    this.meta.removeTag("name='twitter:title'")
    this.meta.removeTag("name='twitter:image'")
    this.meta.removeTag("name='twitter:image:src'")
    this.meta.removeTag("property='og:type'")
    this.meta.removeTag("property='og:url'")
    this.meta.removeTag("property='og:title'")
    this.meta.removeTag("property='og:image'")
  }

  public scroll(): void {
    if (document.getElementById('myElem'))
      document.getElementById('myElem').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  closeNewsletter($event: any): void {
    this.isOpen = $event;
  }

  public copyUrl(): void {
    navigator.clipboard.writeText(document.location.href)
      .then(() => {
        this.clipboardCopySuccess = true;
        setTimeout(() => {
          this.clipboardCopySuccess = false;
        }, this.clipboardmessageDuration);
      })
      .catch(() => this.clipboardCopySuccess = false);
  }
}