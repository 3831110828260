import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dep-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  public paginations: number[];
  public lastPage: number = 0;
  public listByPage: any[];
  public page: number = 0;
  public totalPages: number = 0;
  public totalList: number = 0;
  public pagination = [];
  public listCount = 0;
  @Input() public list: any[];
  @Input() limit: number;
  @Output() listByPageEmitter = new EventEmitter();

  constructor(private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.list != null) {
      this.initList();
    }
  }

  ngOnInit(): void {
    if(this.list != null) {
      this.initList();
    }
  }

  initList() {
    this.getPagination();
    this.showList(0);
  }

  getList(): void {
    window.scroll(0, 0);
    this.listByPage = [];
    let index = this.page * this.limit;
    let limit = (index + this.limit <= this.listCount) ? index + this.limit : this.listCount;
    for (let i = index; i < limit; i++) {
      this.listByPage.push(this.list[i]);
    }
    this.listByPageEmitter.emit(this.listByPage);
    this.totalList = this.limit * this.page + this.listByPage.length;
    this.getActivePage(this.page);
  }

  getPagination(): void {
    this.listCount = this.list.length;
    this.totalPages = Math.ceil(this.listCount / this.limit);
    this.pagination = new Array(Math.ceil(this.listCount / this.limit));
  }

  getActivePage(index: number): void {
    let lastPage = document.getElementById("pagination" + this.lastPage);
    let activePage = document.getElementById("pagination" + index);
    if (lastPage && activePage) {
      if (this.lastPage != index) {
        this.renderer.removeClass(lastPage, 'active');
        this.renderer.addClass(activePage, 'active');
      } else {
        this.renderer.addClass(activePage, 'active');
      }
      this.lastPage = index;
    }
  }

  showList(index: number): void {
    this.page = index;
    this.getList();
  }

  next(): void {
    this.page += 1;
    this.getList();
  }

  previous(): void {
    this.page -= 1;
    this.getList();
  }

}
