<div class="quote-blocks-carousel-wrapper">
    <ul
      class="quote-blocks-carousel"
      aria-live="polite"
      tabindex="0"
      (scroll)="scrollEvent()"
      #carousel
    >
      <ng-container *ngFor="let slide of carouselSlides">
        <li class="quote-blocks-carousel__slide-wrapper">
          <ng-container [ngTemplateOutlet]="slide.slideTemplate"></ng-container>
        </li>
      </ng-container>
    </ul>
  
    <div class="quote-blocks-carousel__controls" *ngIf="carouselSlides.length > 1">
      <!-- Go to previous quote -->
      <button
        class="quote-blocks-carousel__button quote-blocks-carousel__previous"
        (click)="scrollToDirection(-1)"
        area-label="previous"
        #buttonPrevious
      >
        <dep-icon [icon]="'icon-arrow_back'"></dep-icon>
        <span class="sr-only">Image précédente</span>
      </button>
      <!-- Carousel scroll indicator -->
      <div class="quote-blocks-carousel__progressbar-container" #progressBarContainer>
        <div
          class="quote-blocks-carousel__progressbar"
          role="progressbar"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
          aria-label="quote-blocks-carousel progressbar"
          #progressBar
        ></div>
      </div>
      <!-- Go to next quote -->
      <button
        class="quote-blocks-carousel__button quote-blocks-carousel__next"
        (click)="scrollToDirection(1)"
        area-label="next"
        #buttonNext
      >
        <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
        <span class="sr-only">Image suivante</span>
      </button>
    </div>
  </div>
  