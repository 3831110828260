import { Component, Input, OnInit } from '@angular/core';
import { Podcast } from '../../../../models/podcasts/podcast.model';

@Component({
  selector: 'dep-podcasts-carousel',
  templateUrl: './podcasts-carousel.component.html',
  styleUrls: ['./podcasts-carousel.component.scss']
})
export class PodcastsCarouselComponent implements OnInit {
  @Input('podcasts') podcasts: Podcast[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
