<article class="startup">
    <div class="startup__tags">
        <div class="startup__tag" *ngFor="let tag of startup.tags">{{tag}}</div>
    </div>
    <img [src]="startup.logo" class="startup__image" alt="">
    <h2 class="startup__title">{{startup.companyName}}</h2>
    <p *ngIf="startup.type == 1" class="startup__text" >Startup créée en {{startup.companyFoundedIn}}</p>
    <p *ngIf="startup.type == 0" class="startup__text" >{{startup.companyTotalFunding}}M€ levés depuis {{startup.companyFoundedIn}}</p>

    <a [href]="startup.companyUrl" target="_blank" rel="noopener noreferrer" class="link startup__link">
        <span>
            Découvrir<span class="sr-only"> {{startup.companyName}}</span>
        </span>
        <dep-icon class="icon" [icon]="'icon-call_made'"></dep-icon>
    </a>
</article>
