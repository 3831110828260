<div class="footer">
<div class="footer_container">
    <!-- Logo, link to homepage -->
    <section class="footer_logo">
      <a routerLink="/" aria-label="Accueil" class="footer_transparent">
        <img  class="footer_transparent" src="/assets/images/deep-tech/Logo-Lesdeeptech-blanc.png" alt="Les deeptech">
      </a>
    </section>
       <!-- footer navigation -->

       <nav id="footer-nav" class="footer-nav_container footer_transparent" aria-label="footer links">
        <ul class="footer_nav">
          <li class="transparent">
            <a class="transparent" [routerLink]="'/contact.html'" [state]="{source:'espace partenaire'}">
               <span class="transparent">Nous contacter</span>
            </a>
          </li>
          <li class="transparent">
            <a class="transparent" href="{{pdfCGU}}"  target="_blank" rel="noopener noreferrer">
               <span class="transparent">CGU</span>
            </a>
          </li>
          <li class="transparent">
            <a class="transparent" href="./assets/docs/protection-des-donnees-espace-partenaire.pdf"  target="_blank" rel="noopener noreferrer">
               <span class="transparent">Protection des données</span>
            </a>
          </li>
          <li class="transparent">
            <button class="btn-cookies transparent" onclick="tC.privacyCenter.showPrivacyCenter(); return false">
               <span class="transparent">Gestion des cookies</span>
            </button>
          </li>
        </ul>
      </nav>
  </div>
</div>