import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'average'
})
export class AverageCountPipe implements PipeTransform {

  transform(array: number[]): string {
    let sum=0;
    array.forEach((item:number) => {  sum += +item; });
    return Math.round(sum/array.length).toString();
  }

}
