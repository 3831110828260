import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-go-further',
  templateUrl: './go-further.component.html',
  styleUrls: ['./go-further.component.scss']
})
export class GoFurtherComponent implements OnInit {

  @Input('titre') titre : string;

  constructor() { }

  ngOnInit(): void {
  }

}
