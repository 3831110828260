<article class="briefs-thumbnail">
    <p class="briefs-thumbnail__date">
      <dep-icon class="icon" [icon]="'icon-time'"></dep-icon>
      {{(brief.publishDate | nbDaysElapsed)}}
    </p>
    <h3 class="briefs-thumbnail__title">{{brief.title}}</h3>
    <div class="briefs-thumbnail__source">
      <img src="{{brief.logoUrl}}" alt="{{brief.altImage}}">
    </div>
    <a href="{{brief.externalLink}}" target="_blank" rel="noopener noreferrer" class="link briefs-thumbnail__link">
      <span>Lire</span>
      <!-- hidden text to complete the link label (for screen readers and bots)  -->
      <span class="sr-only">la brève : {{brief.title}}</span>
      <dep-icon class="icon" [icon]="'icon-call_made'"></dep-icon>
    </a>
  </article>

