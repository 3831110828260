import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dep-info-bloc',
  templateUrl: './info-bloc.component.html',
  styleUrls: ['./info-bloc.component.scss']
})
export class InfoBlocComponent implements OnInit {
  @Input('url') url : string;
  @Input('blocText') blocText : string;
  @Input('buttonTitle') buttonTitle : string;
  @Input('serviceSelected') serviceSelected : string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
