<div class="temoin_container" id="temoin-{{temoin.id}}">
    <div class="temoin_image">
        <img src="{{photo}}" alt="profil" class=" img-member customised" />
    </div>
    <div [ngClass]="{'temoin_infos-padding': !addCircle , 'temoin_infos': addCircle }">
        <div class="temoin_text">
            <span *ngIf="addCircle" class="circle"
                [ngClass]="{'circle-active': !temoin.reactivationDate , 'circle-inactive' : temoin.reactivationDate}">
            </span>
            <p>{{temoin.name}} {{temoin.lastName}}</p>
            <p>{{temoin.jobs[0].nom_poste}} - {{temoin.companyName}}</p>
            <p>{{temoin.activitySectors[0].nom_secteur_entreprise}}</p>
            <span *ngIf="temoin.reactivationDate && !addCircle" class="tag">Indisponible</span>
        </div>
        <button area-label="button add">
            <ng-container *ngIf="!(iconClose$|async)">
                <img src="/assets/images/espace-partenaire/plus.png" alt="icon-add" class="icon customised"
                    (click)="showDetails()" />
            </ng-container>
            <ng-container *ngIf="(iconClose$|async)">
                <img src="/assets/images/espace-partenaire/close.png" alt="icon-close" class="icon customised"
                    (click)="closeDetails()" />
            </ng-container>
        </button>

    </div>
</div>