import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'dep-page-section-actualities',
  templateUrl: './page-section-actualities.component.html',
  styleUrls: ['./page-section-actualities.component.scss']
})
export class PageSectionActualitiesComponent implements OnInit, AfterViewInit {
  @ViewChild('section') sectionRef: ElementRef;
  @Input() sectionTheme: string = 'light';
  @Input() sectionBackground: string;
  @Input() sectionOverflow: string = 'none';
  @Input() sectionTitle: string;
  @Input() sectionRouterLink: string;
  @Input() sectionLinkIcon: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.sectionBackground) {
      this.sectionRef.nativeElement.style.setProperty('--background-url', 'url(' + this.sectionBackground + ')');
    }
  }

}
