<article class="platform-list-item" *ngIf="content">
  <div class="platform-list-item__image">
    <div class="platform-list-item__image-wrapper"><img src="{{content.authorPhotoProfile}}" alt="{{forum?.altImage}}" loading="lazy"></div>
  </div>
  <p class="platform-list-item__categories">
    <span class="platform-list-item__category" *ngFor="let category of forum?.tags">{{category.name}}</span>
  </p>
  <h3 class="platform-list-item__quote" *ngIf="content.quote"><a [routerLink]="'/tribunes/'+(forum.tags[0].name | formatUrl) + '/' +forum.id+'-'+forum.url+'.html'">{{content.quote}}</a></h3>
  <p class="platform-list-item__author-name" *ngIf="content.authorName">{{content.authorName}}</p>
  <p class="platform-list-item__author-occupation" *ngIf="content.authorRole">{{content.authorRole}}</p>
  <a [routerLink]="'/tribunes/'+(forum.tags[0].name | formatUrl ) + '/' +forum.id+'-'+forum.url+'.html'" class="platform-list-item__button">
    <span>Lire</span>
    <!-- hidden text to complete the link label (for screen readers and bots) -->
    <span class="sr-only">la tribune : {{content.quote}}</span>
  </a>
</article>