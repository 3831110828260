import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dep-pagination-system',
  templateUrl: './pagination-system.component.html',
  styleUrls: ['./pagination-system.component.scss']
})
export class PaginationSystemComponent implements OnInit {

  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;
  @Input() itemsName: string;
  @Output() pageChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  getStartIndex(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  getEndIndex(): number {
    return Math.min(this.getStartIndex() + this.itemsPerPage - 1, this.totalItems);
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  getPages() : number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  onPageClick (page: number | '...'): void {
    if (typeof page === 'number') {
      this.pageChanged.emit(page);
    }
  }

  onPrevious(): void {
    this.pageChanged.emit('previous');
  }

  onNext(): void {
    this.pageChanged.emit('next');
  }

  getPagesWithEllipsis(): (number | '...')[] {
    const result: (number | '...')[] = [];
    const pages = this.getPages();

    // un seuil pour déterminer quand utiliser "..." 
    const limit = 5;

    for (let i = 0; i < pages.length; i++) {
      // Vérifie si la page actuelle est à une distance de 2 pages ou moins de la page courante
      const isNearCurrent = Math.abs(i - this.currentPage)<= 2;
      const isFirstOrLast = i === 0 || i === pages.length - 1;

      if ( pages.length <= limit || isFirstOrLast || isNearCurrent || (i > limit && i < pages.length - 2)) {
        result.push(pages[i]);
      } else if (!result.includes('...')) {
        result.push('...');
      }
    }
    return result;
  }
}
