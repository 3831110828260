import { NgIf } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Contest } from 'src/app/models/contests/contest.model';

@Component({
  selector: 'dep-contests-thumbnail',
  templateUrl: './contests-thumbnail.component.html',
  styleUrls: ['./contests-thumbnail.component.scss']
})
export class ContestsThumbnailComponent implements OnInit {
  @Input('contest') contest: Contest;
  @Input() contestIndex: number;

  public diffDate: number;





  constructor() { }

  ngOnInit(): void {
    if (this.contest)
      this.diffDate = this.calculateDiff(this.contest.endDate);

  }

  /**
   * 
   * @param dateSent 
   * @returns 
   */
  public calculateDiff(dateSent) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.abs(Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24)));
  }
}
