import { Component, OnInit, Input } from '@angular/core';
import { Brief } from 'src/app/models/briefs/brief.model';

@Component({
  selector: 'dep-briefs-thumbnail',
  templateUrl: './briefs-thumbnail.component.html',
  styleUrls: ['./briefs-thumbnail.component.scss']
})
export class BriefsThumbnailComponent implements OnInit {
  @Input("brief") brief: Brief;

  constructor() { }

  ngOnInit(): void {
  }


}
