<div class="main-footer">
  <div class="main-footer__wave">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1440 140" preserveAspectRatio="none" aria-hidden="true">
      <path d="M-64 461h1567l-.3-419.6Q1284.15-1.2 992.9 24.8c-291.2 26-432.4 68.3-706.4 58.7Q12.6 74.05-64 0z" fill="#1c2463" fill-rule="evenodd" />
    </svg>
  </div>
  <div class="main-footer__container">
    <div class="main-footer__content">
      <!-- About Les Deeptech -->
      <div class="main-footer__about-us about-us">
        <a routerLink="/" class="about-us__logo-wrapper">
          <img src="/assets/images/redesign-v2021/footer/logo-lesdeeptech-blanc.png" class="about-us__logo" alt="Les Deeptech">
        </a>
        <p class="about-us__text">
          Une plateforme pour créer et booster les startups deeptech.<br />
          Pensée et opérée par les acteurs de l’innovation.
        </p>
      </div>

      <!-- Social links -->
      <div class="main-footer__social social">
        <p class="social__title">Suivez-nous</p>
        <ul class="social__list">
          <li>
            <a href="https://fr-fr.facebook.com/bpifrance/" class="social__link" target="_blank" rrel="noopener noreferrer">
              <dep-icon [icon]="'icon-social-facebook'"></dep-icon>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Bpifrance" class="social__link" target="_blank" rel="noopener noreferrer">
              <dep-icon [icon]="'icon-social-twitter'"></dep-icon>
              <span class="sr-only">Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/c/bpifrance/" class="social__link" target="_blank" rel="noopener noreferrer">
              <dep-icon [icon]="'icon-social-youtube'"></dep-icon>
              <span class="sr-only">Youtube</span>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/showcase/les-deeptech-bpifrance/" class="social__link" target="_blank" rel="noopener noreferrer">
              <dep-icon [icon]="'icon-social-linkedin'"></dep-icon>
              <span class="sr-only">Linkedin</span>
            </a>
          </li>
        </ul>
      </div>

      <!-- Logos -->
      <div class="main-footer__logos logos">
        <ul class="logos__list">
          <li >
            <img src="/assets/images/redesign-v2021/footer/france2030.png" class='logos__logo' alt="France 2030">
          </li>
          <li >
            <img src="/assets/images/redesign-v2021/footer/pia@2x.png" class='logos__logo' alt="Programme d'investissements d'avenir">
          </li>
          <li >
            <a href="https://lafrenchtech.com/fr/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/redesign-v2021/footer/french-tech@2x.png" class='logos__logo' alt="La French Tech">
            </a>
          </li>
          <li >
            <img src="/assets/images/redesign-v2021/footer/gouvernement@2x.png" class='logos__logo' alt="Gouvernement">
          </li>
          <li >
            <a href="https://www.bpifrance.fr/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/redesign-v2021/footer/bpifrance.png" class='logos__logo' alt="Bpifrance">
            </a>
          </li>
        </ul>
      </div>

      <!-- Navigation: Deeptech and site links -->
      <div class="main-footer__links links" aria-label="Pages et ressources utiles">
        <div>
          <p class="links__list-title">Les Deeptech</p>
          <ul class="links__list">
            <li>
              <a routerLink="/envie-d-entreprendre/." class="links__link">Envie d’entreprendre</a>
            </li>
            <li>
              <a href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true" class="links__link">L’Observatoire</a>
            </li>
            <li>
              <!-- <a [routerLink]="'/'" fragment="services" class="links__link">Les outils</a> -->
              <a href="/#services" class="links__link">Les outils</a>
            </li>
            <li>
              <a [routerLink]="'/actualites-ressources/.'" class="links__link">Actualités et ressources</a>
            </li>
          </ul>
        </div>
        <div>
          <p class="links__list-title">Liens utiles</p>
          <ul class="links__list">
            <li>
              <a [routerLink]="'/qui-sommes-nous.html'" class="links__link">Qui sommes-nous ?</a>
            </li>
            <li>
              <a href="./assets/docs/CGU lesdeeptech.fr site vitrine v2.pdf" target="_blank" class="links__link" rel="noopener noreferrer"><abbr title="Conditions générales d’utilisation">CGU</abbr></a>
            </li>
            <li>
              <a href="./assets/docs/Mentions-legales-du-site-Lesdeeptech.pdf" target="_blank" class="links__link" rel="noopener noreferrer">Mentions légales</a>
            </li>
            <li>
              <a href="./assets/docs/protection-des-donnees-espace-publique.pdf"  target="_blank" class="links__link" rel="noopener noreferrer">Protection des données</a>
            </li>
            <li>
              <a [routerLink]="'/contact.html'" [state]="{source:'public footer'}" class="links__link">Nous contacter</a>
            </li>
            <li>
              <button type="button" class="links__link" area-label="cookies" onclick="tC.privacyCenter.showPrivacyCenter(); return false">Gestion des cookies</button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Register to the newsletter -->
      <div class="main-footer__register register">
        <p class="register__title">Restez informé</p>
        <p class="register__promise">Recevez notre newsletter directement dans votre boîte mail</p>
        <form
          class="register__form"
          #f="ngForm"
          (ngSubmit)="onSubmit(f)"
        >
          <label for="email" class="register__input-wrapper">
            <input
              id="email"
              class="register__input"
              [class.register__input_status_error]="f.submitted && (email.errors || emailFound)"
              type="email"
              name="email"
              placeholder="Votre email"
              #email="ngModel"
              ngModel
              pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
              required
            >
            <span class="sr-only">Votre email août</span>
          </label>
          <ng-container *ngIf=" f.submitted && email.value.length === 0 && email.errors && !emailFound">
            <p class="register__input-feedback">Veuillez renseigner votre adresse email</p>
          </ng-container>
          <ng-container *ngIf=" f.submitted && email.value.length !== 0 && email.errors && !emailFound">
            <p class="register__input-feedback">Veuillez renseigner une adresse email valide (mon-email@exemple.fr)</p>
          </ng-container>

          <button type="submit" area-label="s'inscrire" class="register__submit">S’inscrire</button>
        </form>
      </div>

      <!-- Copyright -->
      <p class="main-footer__copyright">Copyright © {{date}} Bpifrance.</p>
    </div>
  </div>
</div>
