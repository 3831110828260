import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-page-section-new',
  templateUrl: './page-section-new.component.html',
  styleUrls: ['./page-section-new.component.scss']
})
export class PageSectionNewComponent implements OnInit {
  @Input() sectionOverflow = 'none';
  @Input() sectionRouterLink: string;
  @Input() sectionLinkLabel: string;
  @Input() hideHeader:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
