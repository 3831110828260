import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-event-bloc',
  templateUrl: './event-bloc.component.html',
  styleUrls: ['./event-bloc.component.scss']
})
export class EventBlocComponent implements OnInit {
  @Input() event ;
  constructor() { }

  ngOnInit(): void {
  }

}
