<article class="video-list-item">
    <div class="video-list-item__image">
        <div class="video-list-item__image-wrapper">
            <img src="{{video?.imageUrl}}" alt="{{video?.altImage}}" loading="lazy">
        </div>
    </div>
    <p class="video-list-item__categories">
        <span class="video-list-item__category" *ngFor="let tag of video?.tags">
        <ng-container *ngIf="tag?.name !== 'France' && tag?.name !== 'Europe'">{{tag?.name}}</ng-container> 
        </span>
    </p>
    <h3 class="video-list-item__title"><a
            [routerLink]="replaceUrl()">{{video?.title}}</a></h3>
    <p class="video-list-item__author">{{video?.authorNameAndRole}}</p>
    <p class="video-list-item__duration">
        <dep-icon class="icon" [icon]="'icon-timer'"></dep-icon>
        {{video?.duration}}
    </p>
    <p class="video-list-item__intro">{{video?.description}}</p>
    <a [routerLink]="replaceUrl()" class="video-list-item__button">
        <span>Visionner</span>
        <!-- hidden text to complete the link label (for screen readers and bots) -->
        <span class="sr-only">la vidéo : {{video?.title}}</span>
    </a>
</article>