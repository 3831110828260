import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/authentification/auth.service';

@Component({
  selector: 'dep-popup-cgu',
  templateUrl: './popup-cgu.component.html',
  styleUrls: ['./popup-cgu.component.scss']
})
export class PopupCguComponent implements OnInit {
  @Input() pdfUrl : string ;
  public close  : boolean = false;
  public disabled : boolean = true;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  acceptCGU(): void {
    this.authService.updateUserCGUValidation();
    this.closePopup();
  }

  refuseCGU(): void {
    this.authService.logout();
  }

  closePopup() : void {
    this.close = true;
  }

  disableButton() : void {
   this.disabled = !this.disabled;
  }
}
