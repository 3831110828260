<dep-navbar-public-space></dep-navbar-public-space>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Exploiter un résultat de recherche">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup/.'" *appBreadcrumbItem>Envie d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Exploiter un résultat de recherche</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- Exploiter un résultat de recherche -->
  <dep-page-section sectionTitle="Exploiter un résultat de recherche" sectionTheme="medium">
    <dep-text>
      <p>
        L’aventure technologique d’une startup Deeptech commence par l’obtention d’un résultat de recherche exploitable en laboratoire. En voici la définition selon la commission européenne:
      </p>

      <p>
        « Toute sortie tangible ou intangible de l’action, telle que des données, des connaissances et des informations quelle que soit leur forme ou leur nature, qu’elles puissent ou non être protégées, qui sont générées dans l’action comme ainsi que tous les droits attachés, y compris les droits de propriété intellectuelle. »
      </p>
    </dep-text>
  </dep-page-section>
  <!-- FIN de Exploiter un résultat de recherche -->

  <!-- Contenu éditorial -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Dans la pratique</h2>

          <p>
            La commission européenne détaille cette définition : « Un résultat clé exploitable est un résultat intéressant principal identifié qui a été sélectionné et priorisé <strong>en raison de son potentiel élevé d’être « exploité »</strong> en aval de la chaîne de valeur d’un produit, d’un processus ou d’une solution, ou d’agir en tant que contribution importante à la politique, à la recherche ou à l’éducation. »
          </p>

          <p>
            Pour sélectionner et hiérarchiser les résultats, la CE recommande aux projets d’utiliser les critères suivants :
          </p>

          <ul>
            <li>degré d’innovation,</li>
            <li>exploitabilité,</li>
            <li>impact. </li>
          </ul>

          <h2>Des exemples de résultats exploitables</h2>

          <p>Si nous devions caractériser le TRL (Technology Readiness Level) d’un résultat de recherche exploitable, celui-ci se situerait autour du TRL 3. Vous trouverez ci-dessous des exemples en fonction de votre champ de recherche (tableau non exhaustif)</p>
        </div>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <dep-cards-list>
            <dep-card
              class="lg-grid-col-4"
              cardTitle="Logiciel"
            >
              TRL 3 – Ecriture des premiers algorithmes d’essai (non intégré).
            </dep-card>

            <dep-card
              class="lg-grid-col-4"
              cardTitle="Matériel"
            >
              TRL 3 – Simulation numérique de la solution (étude du comportement).
            </dep-card>

            <dep-card
              class="lg-grid-col-4"
              cardTitle="Diagnostic et DM"
            >
              TRL 3 – Prototype alpha validé sur des échantillons.
            </dep-card>

            <dep-card
              class="lg-grid-col-4"
              cardTitle="Chimie et matériaux"
            >
              TRL 3 – Molécule(s) obtenue(s) séparément&nbsp; Paramètres d’intérêt mesurés&nbsp; Comparaison avec les prédictions théoriques et analytiques.
            </dep-card>

            <dep-card
              class="lg-grid-col-4"
              cardTitle="Optique, accoustique, micro-onde"
            >
              TRL 3 – Réalisations et tests séparés de chaque élément composant le système. Comparaison avec les prédictions théoriques.
            </dep-card>

            <dep-card
              class="lg-grid-col-4"
              cardTitle="Thérapeutique"
            >
              TRL 3 – Validation in vitro.
            </dep-card>
          </dep-cards-list>
        </div>
      </div>
    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->

  <!-- Les parties prenantes clés -->
  <dep-page-section sectionTitle="Les parties prenantes clés" sectionTheme="medium">
    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center" icon="/assets/images/groupe.svg">
        Les chercheurs
      </dep-icon-text>

      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center" icon="/assets/images/groupe.svg">
        Les établissements de recherche publique
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->

  <!-- Les autres composantes -->
  <dep-page-section sectionTitle="Les autres composantes">
    <div class="grid-container">
      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/declarer-invention.html']">Déclarer une invention</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/protection-propriete-intellectuelle.html']">Protéger une invention</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/entreprendre-en-equipe.html']">Monter une équipe fondatrice</a></dep-step>
    </div>
  </dep-page-section>
  <!-- FIN de Les autres composantes -->
</main>

<dep-footer-public-space></dep-footer-public-space>
