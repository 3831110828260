import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-offer-link',
  templateUrl: './offer-link.component.html',
  styleUrls: ['./offer-link.component.scss']
})
export class OfferLinkComponent implements OnInit {
  @Input() offerTitle: string;
  @Input() offerURL: string;
  @Input() buttonLabel: string;


  constructor() { }

  ngOnInit(): void {
  }

}

