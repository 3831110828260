<div class="popup_container" [ngClass]="{'show': isPopup,'hide': !isPopup }">
    <div class="popup_content">
        <div class="icon_container">
            <div class="icon-close" (click)="closePopup()">
                <dep-icon [icon]="'icon-close'"></dep-icon>
            </div>
        </div>
        <img src="{{imageUrl}}" alt="deeptech event" class="customised">
        <a href="{{btnUrl}}" target="_blank" rel="noopener noreferrer"
            class="popup_button ds-btn ds-btn--primary">
            {{btnLibelle}}
            <dep-icon class="icon" [icon]="'icon-arrow_forward'"></dep-icon>
        </a>
    </div>
</div>