<div class="article__categories" subheader>
  <p class="article__categories-label">Thématiques</p>
  <p class="article__categories-list">
  <ng-container *ngIf="!replaceTag">
    <span class="article__category" *ngFor="let category of articleCategories">
      <ng-container *ngIf="category.name !== 'France' && category.name !== 'Europe'">{{category.name}}</ng-container>
    </span>
  </ng-container>
  <ng-container>
    <span class="article__category" *ngIf="replaceTag">Autres</span>
  </ng-container>
  </p>
</div>
