import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-page-header-simple',
  templateUrl: './page-header-simple.component.html',
  styleUrls: ['./page-header-simple.component.scss']
})
export class PageHeaderSimpleComponent implements OnInit {
  @Input() titleSize : string;
  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  back(): void {
    this.location.back();
  }

}
