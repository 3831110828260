<article *ngIf="service">
    <a [routerLink]="" class="service_container" [ngClass]="{'change-grid': !service.imageUrl}">
        <div class="service_image" *ngIf="service.imageUrl">
            <img [src]="service.imageUrl" [alt]="service.altImage"  loading="lazy" >
        </div>
        <div class="service_description">
            <p class="title">{{service.name}}</p>
            <p class="description" [innerHTML]="truncateDescription(service.description)"></p>
            <a *ngIf="service.authorized && service.link!=null" href="{{service.link}}"  target="_blank" rel="noopener noreferrer" class="ds-btn ds-btn--primary btn-cta">Accéder au service</a>
            <a *ngIf="!service.authorized" (click)="accessRequest()" class="ds-btn ds-btn--primary btn-cta">Demander l’accès</a>
        </div>
    </a>
</article>

<!-- TOASTER : error -->
<dep-toaster [status]="'error'" [toasterTitle]="'Une erreur s’est produite'"
    [toasterText]="'Votre demande n’a pas pu être envoyée, veuillez réessayer ultérieurement.'" [show]="toasterFailure"
    (closeToaster)="closeToaster()">
</dep-toaster>

<!-- TOASTER : success -->
<dep-toaster [status]="'success'" [toasterTitle]="'Votre demande a bien été envoyée'"
    [toasterText]="'Merci pour cet envoi, nous vous recontacterons dans les plus brefs délais.'" [show]="toasterSuccess"
    (closeToaster)="closeToaster()">
</dep-toaster>