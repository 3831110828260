import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Sector } from 'src/app/models/sectors/sector.model';
import { environment } from 'src/environments/environment';
import { SectorMenu } from './../../models/sectors/sector-menu.model';

@Injectable({
  providedIn: 'root',
})
export class SectorService {
  private REST_API_SERVER = '';
  private SECTORS_URL = '';
  private SECTOR_URL = '';

  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  /**
   * get All sectors Menu
   * @returns Observable<SectorMenu[]>
   */
  public getMenuSectors(): Observable<SectorMenu[]> {
    this.SECTORS_URL = this.ressourcePath.getMenuSectors();
    return this.http.get<SectorMenu[]>(this.REST_API_SERVER + this.SECTORS_URL);
  }

  public getSectorById(id: number): Observable<Sector> {
    this.SECTOR_URL = this.ressourcePath.getSectorById(id);
    return this.http.get<Sector>(this.REST_API_SERVER + this.SECTOR_URL);
  }
}
