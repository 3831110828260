import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-build-business-plan',
  templateUrl: './build-business-plan.component.html',
  styleUrls: ['./build-business-plan.component.scss'],
})
export class BuildBusinessPlanComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Envie d\'entreprendre - rédiger son business plan');
    this.meta.addTag({
      name: 'description',
      content:
        'Découvrez et téléchargez un modèle de business plan pour entreprise innonvante qui facilitera le pilotage de votre activité, vos relations investisseurs et votre recherche de fonds.',
    });
  }
}
