import { Component, Input } from '@angular/core';
import { Tag } from 'src/app/models/tags/tag.model';

@Component({
  selector: 'dep-training-thumbnail',
  templateUrl: './training-thumbnail.component.html',
  styleUrls: ['./training-thumbnail.component.scss']
})
export class TrainingThumbnailComponent {
  @Input() formationType: string;
  @Input() formationTag: Tag;
  @Input() formationTagType: number = 0;
  @Input() formationDuration: string;
  @Input() formationLogo: string;
  @Input() formationLogoAlt: string;
  @Input() formationTitle: string;
  @Input() formationIntro: string;
  @Input() formationExpert: string;
  @Input() formationLevel: string;
  @Input() formationIndex: number;
  @Input() formationExternalLink: string;

  constructor() { }


}
