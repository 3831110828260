<div
  class="resource"
  [ngClass]="{'resource--offset-medium': offset === 'medium',
            'resource--offset-large': offset === 'large',
            'resource--nodecoration': noDecoration === true}"
>
  <h2 class="resource__title">{{title}}</h2>

  <p class="resource__content">
    <ng-content></ng-content>
  </p>

  <div class="resource__action">
    <a  class="ds-btn ds-btn--primary" href="{{resource}}" download *ngIf="resource && resource.length > 0">Télécharger</a>
    <!-- Si c'est un lien externe au site  -->
    <a *ngIf="externalRedirection" class="ds-btn ds-btn--primary" [href]="externalRedirection" target="_blank" rel="noopener noreferrer">{{titleLink}}</a>
  </div>
</div>
