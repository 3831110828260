import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit{
  @Input() tabTitle: string;
  @Input() index: number;
  @Input() active = false;
  @Input() elementId: number;

  constructor() {}

  ngOnInit(): void {  }
}
