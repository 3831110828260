import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FaqDetail } from 'src/app/models/faqs/faq-detail.model';
import { Faq } from 'src/app/models/faqs/faq.model';
import { Ressource } from 'src/app/models/ressource/ressource.model';
import { FaqsService } from '../faqs/faqs.service';
import { RessourceService } from '../ressources/ressource.service';

@Injectable({
  providedIn: 'root'
})
export class DesireUndertakeService {

  public selectedFaqBehavior = new BehaviorSubject<Faq>(null);
  public faqDetailsBehavior = new BehaviorSubject<FaqDetail[]>([]);
  public ressourceBehavior = new BehaviorSubject<Ressource>(null);
  public selectedFaq$ = this.selectedFaqBehavior.asObservable();
  public faqDetails$ = this.faqDetailsBehavior.asObservable();
  public ressource$ = this.ressourceBehavior.asObservable();

  constructor(
    private faqService:FaqsService,
    private ressourceService:RessourceService
  ) { 

    this.getFaqs();

  }


  private getFaqs():void{
    this.faqService.getFaqs().subscribe(
      results => {
        if (results.length > 0) {
          this.setSelectedFaq(results[0]);
        }
      }
    )
  }


  public setSelectedFaq(faq:Faq):void{
    this.selectedFaqBehavior.next(faq);
    this.faqService.getFaqDetails(faq.id).subscribe(
      results=>{
        this.faqDetailsBehavior.next(results);
      }
    );
    this.ressourceService.getRessourceByFaqId(faq.id).subscribe(
      result=>{
        this.ressourceBehavior.next(result);
      }
    );
  }


  public getSelectedFaq(): Faq{
    return this.selectedFaqBehavior.getValue();
  }


}