import { Component, Input, OnInit } from '@angular/core';
import { Favorite } from 'src/app/models/favorites/favorite.model';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';

@Component({
  selector: 'dep-partner-thumbnail-detail',
  templateUrl: './partner-thumbnail-detail.component.html',
  styleUrls: ['./partner-thumbnail-detail.component.scss']
})
export class PartnerThumbnailDetailComponent implements OnInit {

  @Input() partner : any ;

  public isChecked = false;

  constructor(
    private favoriteService: FavoriteService
  ) { }

  ngOnInit(): void {
   
  }

  ngOnChanges(): void{
    if(this.partner){
      this.isChecked = this.favoriteService.isFavorite(this.partner)!=null;
    }
  }

  toggle(): void{
    
    let fav = new Favorite();
    fav.content = this.partner;
    fav.referenceId = this.partner.id.toString();
    fav.referenceType = this.partner.type;
    
    this.favoriteService.toggle(this.partner);
  }

  
 

}
