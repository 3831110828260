<div class="dropdown" *ngIf="status != 'unrequested'">
    <button class="btn-drop" (click)="toggleDropdown()" [ngClass]="{ 'accepted': status === 'Autorisé', 'refused': status === 'Refusé', 'requested': status === 'Demandé' }">
        {{ status }}
        <img src="/assets/images/icones/arrow.svg" alt="arrow" [class.rotate]="showDropdown" class="arrow customised"/>
    </button>
    <div class="dropdown-content" *ngIf="showDropdown">
        <span (click)="selectOption('Autorisé')">Autorisé</span>
        <span (click)="selectOption('Refusé')">Refusé</span>
    </div>
</div>
<span *ngIf="status == 'unrequested'" class="tag-unrequested">Non demandé</span>