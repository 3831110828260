<article class="actuality">
  <a [routerLink]="replaceUrl()" [ngClass]="{'actuality_grid': isRegionTag}" class="actuality__container" [attr.aria-labelledby]="'actuality-' + actualityIndex">
    <p class="actuality__categories">
      <ng-container *ngIf="!isRegionTag">
        <span class="actuality__category" *ngFor="let tagName of actuality.tags">
          <ng-container *ngIf="tagName?.name !== 'France' && tagName?.name !== 'Europe'"> {{tagName.name}}</ng-container>
        </span>
     </ng-container>
     <ng-container *ngIf="isRegionTag">
        <span class="actuality__category white-space"> 
          <ng-container *ngIf="actuality.tags[0].name !== 'France' && actuality.tags[0].name !== 'Europe'"> {{actuality.tags[0].name}} - {{actuality.rbName}}</ng-container>
        </span>
     </ng-container>
    </p>
    <div class="actuality__image">
      <!-- TO DO REFACTOR IMG SRC   -->
      <div class="actuality__image-wrapper"><img src="{{actuality.imageUrl}}" alt="{{actuality.altImage}}" loading="lazy"></div>
    </div>
    <span id="actuality-{{actualityIndex}}" class="actuality__title">{{actuality.title}}</span>
    <p class="actuality__date">{{actuality.publishDate | date :'d MMMM' | titlecase}}</p>
    <p class="actuality__duration">
      <span>&nbsp;-&nbsp;</span>{{actuality.readingTime}}
    </p>
    <p class="actuality__intro" *ngIf="showDesc">{{actuality.description}}</p>
  </a>
</article>
