<section
  class="page-section-bpi"
  [ngClass]="{
    'page-section-bpi_overflow_none': sectionOverflow === 'none',
    'page-section-bpi_overflow_right': sectionOverflow === 'right',
    'page-section-bpi_overflow_both': sectionOverflow === 'both'
  }"
  #section
>
  <div class="page-section-bpi__headerwrapper" *ngIf="!hideHeader">
    <header class="page-section-bpi__header">
      <h2 class="page-section-bpi__title">
        <ng-content select="[slot='title']"></ng-content>
      </h2>
      <div class="page-section-bpi__content-info">
        <ng-content select="[slot='content-info']"></ng-content>
      </div>
      <a [routerLink]="sectionRouterLink" class="page-section-bpi__see-more-title" *ngIf="sectionRouterLink">
        {{sectionLinkLabel}}
      </a>
    </header>
  </div>
  <div class="page-section-bpi__container">
    <div class="page-section-bpi__inner-container">
      <ng-content></ng-content>

      <a [routerLink]="sectionRouterLink" class="page-section-bpi__see-more-content" *ngIf="sectionRouterLink">
        {{sectionLinkLabel}}
      </a>
    </div>
  </div>
</section>
