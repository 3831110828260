<article class="event" *ngIf="event">
  <a href="{{event.externalLink}}"  target="_blank" rel="noopener noreferrer" class="event__container" [attr.aria-labelledby]="'event-' + eventIndex">
    <p class="event__categories">
      <span class="event__type">{{event.type}}</span>
      <span class="event__category" *ngFor="let category of event.tags">{{category.name}}</span>
    </p>
    <div class="event__image">
      <div class="event__image-wrapper"><img src="{{event.imageUrl}}" alt="{{event.altImage}}" loading="lazy"></div>
    </div>
    <p class="event__date" *ngIf="displayStyle==1">
      <span>{{event.date | date: 'dd'}}</span>
      {{event.date | date: 'LLLL' | titlecase}}
    </p>
    <p class="event__date" *ngIf="displayStyle==2">
      <span class="event__dates">
        {{event.date | date: 'dd'}}
        <dep-icon [icon]="'icon-arrow_forward'" class="icon-arrow"></dep-icon>
        {{event.endDate | date: 'dd'}}
      </span>
      {{event.date | date: 'LLLL' | titlecase}}
    </p>
    <p class="event__date" *ngIf="displayStyle==3">
      <span>
        <p class="event_months debut">{{event.date | date: 'dd/MM'}}</p>
        <p class="event_months fin">{{event.endDate | date: 'dd/MM'}}</p>
      </span>
    </p>
    <h3 id="event-{{eventIndex}}" class="event__title">
      {{event.title}}
      <dep-icon class="icon" [icon]="'icon-call_made'"></dep-icon>
    </h3>
    <div class="event__meta">
      <div class="event__location">
        <dep-icon class="icon" [icon]="'icon-pin'"></dep-icon>
        <p>{{event.location}}</p>
      </div>
      <div class="event__organizer">
        <p>Organisé par&nbsp;<span>{{event.organizer}}</span></p>
      </div>
      <div class="event__pricing">
        <p *ngIf="event.payant == true">Payant</p>
        <p *ngIf="event.payant == false">Gratuit</p>
      </div>
    </div>
    <p class="event__intro">{{event.description}}</p>
  </a>
</article>
