<div>
  <h5>Filtre avec sélection multiple</h5>
  <mat-form-field class="formfield formfield--auto" appearance="outline" floatLabel='auto'>
    <mat-select [formControl]="toppings" multiple placeholder="{{toppingsLabel}}">
      <mat-select-trigger>
        {{toppingsLabel}}
        <span class="multiple-select-number-checked" *ngIf="toppings.value?.length > 0">
          {{toppings.value.length}}
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
    </mat-select>
  </mat-form-field>

  <h5>Filtre avec sélection unique</h5>
  <mat-form-field class="formfield formfield--auto" appearance="outline" floatLabel='auto'>
    <mat-select [formControl]="toppings2" placeholder="{{toppings2Label}}">
      <mat-select-trigger>
        {{toppings2Label}}
        <span class="multiple-select-number-checked" *ngIf="toppings2.value">
          1
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let topping2 of topping2List" [value]="topping2">{{topping2}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
