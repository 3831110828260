<div class="header_container">
  <div class="download-buttons">
    <select id="download-options" (change)="onDownloadOptionChange($event)">
        <option value="" selected disabled>Exporter</option>
        <option value="pdf">PDF</option>
        <option value="csv">CSV</option>
    </select>
  </div>
</div>
<table class="datatable customised" 
[ngClass]="{'add-space': isFilter && displayedStartups.length <= 3 && !showPagination,'remove-space': !isFilter }">
    <thead>
        <tr>
            <th>@STARTUPS</th>
            <th>SIREN</th>
            <th>DESCRIPTION</th>
            <th>
                <div class="th-icon customised">
                    ANNÉE DE CRÉATION
                    <img src="/assets/images/espace-partenaire/sort.png" class="icon transparent" alt="icon-sort"
                        (click)="sort()">
                </div>
            </th>
            <th>
                <div class="th-icon customised">
                    SECTEUR D’ACTIVITÉ
                    <img src="/assets/images/espace-partenaire/filter.png" class="icon transparent" alt="icon-filter"
                        (click)="showFilter()">
                </div>
                <div *ngIf="isFilter" if="filter" class="filter_container">
                    <div class="filter_header">
                        <p class="filter_title">Filtrer par secteur :</p>
                        <button (click)="resetFilters()">
                            <img src="/assets/images/espace-partenaire/reset.png" alt="icon-reset">
                        </button>
                    </div>

                    <div *ngFor="let sector of sectors" class="sector_checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="selectedSectors[sector]" /> {{ sector }}
                        </label>
                    </div>
                    <div class="btn_container">
                        <button class="btn ds-btn ds-btn--primary"
                            (click)="updateDisplayedStartups(true)">Filtrer
                        </button>
                    </div>
                </div>
            </th>
            <th>SITE WEB</th>
            <th>ADRESSE</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let startup of displayedStartups">
            <td>@{{ startup.raison_sociale }} <br /><br />
                <span *ngIf="startup.nouvelle_su === 'true'" class="tag customised">
                    Nouveauté
                </span>
            </td>
            <td>{{ startup.siren }}</td>
            <td>{{ startup.description }}</td>
            <td>{{ startup.date_de_creation.split('-')[0] }}</td>
            <td>{{ startup.secteur }}</td>
            <td>{{ startup.site_web }}</td>
            <td>{{ startup.adresse }}</td>
        </tr>
    </tbody>
</table>

<div class="pagination" *ngIf="showPagination"
 [ngClass]="{'add-space': isFilter && displayedStartups.length <= 3 ,'remove-space': !isFilter }">
    <button [disabled]="currentPage === 1" (click)="prevPage()">
        <dep-icon [icon]="'icon-arrow_back_ios'" class="icon-arrow"></dep-icon>
    </button>
    <button *ngIf="visiblePages[0] > 1" (click)="goToPage(1)" class="customised">1</button>
    <button *ngIf="visiblePages[0] > 2" disabled>...</button>
    <button *ngFor="let page of visiblePages" [class.active]="page === currentPage" class="customised"
        (click)="goToPage(page)">{{ page }}</button>
    <button *ngIf="visiblePages[visiblePages.length - 1] < pages.length - 1" disabled>...</button>
    <button *ngIf="visiblePages[visiblePages.length - 1] < pages.length" (click)="goToPage(pages.length)"
        class="customised">{{ pages.length }}</button>
    <button [disabled]="currentPage === pages.length" (click)="nextPage()">
        <dep-icon [icon]="'icon-arrow_forward_ios'" class="icon-arrow"></dep-icon>
    </button>
</div>