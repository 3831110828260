<div class="video-card__container" [routerLink]="replaceUrl()">
    <div class="video-card__image">
            <img src="/assets/images/icones/play.png" alt="icon-play" class="icon-play customised" >
        <div class="video-card__image-wrapper">
            <img src="{{video.imageUrl}}" alt="{{video.altImage}}" loading="lazy" draggable="false" class="customised" >
        </div>
    </div>
    <div class="video-card__header">
        <p>vidéo</p>
        <p>publié le {{video.publishDate  | date: 'dd/MM/yyyy'}}</p>
        <p class="video-duration">{{video.duration}}
            <img src="/assets/images/icones/timer.png" alt="icon-timer" class="customised" />
        </p>
    </div>
    <p class="video-card__title">{{video.title}}</p>
    <p class="video-card__description">{{video.description}}</p>
</div>