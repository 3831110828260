import { Component, OnInit, Input } from '@angular/core';
import { Forum } from 'src/app/models/forums/forum.model';

@Component({
  selector: 'dep-forums-list',
  templateUrl: './forums-list.component.html',
  styleUrls: ['./forums-list.component.scss']
})
export class ForumsListComponent implements OnInit {
  @Input('forums') forums: Array<Forum>;
  constructor() { }

  ngOnInit(): void {

  }
}
