import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'dep-definitions-list-item',
  templateUrl: './definitions-list-item.component.html',
  styleUrls: ['./definitions-list-item.component.scss']
})
export class DefinitionsListItemComponent implements OnInit, AfterViewInit {

  @Input() keyword: string;
  @Input() definition: string;
  @Input() index: number;
  @Input() anchorTitle: string;
  constructor(private scroller: ViewportScroller) { }

  ngAfterViewInit(): void {
    if (this.anchorTitle && this.anchorTitle == this.keyword)
      this.scrollToAnchor();
  }

  ngOnInit(): void {
  }
  
  scrollToAnchor() {
    setTimeout(()=>{
      let header = document.querySelector('[class="main-header"]');
      this.scroller.setOffset(() => [0, header.clientHeight]);
      this.scroller.scrollToAnchor(this.anchorTitle);
    },500)
  }
}
