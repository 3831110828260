<a class="post_container" href="{{ssoCodeUrl+post.url}}" target="_blank" rel="noopener noreferrer">
    <div class="post_wrapper">
        <div class="post_author">
            <img *ngIf="post.author.avatar && isUrl(post.author.avatar)" src="{{post.author.avatar}}" alt="image author" class="customised" />
            <div *ngIf="post.author.avatar && !isUrl(post.author.avatar)" [style.background-color]="post.author.avatar" class="circle circle-l">
                <span [style.color]="post.author.avatar | darkenColor" class="initials">{{getInitials(post.author.name)}}</span>
            </div>
            <div class="author_infos">
                <p class="title">{{post.author.name}}</p>
                <p class="date">Le {{post.createdAt | date :'d/MM/YYYY à h:mm' }}</p>
            </div>
        </div>
        <p class="description" [innerHTML]="post.content | markdown"></p>
        <div class="post_image" *ngIf="post.media">
            <img src="{{post.media}}" class="image customised" />
        </div>
        <div class="post_infos">
            <div class="post_likes">
                <img src="/assets/images/icones/icon-like.svg" alt="icon like" class="customised">
                <p>{{post.reactions.likes}} Likes</p>
            </div>
            <p>{{post.reactions.comments}} Commentaires</p>
        </div>
        <!-- <div class="post_comments">
            <div class="post_comment" *ngFor="let comment of post.comments">
                <img *ngIf="comment.author.avatar && isUrl(comment.author.avatar)" src="{{comment.author.avatar}}" alt="image comment" class="customised" />
                <div *ngIf="comment.author.avatar && !isUrl(comment.author.avatar)" [style.background-color]="comment.author.avatar" class="circle">
                    <span [style.color]="comment.author.avatar | darkenColor" class="initials">{{getInitials(comment.author.name)}}</span>
                </div>
                <div class="comment_infos">
                    <p class="comment_title">{{comment.author.name}}</p>
                    <p class="comment_description" [innerHTML]="truncateDescription(comment.content,100) | markdown"></p>
                </div>
            </div>
        </div> -->
    </div>
</a>