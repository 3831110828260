import { Component, Input, OnInit } from '@angular/core';
import { Study } from 'src/app/models/studies/study.model';

@Component({
  selector: 'dep-studies-list',
  templateUrl: './studies-list.component.html',
  styleUrls: ['./studies-list.component.scss']
})
export class StudiesListComponent implements OnInit {
  @Input() responsiveDisplay: string;
  @Input('studies') studies: Study[];

  constructor() { }

  ngOnInit(): void {
  }

}
