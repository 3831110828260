<div class="projects-list"
[class.contests-list_responsive-carousel]="responsiveDisplay == 'carousel'"
aria-live="polite">
    <ng-container *ngFor="let contest of contests; let i = index">
      <dep-contests-thumbnail [contest]="contest" [contestIndex]="i">
  
      </dep-contests-thumbnail>
     <!-- <app-project-thumbnail-prototype
        [projectType]="'Appel à projet'"
        [projectCategories]="['Tech']"
        [projectImage]="'/assets/images/actualites-ressources/EtudeBiotech.jpg'"
        [projectRemainingDays]="'2'"
        [projectTitle]="'Lancement de l’Accélérateur Jeux Vidéo'"
        [projectStart]="'31 juillet'"
        [projectEnd]="'2 septembre'"
        [projectIntro]="'Entrepreneurs ne passez pas a coté du nouvel Accélérateur Jeux Vidéo, un programme qui, sur 12 mois, vous forme par grande école, muscle votre carnet d’adresse et vous donne accès à du conseil de qualité ! '"
        [projectIndex]="i"
        [projectRouterLink]="'router-link-to-be-defined'"
      >
      </app-project-thumbnail-prototype>-->
    </ng-container>
  </div>
  