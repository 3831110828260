<!-- ACTU -->
<a *ngIf="!isDifferentTags" class="card_container" href="{{url}}"  style="background-image:linear-gradient(180deg, rgba(0, 0, 0, 0.00) 28%, rgba(0, 0, 0, 0.87) 100%),
 url('{{content.imageUrl}}')" [ngClass]="{'large-size': !isSmall,'small-size': isSmall && !isExpanded, 'small-size-expand': isSmall && isExpanded }">
    <div class="card_wrapper">
        <div class="card_content">
            <div class="tags_wrapper">
                <div class="tag" style="background-color: #FFCD00;" *ngIf="index == 0"><span>À la une</span></div>
                <div class="tag" *ngIf="content.tags[0]"><span>{{content.tags[0].name}}</span></div>
                <div class="tag" *ngIf="content.tags[1]"><span>{{content.tags[1].name}}</span></div>
            </div>
            <p class="title">{{content.title}}</p>
            <p class="text">{{text}}</p>
        </div>
    </div>
</a>
<!-- EVENT -->
<a *ngIf="isDifferentTags" class="card_container" href="{{url}}" target="_blank" rel="noopener noreferrer" style="background-image:linear-gradient(180deg, rgba(0, 0, 0, 0.00) 28%, rgba(0, 0, 0, 0.87) 100%),
 url('{{content.imageUrl}}')" [ngClass]="{'large-size': !isSmall,'small-size': isSmall }">
    <div class="card_wrapper">
        <div class="card_content" [class.add-space]="isSmall">
            <div class="tags_wrapper" >
                <div class="tag" style="background-color: #FFCD00;" *ngIf="content.secteurs && content.secteurs[0]"><span>{{content.secteurs[0].title}}</span></div>
                <div class="tag" *ngIf="content.formats && content.formats[0]"><span>{{content.formats[0].title}}</span></div>
            </div>
            <p class="title">{{content.title}}</p>
            <p class="text">{{text}}</p>
        </div>
    </div>
</a>
