<button type="button" class="article-header__back" (click)="back()" *ngIf = "showReturnButton">
  <dep-icon class="icon" [icon]="'icon-arrow_back_ios'"></dep-icon>
  Retour
</button>

<div class="article-header__breadcrumb">
  <ng-content select="[breadcrumb]"></ng-content>
</div>

<p class="article-header__published-date" *ngIf="articleDateTime">Publié le <time [attr.datetime]="articleDateTime">{{articleDate}}</time></p>
<h1 class="article-header__title">{{articleTitle}}</h1>
<p class="article-header__reading-time" *ngIf="articleReadingTime">
  <time [attr.datetime]="'PT' + articleReadingTime + 'M'">{{articleReadingTime}}</time>
</p>

<div class="article-header__action-wrapper" aria-live="polite" *ngIf="buttonIcon && buttonText">
  <button *ngIf="!isPopover" class="article-header__action" (click)="this.buttonAction.emit();">
    <dep-icon class="icon" [icon]="buttonIcon"></dep-icon>&nbsp;{{buttonText}}
  </button>
 
  <div type="button" *ngIf="isPopover">
    <button #toggleButton (click)="togglePopover()" class="article-header__action popover__button">
      <dep-icon class="icon" [icon]="buttonIcon"></dep-icon>&nbsp;{{buttonText}}
    </button>
    <div #popover id="popover" [ngClass]="{'show__popover': showPopup}" class="popover__container">
      <div class="popover__content">
        <div class="popover__title">
          <strong>Partager avec votre réseau</strong>
          <div class="underline"></div>
        </div>
        <div class="popover__icons">
          <a href="{{twitterUrl}}" target="_blank" rel="noopener noreferrer"><img src="/assets/images/icones/twitter.png" alt="twitter"/></a>
          <a href="{{linkedinUrl}}" target="_blank" rel="noopener noreferrer"><img src="/assets/images/icones/linkedin.png" alt="linkedin"/></a>
        </div>
        <p>ou copier le lien</p>
        <div class="popover__link">
          <input value='{{urlPage}}' type="text">
          <button (click)="copyUrl()">Copier lien</button>
        </div>
      </div>
    </div>
  </div>
  <p  id="btn_msg" class="article-header__action-message" *ngIf="isPopover && showButtonMessage" >{{buttonMessage}}</p>
  <p class="article-header__action-message" [@inOutAnimation] *ngIf="showButtonMessage && !isPopover">{{buttonMessage}}</p>
</div>
