import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-market-access',
  templateUrl: './market-access.component.html',
  styleUrls: ['./market-access.component.scss'],
})
export class MarketAccessComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Envie d\'entreprendre - de l\'invention à l\'innovation'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Les questions clés pour faire évoluer son projet technologique en un couple produit / business model adapté à son marché.',
    });
  }
}
