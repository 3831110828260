<article class="actuality">
  <a [routerLink]="replaceUrl()" class="actuality__container" [attr.aria-labelledby]="'actuality-' + actualityIndex">
    <p class="actuality__categories">
      <span class="actuality__category" *ngFor="let tag of actuality.tags">{{tag.name}}</span>
    </p>
    <div class="actuality__image">
      <div class="actuality__image-wrapper"><img src="{{actuality.imageUrl}}" alt="" loading="lazy"></div>
    </div>
    <h3 id="actuality-{{actualityIndex}}" class="actuality__title">{{actuality.title}}</h3>
    <p class="actuality__date">{{actuality.publishDate | date :'d MMMM' | titlecase}}</p>
    <p class="actuality__duration">
      <span>&nbsp;-&nbsp;</span>{{actuality.readingTime}}
    </p>
    <p class="actuality__intro">{{actuality.description}}</p>
  </a>
</article>
