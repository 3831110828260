import { Component, Input, OnInit } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';

@Component({
  selector: 'dep-actualities-list',
  templateUrl: './actualities-list.component.html',
  styleUrls: ['./actualities-list.component.scss'],
})
export class ActualitiesListComponent implements OnInit {
  @Input() highlightFirst: boolean = false;
  @Input() responsiveDisplay: string;
  @Input() actualities: Actuality[];

  constructor() { }

  ngOnInit(): void { }
}
