import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { FormStatus } from 'src/app/models/forms/form-status.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailSenderService {

  private SEND_EMAIL_API = '';

  constructor(private http: HttpClient, private globalUrls: GlobalURLs) {
    this.SEND_EMAIL_API = environment.apiUrl + this.globalUrls.apiSendContactEmail;

  }

  public sendEmail(body:string,subject:string):Observable<FormStatus>{
    let messageBody = {
      "subject": subject,
      "body": body
    }

    return this.http.post<FormStatus>(
      this.SEND_EMAIL_API,
      messageBody
    );
  }
}
