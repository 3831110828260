import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  @Input() quote: string;
  @Input() author: string;

  constructor() { }

  ngOnInit(): void {
  }

}
