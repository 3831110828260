<section  [ngClass]="{'page-section--dark': theme === 'dark',
              'page-section--medium': theme === 'medium',
              'page-section--light': theme === 'light',
              'page-section_partner-space': isPartnerSpace ,
              'page-section': !isPartnerSpace}" [class.page-section--transparent]="bgimage">

  <div class="page-section__content">
    <div style="display: flex; align-items: center;">
      <img class="page-section__title-logo position-logo" src="{{logoTitle}}" alt="" *ngIf="logoTitle">
      <h2 class="page-section__title" *ngIf="title">{{title}}</h2>
    </div>
    <ng-content></ng-content>
  </div>

  <div class="page-section__background-text" role="presentation" aria-hidden="true" *ngIf="bgtext">{{bgtext}}</div>
  <div class="page-section__background-image" role="presentation" aria-hidden="true" *ngIf="bgimage">
    <img src="{{bgimage}}" alt="">
  </div>
</section>