<div class="popup_container" *ngIf="showPopup"
 [ngClass]="{'right-position': trianglePosition === 'left-bottom',
             'top-position': trianglePosition === 'top-center',
             'top-right': trianglePosition === 'top-right',}">
    <div class="popup_header">
        <p>{{title}}</p>
        <button class="btn-close" (click)="closePopup()">
            <img src="/assets/images/icones/cancel.svg" alt="icon close" class="customised" />
        </button>
    </div>
    <p class="text">{{text}}</p>
    <p class="warning" *ngIf="stepId === 2">
        <img src="/assets/images/icones/ic_warning.svg" alt="icon warning" class="customised">
        Activez le partage de données dans votre profil pour accéder à l’annuaire.
    </p>
    <div class="popup_footer">
        <p>étape {{stepId}}/3</p>
        <button (click)="next()" *ngIf="stepId != 3" class="ds-btn--primary">Suivant</button>
        <button  (click)="closePopup()" [routerLink]="['/mon-espace-partenaire/profil.html']" *ngIf="stepId === 3" class="ds-btn--primary">Accéder à mon profil</button>
    </div>
</div>