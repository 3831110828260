import { Component, OnInit, Input } from '@angular/core';
import { Toolbox } from 'src/app/models/toolbox/Toolbox.model';
import { ToolboxService } from 'src/app/services/toolbox/toolbox.service';

@Component({
  selector: 'dep-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit {
  @Input() toolboxes:Toolbox[];

  constructor() { }

  ngOnInit(): void {

  }

}
