import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Tag } from 'src/app/models/tags/tag.model';
import { TagRequest } from 'src/app/models/tags/tagRequest.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private REST_API_SERVER = '';
  private TAGS_URL = '';

  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  getTags(tagRequest?:TagRequest): Observable<Tag[]> {

    let params = new HttpParams();
    if (tagRequest?.collectionName) {
      params = params.append("collectionName", tagRequest.collectionName);
    }
    if (tagRequest?.collectionType) {
      params = params.append("collectionType", tagRequest.collectionType);
    }
    if (tagRequest?.technicalsTagCodes && tagRequest?.technicalsTagCodes.length>0) {
      params = params.appendAll({"technicalsTagCodes":  tagRequest.technicalsTagCodes});
    }
    if (tagRequest?.excludeActualityIds && tagRequest?.excludeActualityIds.length>0) {
      params = params.appendAll({"excludeActualityIds":  tagRequest.excludeActualityIds});
    }

    this.TAGS_URL = this.ressourcePath.getTagsUrl();

    return this.http.get<Tag[]>(this.REST_API_SERVER + this.TAGS_URL, {params});
  }

}
