import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { DetailsRegion } from 'src/app/models/observatory/regions-details/details-regions';
import { RegionDetails } from 'src/app/models/observatory/regions-details/regions-details';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RegionsDetailsService {

  private ObsUrl = '';

  constructor(private httpClient: HttpClient, globalUrls: GlobalURLs) {
    this.ObsUrl = environment.apiUrlAws + globalUrls.apiRegionsDetails;
  }


  public getRegionsDetails(): Observable<RegionDetails[]> {
    return this.httpClient.get<DetailsRegion[]>(this.ObsUrl).pipe(
      map(
        results =>
          results.map(
            result => this.mapToRegionsDetails(result)
          )
      )
    );
  }

  private mapToRegionsDetails(data: DetailsRegion): RegionDetails {
    return {
      siren:data.siren,
      region: data.region,
      description:data.description,
      address:data.address_full,
      rd:data.dr,
      companyUrl:data.companies_website_url,
      postalCode:data.zip_code,
      adressLat:data.address_lat,
      adressLng:data.address_lng,
      logo:data.logo,
      sector:data.secteur,
      companyFoundedIn:data.date_de_creation,
      companyName:data.companies_name,
      companyTotalFunding:data.companies_total_funding,
      totalEmployment:data.nb_emploi,
      tags:[]
    }
  }
}
