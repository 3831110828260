import { Component, Input, OnInit } from '@angular/core';
import { Favorite } from 'src/app/models/favorites/favorite.model';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { PartnerRequest } from 'src/app/models/partners/partnerRequest.model';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
import { PartnersService } from 'src/app/services/partners/partners.service';


@Component({
  selector: 'dep-service-thumbnail',
  templateUrl: './service-thumbnail.component.html',
  styleUrls: ['./service-thumbnail.component.scss']
})
export class ServiceThumbnailComponent implements OnInit {

  @Input() service : any ;
  @Input() index : number;
  @Input() isHomePage : boolean;

  public isChecked = false;

  public description: string = "";
  public partnerList = [];
  public subTitle = "";
  private limit = 50;

  constructor(
    private partnerService: PartnersService,
    private favoriteService: FavoriteService
  ) { }

  ngOnInit(): void {

    if(this.service.description.length>this.limit){
      this.description = this.service.description.slice(0, this.limit) + '...'
    }
    else{
      this.description = this.service.description;
    }
  
    let partnerRequest = new PartnerRequest();
    partnerRequest.serviceId = this.service.id;
    this.partnerService.getPartners(partnerRequest).subscribe(
      result => {
        this.partnerList = result;
        this.getSubTitle();
      }
    )

    this.favoriteService.favorites$.subscribe(
      _ => {
        let favorite = new Favorite();
        favorite.referenceId = this.service.id.toString();
        favorite.referenceType = ReferenceType.SERVICE;
        favorite.content = this.service
        this.isChecked = this.favoriteService.isFavorite(favorite)!=null;
      }
    )
  }

  getSubTitle(): void{
    if(this.partnerList.length>0){
      this.subTitle = this.partnerList[0].name;
    }
    if(this.partnerList.length>1){
      this.subTitle += " +"+(this.partnerList.length-1);
    }
  }

  toggle(): void{

    let fav = new Favorite();
    fav.content = this.service;
    fav.referenceId = this.service.id.toString();
    fav.referenceType = this.service.type;
    
    this.favoriteService.toggle(fav);
  }


}
