<div
  class="redirection"
  [ngClass]="{'redirection--offset-medium': offset === 'medium',
            'redirection--offset-large': offset === 'large',
            'redirection--nodecoration': noDecoration === true}"
>
  <h2 class="redirection__title">{{title}}</h2>

  <p class="redirection__content">
    <ng-content></ng-content>
  </p>

  <div class="redirection__action">
    <!-- Si c'est un lien interne au site  -->
    <a *ngIf="redirection" class="ds-btn ds-btn--primary" [routerLink]="redirection">{{titleLink}}</a>
    
    <!-- Si c'est un lien externe au site  -->
    <a *ngIf="externalRedirection" class="ds-btn ds-btn--primary" [href]="externalRedirection" target="_blank" rel="noopener noreferrer">{{titleLink}}</a>
  </div>
</div>
