import { Component, OnInit } from "@angular/core";
import { Startup } from "src/app/models/observatory/startups/startup.model";
import { DashboardService } from "src/app/services/observatory/dashboard.service";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';

interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
}

@Component({
  selector: "dep-datatable-observatory",
  templateUrl: "./datatable-observatory.component.html",
  styleUrls: ["./datatable-observatory.component.scss"]
})
export class DatatableObservatoryComponent implements OnInit {
  public startups: Startup[];
  public displayedStartups: any[] = [];
  public currentPage: number = 1;
  public pageSize: number = 5; //number of items to display per page
  public showPagination: boolean = false;
  public pages: number[] = [];
  public visiblePages: number[] = [];
  public sortDirection: string;
  public sectors: string[] = [];
  public selectedSectors: { [key: string]: boolean } = {};
  public isFilter: boolean = false;
  selectedDownloadOption: string = '';
  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.dashboardService.getDashboard().subscribe(response => {
      this.startups = response.listDesSu;
      this.init();
    });
  }

  init() {
    this.sort();
    this.updateDisplayedStartups(false);
    this.startups.forEach(startup => {
      if (!this.sectors.includes(startup.secteur)) {
        this.sectors.push(startup.secteur);
      }
    });
  }

  cancelFilter(e) {
    var container = document.getElementById("filter");
    if (container && !container.contains(e.target)) {
      container.style.display = "none";
    }
  }

  updateDisplayedStartups(resetPagination: boolean) {
    this.isFilter = false;
    let filteredStartups = this.startups;

    // If no sector is selected, all sectors are considered selected by default
    if (Object.values(this.selectedSectors).every(value => value === false)) {
      this.selectedSectors = {};
    }
    // Filter the startups based on the selected sectors
    if (Object.keys(this.selectedSectors).length > 0) {
      filteredStartups = this.startups.filter(
        startup => this.selectedSectors[startup.secteur]
      );
    }

    if (resetPagination) {
      this.currentPage = 1;
    }
    // Update the list of displayed offers based on the pagination settings
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedStartups = filteredStartups.slice(startIndex, endIndex);

    // Update the list of pages numbers in the pagination controls
    const pageCount = Math.ceil(filteredStartups.length / this.pageSize);
    this.pages = [];
    for (let i = 1; i <= pageCount; i++) {
      this.pages.push(i);
    }
    this.showPagination = pageCount > 1;
    this.updateVisiblePages();
  }

  updateVisiblePages(): void {
    const pagesToShow = 4;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    let startPage = Math.max(this.currentPage - halfPagesToShow, 1);
    let endPage = Math.min(startPage + pagesToShow - 1, this.pages.length);
    if (endPage - startPage < pagesToShow - 1) {
      startPage = Math.max(endPage - pagesToShow + 1, 1);
    }
    this.visiblePages = Array(endPage - startPage + 1)
      .fill(0)
      .map((_, i) => startPage + i);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateDisplayedStartups(false);
    }
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
      this.updateDisplayedStartups(false);
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.updateDisplayedStartups(false);
  }

  public sort(): void {
    if (!this.sortDirection) {
      this.startups.sort((a: Startup, b: Startup) => {
        const aValue = JSON.parse(a.nouvelle_su) ? 1 : 0;
        const bValue = JSON.parse(b.nouvelle_su) ? 1 : 0;

        return bValue - aValue;
      });

      this.sortDirection = "asc";
    } else {
      // Change the current sort order (asc or desc)
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";

      // Sort the startups based on the year
      this.startups.sort((a: Startup, b: Startup) => {
        let dateA = a["date_de_creation"].split("-");
        let dateB = b["date_de_creation"].split("-");
        //
        if (this.sortDirection === "asc") {
          return (
            Number(dateA[0]) - Number(dateB[0]) ||
            Number(dateA[1]) - Number(dateB[1]) ||
            Number(dateA[2]) - Number(dateB[2])
          ); // Sort in ascending order
        } else {
          return (
            Number(dateB[0]) - Number(dateA[0]) ||
            Number(dateB[1]) - Number(dateA[1]) ||
            Number(dateB[2]) - Number(dateA[2])
          ); // Sort in descending order
        }
      });
    }

    this.updateDisplayedStartups(false);
  }

  public showFilter(): void {
    this.isFilter = !this.isFilter;
  }

  resetFilters() {
    this.selectedSectors = {};
    for (const sector of this.sectors) {
      this.selectedSectors[sector] = false;
    }
  }

  // Method for downloading as PDF
  downloadAsPdf(): void {
    const doc = new jsPDF() as jsPDFCustom;
    doc.setProperties({ title: 'Liste des startups' });

    // Data table
    const tableData = [];
    this.displayedStartups.forEach(startup => {
      const rowData = [
        startup.raison_sociale,
        startup.siren,
        startup.description,
        startup.date_de_creation.split('-')[0],
        startup.secteur,
        startup.site_web,
        startup.adresse
      ];
      tableData.push(rowData);
    });

    // Add centered title
    const title = "Liste des startups";
    const titleWidth = doc.getStringUnitWidth(title) * 18 / doc.internal.scaleFactor;
    const pageWidth = doc.internal.pageSize.width;
    const xPosition = (pageWidth - titleWidth) / 2;
    doc.setFontSize(18);
    doc.setFillColor('#071a39');
    doc.text(title, xPosition, 10);
    doc.setLineWidth(0.5);
    doc.line(10, 20, pageWidth - 10, 20);

    // Generate PDF
    doc.autoTable({
      head: [['@STARTUPS', 'SIREN', 'DESCRIPTION', 'ANNÉE DE CRÉATION', 'SECTEUR D’ACTIVITÉ', 'SITE WEB', 'ADRESSE']],
      body: tableData,
      startY: 30
    });

    // download PDF
    doc.save('liste_des_startups.pdf');
  }

  //Method for downloading as CSV
  downloadAsCsv(): void {
    let csvContent = 'data:text/csv;charset=utf-8,\uFEFF';
    const doc = new jsPDF() as jsPDFCustom;

    // CSV headers
    const headers = [
      '@STARTUPS',
      'SIREN',
      'DESCRIPTION',
      'ANNÉE DE CRÉATION',
      'SECTEUR D’ACTIVITÉ',
      'SITE WEB',
      'ADRESSE'
    ];
    csvContent += headers.join(',') + '\n';

    // CSV Data
    this.displayedStartups.forEach(startup => {
      const rowData = [
        startup.raison_sociale,
        startup.siren,
        startup.description,
        startup.date_de_creation.split('-')[0],
        startup.secteur,
        startup.site_web,
        startup.adresse
      ];
      csvContent += rowData.join(',') + '\n';
    });

    // Download CSV
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'liste_des_startups.csv');
    document.body.appendChild(link); // Necessary for some browsers
    link.click();

  }

  onDownloadOptionChange(event: Event): void {
    this.selectedDownloadOption = (event.target as HTMLSelectElement).value;
    if (this.selectedDownloadOption === "pdf") {
      this.downloadAsPdf();
    } else if (this.selectedDownloadOption === "csv") {
      this.downloadAsCsv();
    }
  }

}
