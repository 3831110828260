import { Component, OnInit, Input, Inject } from '@angular/core';
import { Content } from 'src/app/models/dynamic-contents/content.model';
import { Forum } from 'src/app/models/forums/forum.model';
import { ForumService } from 'src/app/services/forums/forum.service';

@Component({
  selector: 'dep-forums-list-item',
  templateUrl: './forums-list-item.component.html',
  styleUrls: ['./forums-list-item.component.scss']
})
export class ForumsListItemComponent implements OnInit {

  @Input('forum') forum: Forum;
  public content: Content;

  constructor(@Inject(ForumService) private forumService: ForumService) { }

  ngOnInit(): void {
    this.content = this.getForumContent();
    if (this.content === undefined) {
      this.content = this.forum.contents[0];
    }
  }

  /**
   * Get content of the citation in forum
   */
  private getForumContent(): Content {
    this.forum.contents.sort((quoteId1, quoteId2) => quoteId1.quoteId === quoteId2.quoteId ? -1 : 0);
    return this.forum.contents.find(content => (content.quote != null && content.quote != ""
      && content.authorName != null && content.authorName != ""
      && content.authorRole != null && content.authorRole != ""
      && content.authorPhotoProfile != null && content.authorPhotoProfile != ""));
  }

}
