<article class="video-thumbnail">
    <div class="video-thumbnail__image">
        <div class="video-thumbnail__image-wrapper">
            <img src="{{video.imageUrl}}" alt="{{video.altImage}}" loading="lazy" draggable="false">
        </div>
    </div>
    <div class="video-thumbnail__meta">
        <span class="video-thumbnail__title">{{video.title}}</span>
        <p class="video-thumbnail__author">{{video.authorNameAndRole}}</p>
        <p class="video-thumbnail__intro">{{video.description}}</p>
        <p class="video-thumbnail__duration">
            <dep-icon class="icon" [icon]="'icon-timer'"></dep-icon>
            {{video.duration}}
        </p>
        <a [routerLink]="replaceUrl()" class="video-thumbnail__button">
          visionner
          <!-- hidden text to complete the link label (for screen readers and bots)  -->
           <span class="sr-only"> la vidéo : {{video.title}}</span>
        </a>
    </div>
</article>