import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class TrainingRequest {
  limit: number;
  start: number;
  tagsIds?: number[];
  type: string;
  levels?: string [];
}
