<div class="toolbox">
    <article class="toolbox-card" *ngFor="let toolbox of toolboxes">
        <h3 class="toolbox-card__title">{{toolbox.title}}</h3>
        <p class="toolbox-card__description"*ngIf="toolbox.description" >{{toolbox.description | slice:0:150 }}</p>
        <ul class="toolbox-card__list">
            <li *ngFor="let item of toolbox.tools">
                <a href="{{item.linkUrl}}" target="_blank" rel="noopener noreferrer" class="toolbox-card__link" *ngIf="item.linkId">
                    <dep-icon [icon]="'icon-open_in_new'" class="icon"></dep-icon>{{item.linkTitle}}
                </a>
                <a href="{{item.documentUrl}}" class="toolbox-card__link" target="_blank" rel="noopener noreferrer" *ngIf="item.documentId">
                    <dep-icon [icon]="'icon-download'" class="icon"></dep-icon>{{item.documentTitle}}
                </a>
            </li>
        </ul>
    </article>
</div>