import { Component, Input, OnInit } from '@angular/core';
import { JobOffer } from 'src/app/models/job-offers/job-offer.model';

@Component({
  selector: 'dep-job-offers-list',
  templateUrl: './job-offers-list.component.html',
  styleUrls: ['./job-offers-list.component.scss']
})
export class JobOffersListComponent implements OnInit {
  @Input() responsiveDisplay: string;
  @Input() jobOffers: JobOffer[];
  @Input() offerType:string;

  constructor() { }

  ngOnInit(): void {
  }




}
