import { Component, OnInit, Input } from '@angular/core';
import { DirectAccess } from 'src/app/models/direct-access/directAccess.model';

@Component({
  selector: 'dep-info-card-list',
  templateUrl: './info-card-list.component.html',
  styleUrls: ['./info-card-list.component.scss']
})
export class InfoCardListComponent implements OnInit {
  @Input() contents : DirectAccess[];
  constructor() { }

  ngOnInit(): void {
  }

}
