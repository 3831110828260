import { AfterViewInit, Component, ContentChildren, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { finalize, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { CarouselSlideDirective } from '../../../shared/directives/carousel-slide.directive';

@Component({
  selector: 'dep-quote-blocks-carousel',
  templateUrl: './quote-blocks-carousel.component.html',
  styleUrls: ['./quote-blocks-carousel.component.scss']
})
export class QuoteBlocksCarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('carousel') carouselView: ElementRef;
  @ContentChildren(CarouselSlideDirective) carouselSlides: QueryList<CarouselSlideDirective>;
  private destroy$ = new Subject<void>();
  // Scroll indicator
  @ViewChild('progressBar') progressBar: ElementRef;
  // Scroll buttons
  @ViewChild('buttonPrevious', { read: ElementRef, static: false }) buttonPrevious: ElementRef;
  @ViewChild('buttonNext', { read: ElementRef, static: false }) buttonNext: ElementRef;
  carouselTotalWidth: number;
  carouselVisibleWidth: number;
  carouselScrollLeft: number;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const carousel = this.carouselView.nativeElement;
    const mousedown$ = fromEvent(carousel, 'mousedown');
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(carousel, 'mouseup');

    if (this.buttonPrevious && this.buttonNext) {
      this.setButtonStates();
      }

    // Swipe events
    let startX = 0;
    let scrollLeft = 0;

    mousedown$.pipe(
      tap((event: any) => {
        startX = event.pageX - carousel.offsetLeft;
        scrollLeft = carousel.scrollLeft;
        this.renderer.addClass(carousel, 'active');
      }),
      switchMapTo(mousemove$.pipe(
        tap((event: any) => {
          event.preventDefault();
          const x = event.pageX - carousel.offsetLeft;
          const SCROLL_SPEED = 4;
          const walk = (x - startX) * SCROLL_SPEED;
          carousel.scrollLeft = scrollLeft - walk;
        }),
        takeUntil(mouseup$),
        finalize(() => this.renderer.removeClass(carousel, 'active'))
      )),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  scrollToDirection(direction: number): void {
    const carousel = this.carouselView.nativeElement;

    carousel.scrollBy({
      left: direction * carousel.clientWidth,
      behavior: 'smooth'
    });
  }

  scrollEvent(): void {
    let timeOutId: any;

    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      this.setProgressbarValues();
      this.setButtonStates();
    }, 150);
  }

  calcScrollPercent(): number {
    const carousel = this.carouselView.nativeElement;
    this.carouselTotalWidth = carousel.scrollWidth;
    this.carouselVisibleWidth = carousel.clientWidth;
    this.carouselScrollLeft = carousel.scrollLeft;

    const carouselWidthNotInViewPort =
      this.carouselTotalWidth - this.carouselVisibleWidth <= 0
        ? 0.1
        : this.carouselTotalWidth - this.carouselVisibleWidth;

    return (this.carouselScrollLeft / carouselWidthNotInViewPort) * 100;
  }

  setProgressbarValues(): void {
    const roundedPercentage = Math.round(this.calcScrollPercent());

    if (this.progressBar) {
      this.renderer.setStyle(this.progressBar.nativeElement, '--scale', roundedPercentage / 100, 2);
      this.renderer.setAttribute(this.progressBar.nativeElement, 'aria-valuenow', roundedPercentage.toString());
    }
  }

  setButtonStates(): void {
    const roundedPercentage = Math.round(this.calcScrollPercent());

    if (this.buttonPrevious && roundedPercentage <= 0) {
      this.renderer.setAttribute(this.buttonPrevious.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.buttonPrevious.nativeElement, 'disabled');
    }
    if (this.buttonNext && roundedPercentage >= 100) {
      this.renderer.setAttribute(this.buttonNext.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.buttonNext.nativeElement, 'disabled');
    }
  }

}
