<!-- If the breadcrumb item contains a link (page ancestor) -->
<ng-container *ngIf="itemRouterLink">
  <a [routerLink]="itemRouterLink"><ng-container *ngTemplateOutlet="content"></ng-container></a>
</ng-container>

<!-- If the breadcrumb item contains text only (current page) -->
<ng-container *ngIf="!itemRouterLink">
  <span><ng-container *ngTemplateOutlet="content"></ng-container></span>
</ng-container>

<!-- Template for the ng-content (ng-content must be unique) -->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
