import { Component, Input, OnInit } from '@angular/core';
import { Offer } from 'src/app/models/offers/offer.model';
import { PartnerOfferService } from 'src/app/services/partner-offers/partner-offer.service';

@Component({
  selector: 'dep-datatable-taleez',
  templateUrl: './datatable-taleez.component.html',
  styleUrls: ['./datatable-taleez.component.scss']
})
export class DatatableTaleezComponent implements OnInit {

  @Input() offers: Offer[] = [];
  @Input() offerType : string;

  offerStatus = [
    { title: "En ligne", enable: false, selected: false },
    { title: "Suspendue", enable: false, selected: false },
    { title: "Archivée", enable: false, selected: false }
  ];

  selectedStatus: string = "";
  displayedOffers: any[] = [];
  currentPage: number = 1;
  pageSize: number = 5; //number of items to display per page
  showPagination: boolean = false;
  pages: number[] = [];
  visiblePages: number[] = [];
  selectedTypeElement: Element;
  selectedDownloadOption: string = '';

  constructor(private partnerOfferService: PartnerOfferService) { }

  ngOnInit() {
    this.initButton();
    this.updateDisplayedOffers();
  }

  initButton(): void {
    this.offerStatus.forEach(status => {
      let filtredOffers = this.offers.filter(offers => offers.status === status.title);
      if (filtredOffers.length > 0) {
        status.enable = true;
        if (this.selectedStatus === "") {
          this.selectedStatus = status.title;
          status.selected = true;
        }
      }
    });

  }

  updateDisplayedOffers() {
    // Filter the list of offers based on the selected Offer type
    let filteredOffers = this.offers.filter(offer => offer.status === this.selectedStatus && offer.type === this.offerType)
    .sort((a, b) => {
      const dateA = a.publicationDate.split("/").reverse().join("/");
      const dateB = b.publicationDate.split("/").reverse().join("/");
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    });

    // Update the list of displayed offers based on the pagination settings  
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedOffers = filteredOffers.slice(startIndex, endIndex);

    // Update the list of pages numbers in the pagination controls
    const pageCount = Math.ceil(filteredOffers.length / this.pageSize);
    this.pages = [];
    for (let i = 1; i <= pageCount; i++) {
      this.pages.push(i);
    }
    this.showPagination = pageCount > 1;
    this.updateVisiblePages();
  }

  updateVisiblePages(): void {
    const pagesToShow = 4;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    let startPage = Math.max(this.currentPage - halfPagesToShow, 1);
    let endPage = Math.min(startPage + pagesToShow - 1, this.pages.length);
    if (endPage - startPage < pagesToShow - 1) {
      startPage = Math.max(endPage - pagesToShow + 1, 1);
    }
    this.visiblePages = Array(endPage - startPage + 1).fill(0).map((_, i) => startPage + i);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateDisplayedOffers();
    }
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
      this.updateDisplayedOffers();
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.updateDisplayedOffers();
  }

  getOffersByStatus(status: string) {
    this.currentPage = 1;
    let selected = this.offerStatus.find((stat) => stat.title === status);
    this.offerStatus.forEach(stat => {
      if (stat.title === status) {
        stat.selected = true;
      } else {
        stat.selected = false;
      }
    }
    );

    this.selectedStatus = status;
    this.updateDisplayedOffers();
  }

  public saveOffer(offer: Offer): void {
    this.partnerOfferService.updatePartnerOffer(offer).subscribe(
      (response) => {
        // console.log("Offer updated");
      },
      (error) => {
        console.log("error updatePartnerOffer", error);
      }

    )
  }

  isOlderThanOneMonth(date: string): boolean {
    const [day, month, year] = date.split('/');
    const formattedDate = new Date(`${month}/${day}/${year}`);
    
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    return formattedDate < oneMonthAgo;
  }

  setMatchmakingTrue( offer : Offer ): void{
    offer.matchmaking = true;
    this.saveOffer(offer);
  }

  setMatchmakingFalse( offer : Offer ): void{
    offer.matchmaking = false;
    this.saveOffer(offer);
  }
  
  startEditing(offer: any): void {
    offer.isEditing = true;
  }

  saveChanges(offer: any): void {
    offer.isEditing = false;
    this.saveOffer(offer);
  }

}