<a [routerLink]="replaceUrl()">
    <div class="actuality_container">
        <div class="actuality_image">
            <img src="{{actuality.imageUrl}}" alt="actuality image" class="image customised" />
        </div>
        <div class="actuality_content">
            <div class="tags_wrapper">
                <div class="tag" *ngFor="let tag of actuality.tags"><span>{{tag.name}}</span></div>
            </div>
            <p class="date">Publié le {{actuality.publishDate | date :'d MMMM YYYY' | titlecase}}</p>
            <p class="title" >{{actuality.title}}</p>
            <p class="description" [innerHTML]="actuality.description | slice:0:300 "></p>
            <div class="cta">
                <a href="" class="cta-link">Lire l'article
                    <img src="/assets/images/icones/arrow_forward.svg" alt="icon arrow" class="customised" />
                </a>
            </div>
        </div>
    </div>
</a>