<div class="briefs-carousel">
    <div class="briefs-carousel__carousel">
        <dep-carousel *ngIf="briefs" [ariaLabel]="'Dernières brèves'">
            <ng-container *ngFor="let brief of briefs; let i = index">
                <dep-briefs-thumbnail [brief]="brief" *appCarouselSlide>
                </dep-briefs-thumbnail>
            </ng-container>
        </dep-carousel>
    </div>
</div>
