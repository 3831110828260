<div
  class="steps-columns"
  [ngClass]="{
    'steps-columns_cols_2': stepsColumns == 2,
    'steps-columns_cols_3': stepsColumns == 3,
    'steps-columns_cols_4': stepsColumns == 4
  }"
>
  <ng-content></ng-content>
</div>
