import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-protect-invention',
  templateUrl: './protect-invention.component.html',
  styleUrls: ['./protect-invention.component.scss'],
})
export class ProtectInventionComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Envie d\'entreprendre - stratégie de propriété intellectuelle'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'Comprendre la propriété intellectuelle pour protéger les inventions au cœur de votre projet de création d\'entreprise.',
    });
  }
}
