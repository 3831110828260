import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'dep-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input('pageTitle') title: string;
  @Input() longTitle: boolean = false;
  @Input() inscriptionTitle: boolean = false;
  @Input() isPartnerSpace :boolean = false;
  @Input() goBack : boolean = false;

  constructor(private location: Location) { }

  ngOnInit(): void {
  }
  back(): void {
    this.location.back();
  }

}
