import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-encart',
  templateUrl: './encart.component.html',
  styleUrls: ['./encart.component.scss']
})
export class EncartComponent implements OnInit {

  @Input() title:string;
  @Input() description:string;
  @Input() externalLink:string;
  @Input() linkLabel:string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
