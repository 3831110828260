import { MembershipStatus } from './../../models/memberships/membership-status.model';
import { Membership } from './../../models/memberships/membership.model';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/models/memberships/notification.model';
import { environment } from '../../../environments/environment';
import { GlobalURLs } from '../../GlobalURLs';
import { ResponseNotification } from './../../models/memberships/response-notification.model';


@Injectable({
  providedIn: "root",
})
export class MembershipService {
  private REST_API_SERVER = "";
  private MEMBERSHIP_RESSOURCE_PATH = "";
  membership: Membership;

  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs
  ) {
    this.REST_API_SERVER = environment.apiUrl;
    this.MEMBERSHIP_RESSOURCE_PATH = this.ressourcePath.submitMembership();
  }

  public createMembership(membership: Membership): Observable<MembershipStatus> {
    this.membership = membership;

    return this.http.post<MembershipStatus>
      (this.REST_API_SERVER + this.MEMBERSHIP_RESSOURCE_PATH, this.membership);
  }

  public contactNewStartup(notification: Notification): Observable<ResponseNotification> {

    return this.http.post<ResponseNotification>
      (environment.apiUrlAws + this.ressourcePath.sendNotificationNew(), notification);
  }

  public contactExistingStartup(notification: Notification): Observable<ResponseNotification> {

    return this.http.post<ResponseNotification>
      (environment.apiUrlAws + this.ressourcePath.sendNotificationExist(), notification);
  }
}
