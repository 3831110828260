import { Component, OnInit, Input } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-actuality-thumbnail-home',
  templateUrl: './actuality-thumbnail-home.component.html',
  styleUrls: ['./actuality-thumbnail-home.component.scss']
})
export class ActualityThumbnailHomeComponent implements OnInit {
  @Input() actualityIndex: number;
  @Input() actuality: Actuality;
  public url: string;

  constructor(private urlFormatter: FormatUrlPipe) { }


  ngOnInit(): void {
  }

  replaceUrl(): string {
    this.actuality.tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        this.url = "/actualites/Autres/" + this.actuality.id + '-' + this.urlFormatter.transform(this.actuality.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1)
      } else {
        this.url = "/actualites/" + this.urlFormatter.transform(array[0].name) + "/" + this.actuality.id + '-' + this.urlFormatter.transform(this.actuality.url) + '.html'
      }

    });

    return this.url;
  }

}
