import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const FORMAT_DD_MMM_YYYY = 'd MMM y';

@Pipe({ name: 'nbDaysElapsed' })
export class NbDaysElapsedPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: string|Date): string | null {
    if (!value) {
      return '';
    }
    const todayDate = new Date();
    const startTodayDate = todayDate.setHours(0, 0, 0);
    const valueDateTime = new Date(value).getTime();
    const diffDays = Math.ceil(
      (todayDate.getTime() - valueDateTime) / (1000 * 60 * 60 * 24)
    );

    if (valueDateTime > startTodayDate) {
      return 'Aujourd\'hui';
    }
    if (diffDays >= 1 && diffDays < 2) {
      return 'Hier';
    }
    if (diffDays >= 2 && diffDays <= 5) {
      return 'Il y a ' + diffDays + ' jours';
    }
    if (diffDays >= 5) {
      return this.datePipe.transform(value, FORMAT_DD_MMM_YYYY).replace('.', '');
    }
  }
}