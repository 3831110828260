import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dep-article-header',
  templateUrl: './article-header.component.html',
  styleUrls: ['./article-header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.2s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ArticleHeaderComponent implements OnInit {
  @Input() articleTitle: string;
  @Input() articleSubtitle: string;
  @Input() articleDate: string;
  @Input() articleDateTime: string;
  @Input() articleReadingTime: string;
  @Input() buttonIcon: string;
  @Input() buttonText: string;
  @Input() buttonMessage: string;
  @Input() showButtonMessage: boolean;
  @Input() showReturnButton: boolean;
  @Input() isPopover: boolean;
  public showPopup: boolean=false;
  public urlPage : string;
  public clipboardCopySuccess = false;
  private clipboardmessageDuration = 4000;
  @Output("buttonAction") buttonAction: EventEmitter<any> = new EventEmitter();
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('popover') popover: ElementRef;

  public twitterUrl: string = "http://twitter.com/intent/tweet?text="+encodeURIComponent(window.location.href);
  public linkedinUrl: string = "https://www.linkedin.com/feed/?shareActive&mini=true&text="+encodeURIComponent(window.location.href);
  
  constructor(private location: Location,private renderer: Renderer2 , private router: Router) {
    this.renderer.listen('window', 'click',(e:Event)=>{
     if(e.target !== this.toggleButton.nativeElement && e.target!==this.popover.nativeElement){
         this.showPopup=false;
      }
    });
  }

  ngOnInit(): void {
    this.urlPage = window.location.href;
  }

  back(): void {
    this.location.back()
  }

  togglePopover():void{
   this.showPopup= !this.showPopup;
  }
  
  copyUrl():void{
    if(this.urlPage){
    navigator.clipboard.writeText(window.location.href)
    .then(() => {
      this.clipboardCopySuccess = true;
      this.showButtonMessage=this.clipboardCopySuccess;
      this.buttonMessage="Lien copié !";
      setTimeout(() => {
        this.clipboardCopySuccess = false;
      }, this.clipboardmessageDuration);
    })
    .catch(() => this.clipboardCopySuccess = false);
    }
  }
}
