import { Component, Input, OnInit } from '@angular/core';
import { Favorite } from 'src/app/models/favorites/favorite.model';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';

@Component({
  selector: 'dep-partner-thumbnail',
  templateUrl: './partner-thumbnail.component.html',
  styleUrls: ['./partner-thumbnail.component.scss']
})
export class PartnerThumbnailComponent implements OnInit {
  
  @Input() partner : any ;

  public isChecked = false;

  constructor(
    private favoriteService: FavoriteService
  ) { }

  public description: string = "";
  private limit = 50;

  ngOnInit(): void {
    
    if(this.partner.description.length>this.limit){
      this.description = this.partner.description.slice(0, this.limit) + '...';
    }
    else{
      this.description = this.partner.description
    }

    this.favoriteService.favorites$.subscribe(
      _ => {        
        let favorite = new Favorite();
        favorite.referenceId = this.partner.id.toString();
        favorite.referenceType = ReferenceType.PARTNER;
        favorite.content = this.partner
        this.isChecked = this.favoriteService.isFavorite(favorite)!=null;
      }
    )
  }

  toggle(){
    
    let fav = new Favorite();
    fav.content = this.partner;
    fav.referenceId = this.partner.id.toString();
    fav.referenceType = this.partner.type;
    
    this.favoriteService.toggle(this.partner);
  }

 
}
