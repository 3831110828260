<div [ngClass]="{'page-section_partner-space': isPartnerSpace , 'page-section': !isPartnerSpace }"
  class="page-header page-section--dark">
  <div class="page-section__content">
    <!-- Fil d'ariane -->
    <ng-content></ng-content>
    <button *ngIf="goBack" type="button" class="page-header__btn" area-label="back button" (click)="back()">
      <img src="/assets/images/espace-partenaire/arrow-back.png" alt="icon arrow-back" />
    </button>
    <!-- Si ce n'est pas un titre long -->
    <h1 *ngIf="!longTitle && !inscriptionTitle"
      [ngClass]="{'page-header__title-s': isPartnerSpace , 'page-header__title': !isPartnerSpace }">{{title}}
    </h1>

    <!-- Si c'est un titre long -->
    <h2 *ngIf="longTitle && !inscriptionTitle " class="page-header__title">{{title}}</h2>

    <!-- Pour un titre de 38px ( afin de respecter la maquette de la page inscription) -->
    <h2 *ngIf="inscriptionTitle" class="page-header__title-size">{{title}}</h2>

    <div class="page-header__text">
      <ng-content select="[slot='text']"></ng-content>
    </div>
  </div>
</div>