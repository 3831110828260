import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Popup } from 'src/app/models/popup.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs,
  ) { }


  public getAllpopups(): Observable<Popup[]>{
    return this.http.get<Popup[]>(environment.apiUrl + this.ressourcePath.apiPopup);
  } 
}
