import { animate, style, transition, trigger } from '@angular/animations';
import { Input, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'dep-offers-list-header',
  templateUrl: './offers-list-header.component.html',
  styleUrls: ['./offers-list-header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.2s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class OffersListHeaderComponent implements OnInit {
  @Input() offersTitle: string;
  @Input() offersUpdate: string;
  @Input() offersTotal: number;
  @Input() isAdvisor : boolean =false;

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  back(): void {
    this.location.back()
  }

}
