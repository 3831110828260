<article class="podcast-list-item">
  <div class="podcast-list-item__image">
    <div class="podcast-list-item__image-wrapper">
      <img src="{{podcast.imageUrl}}" alt="{{podcast.altImage}}" loading="lazy">
    </div>
  </div>
  <p class="podcast-list-item__categories">
    <span class="podcast-list-item__category" *ngFor="let tag of podcast.tags">
    <ng-container *ngIf="tag?.name !== 'France' && tag?.name !== 'Europe'" >{{tag.name}}</ng-container>
    </span>
  </p>
  <h3 class="podcast-list-item__title"><a [routerLink]="replaceUrl()">{{podcast.title}}</a></h3>
  <p class="podcast-list-item__author">{{podcast.authorNameAndRole}}</p>
  <p class="podcast-list-item__duration">
    <dep-icon class="icon" [icon]="'icon-timer'"></dep-icon>
    {{podcast.duration}}
  </p>
  <p class="podcast-list-item__intro">{{podcast.description}}</p>
  <a [routerLink]="replaceUrl()" class="podcast-list-item__button">
    <span>Écouter</span>
    <!-- hidden text to complete the link label (for screen readers and bots) -->
    <span class="sr-only">le podcast : {{podcast.title}}</span>
  </a>
</article>
