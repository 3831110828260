<section class="page-header">
  <div class="page-header__container">
    <div class="page-header__content">
      <button type="button" class="page-header__back" (click)="back()">
        <dep-icon class="icon" [icon]="'icon-arrow_back_ios'"></dep-icon>
        Retour
      </button>

      <div class="page-header__breadcrumb">
        <ng-content select="[breadcrumb]"></ng-content>
      </div>

      <h1 class="page-header__title"><ng-content select="[pageTitle]"></ng-content></h1>

      <!-- Timeline -->
      <div class="page-header__timeline" aria-label="Filtrer les évènements par mois" *ngIf="displayTimeline">
        <!-- Go to previous months -->
        <button *ngIf="isShowPrevious"
          class="timeline-button timeline-button_previous"
          (click)="getPreviousMonths()"
          #buttonPrevious
        >
          <dep-icon [icon]="'icon-arrow_back_ios'"></dep-icon>
          <span class="sr-only">Précédent</span>
        </button>

        <!-- Timeline carousel -->
        <div
          class="timeline"
          aria-live="polite"
          #timeline
          (swiperight)="swipeRight()"
          (swipeleft)="swipeLeft()"
        >
        <ng-container *ngIf="eventsCalendar.length > 0">
          <button
            type="button"
            class="timeline__month "
            [ngClass]="{'is-active' : i === selectedIndexMonth}"
            (click)="selectEventMonth(eventCalendar, i)"
            *ngFor="let eventCalendar of eventsCalendar; let i = index"
          >
            <span class="timeline__month-year">{{eventCalendar.year}}</span>
            {{eventCalendar.month | date:'MMMM'}}<br />
            <span class="timeline__month-events">{{eventCalendar.count}}</span>
          </button>
        </ng-container>
        </div>

        <!-- Go to next months -->
        <button *ngIf="isShowNext"
          class="timeline-button timeline-button_next"
          (click)="getNextMonths()"
          #buttonNext
        >
          <dep-icon [icon]="'icon-arrow_forward_ios'"></dep-icon>
          <span class="sr-only">Suivant</span>
        </button>
      </div>
    </div>
    
  </div>
</section>
