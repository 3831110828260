import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { SearchService } from "src/app/shared/services/search/search.service";
import { Router } from "@angular/router";
import { remark } from "remark";
import strip from "strip-markdown";

@Component({
  selector: "dep-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit,OnChanges {
  @Input() searchBar: boolean;
  public filledInput: boolean = false;
  searchSubject$: Subject<string> = new Subject<string>();
  search$: Observable<string>;
  searchValue: string = "";
  suggestions?: any[] = null;
  numberOfSuggestions: number;

  constructor(private searchService: SearchService, private router: Router) {
    this.searchService.suggestionsSubject$.subscribe(value => {
      if (value) {
        this.suggestions = this.searchService.getSuggestions();

        if (this.suggestions) {
          this.suggestions.forEach(suggestion => {
            suggestion._source.description = remark()
              .use(strip)
              .processSync(suggestion._source.description)
              .toString();
            suggestion._source.title = remark()
              .use(strip)
              .processSync(suggestion._source.title)
              .toString();
          });
        }

        this.numberOfSuggestions = this.searchService.getNumberOfSuggestions();
      }
    });
  }

  ngOnInit(): void {
    this.searchBar = false;
    this.search$ = this.searchSubject$.pipe(debounceTime(500));

    this.search$.subscribe(text => {
      this.searchService.updateSuggestions(text);
    });
  }


  ngOnChanges(changes: SimpleChanges): void  {
    if (!this.searchBar) {
      this.filledInput = false;
    }
  }

  ngDestroy() {
    this.searchService.suggestionsSubject$.unsubscribe();
  }

  change(e): void {
    const text: string = e.target.value.toString();
    if (text.length >= 4) {
      this.filledInput = true;
      this.searchValue = text;
      this.searchService.cleanSuggestions();
      this.searchSubject$.next(text);
    } else {
      this.filledInput = false;
    }
  }

  navigateToSearchPage() {
    this.filledInput = false;
    this.router.navigate(["/search-results.html/"], {
      queryParams: { searchValue: this.searchService.getSearchValue() }
    });
  }

  cancelSuggestions(e) {
    var container = document.getElementById('suggestion_container');
    if (container && !container.contains(e.target)) {
      container.style.display = 'none';
    }
  }

}
