import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-build-team',
  templateUrl: './build-team.component.html',
  styleUrls: ['./build-team.component.scss'],
})
export class BuildTeamComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Envie d\'entreprendre - s\'associer pour créer sa startup'
    );
    this.meta.addTag({
      name: 'description',
      content:
        'La startup est une aventure humaine avant tout, trouver les bons associés est une étape clé de la création de votre entreprise innovante.',
    });
  }
}
