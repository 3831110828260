import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { Service } from 'src/app/models/services/service.model';
import { ServiceRequest } from 'src/app/models/services/serviceRequest.model';
import { HabilitationName } from 'src/app/models/user/habilitation.enum';
import { User } from 'src/app/models/user/user';
import { AuthService } from 'src/app/shared/services/authentification/auth.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  private REST_API_SERVER = '';
  private SERVICES = '';
  private SERVICE = '';
  private SERVICES_COUNT = '';

  private unauthorizedList: string[] = [];
  
  
  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs,
   private sharedService:SharedService
  ) {
    this.REST_API_SERVER = environment.apiUrl;
    if (sessionStorage.getItem('unauthorized') == null) {
      sessionStorage.setItem('unauthorized', JSON.stringify(this.unauthorizedList));
    }
  }
  
  public getServices(user: User, serviceRequest?: ServiceRequest): Observable<Service[]> {
    const httpOptions = this.sharedService.getSecureHeaders();
    this.SERVICES = this.ressourcePath.getServices();
    return this.http.post<Service[]>(this.REST_API_SERVER + this.SERVICES, serviceRequest, httpOptions)
  }

  public getServicesCount(): Observable<number> {
    const httpOptions = this.sharedService.getSecureHeaders();
    this.SERVICES_COUNT = this.ressourcePath.getServicesCount();
    return this.http.get<number>(this.REST_API_SERVER + this.SERVICES_COUNT, httpOptions);
  }

  public getServiceById(user: User, id: number): Observable<Service> {
    const httpOptions = this.sharedService.getSecureHeaders();
    this.SERVICE = this.ressourcePath.getServiceById(id);

    return this.http.get<Service>(this.REST_API_SERVER + this.SERVICE, httpOptions)
  }


  public checkAuthorized(name: string): boolean {
    return !sessionStorage.getItem('unauthorized').includes(name);
  }

  public addToUnauthorized(name: string): void {
    this.unauthorizedList = JSON.parse(sessionStorage.getItem('unauthorized'));
    if (!sessionStorage.getItem('unauthorized').includes(name)) {
      this.unauthorizedList.push(name);
    }
    sessionStorage.setItem('unauthorized', JSON.stringify(this.unauthorizedList));
  }

  public logout(): void {
    this.unauthorizedList = [];
    sessionStorage.setItem('unauthorized', JSON.stringify(this.unauthorizedList));
  }

}
