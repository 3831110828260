<div
  class="formations-list"
  [class.formations-list_large-thumbs]="trainingType === 'OTT'"
  aria-live="polite"
  *ngIf="trainings.length > 0"
>
    <ng-container *ngFor="let training of trainings; let i = index">
      <dep-training-thumbnail
        [formationType]="trainingType"
        [formationTag]="training.tags[0]"
        [formationDuration]="training.duration"
        [formationLogo]="training.logoUrl"
        [formationLogoAlt]=""
        [formationTitle]="training.title"
        [formationIntro]="training.description"
        [formationExpert]="training.intervener"
        [formationLevel]="training.level"
        [formationIndex]="i"
        [formationExternalLink]="training.externalLink"
      >
      </dep-training-thumbnail>
    </ng-container>
</div>
