<div
  class="numbers-columns"
  [ngClass]="{
    'numbers-columns_cols_2': numbersColumns == 2,
    'numbers-columns_cols_3': numbersColumns == 3,
    'numbers-columns_cols_4': numbersColumns == 4
  }"
>
  <ng-content></ng-content>
</div>
