import { Component, Input, OnInit } from '@angular/core';
import { Favorite } from 'src/app/models/favorites/favorite.model';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
import { ServicesService } from 'src/app/services/services/services.service';

@Component({
  selector: 'dep-service-thumbnail-detail',
  templateUrl: './service-thumbnail-detail.component.html',
  styleUrls: ['./service-thumbnail-detail.component.scss']
})
export class ServiceThumbnailDetailComponent implements OnInit {

  @Input() service: any;
  @Input() partners: any[];

  public isChecked = false;
  public isAuthorized = true;

  constructor(
    private favoriteService: FavoriteService,
    private serviceService: ServicesService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.service){
      this.isChecked = this.favoriteService.isFavorite(this.service)!=null;
    }
    if(this.service.name === 'Tango'||this.service.name === 'Tandem'){
      this.isAuthorized = this.serviceService.checkAuthorized('taleez');
    }
  }

  toggle(){
    
    let fav = new Favorite();
    fav.content = this.service;
    fav.referenceId = this.service.id.toString();
    fav.referenceType = this.service.type;
    
    this.favoriteService.toggle(this.service);
  }

}
