import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-article-thumbnail',
  templateUrl: './article-thumbnail.component.html',
  styleUrls: ['./article-thumbnail.component.scss']
})
export class ArticleThumbnailComponent implements OnInit {
  @Input('articleImage') image: string;
  @Input('articleType') type: string;
  @Input('articleTitle') title: string;
  @Input('articleDate') date: string;
  @Input('articleTag') tag: string;
  @Input('articleCategory') category: string;
  @Input('articleLink') link: string;
  @Input('articleInternalLink') internalLink: string;
  @Input('linkEnSavoirPlus') linkEnSavoirPlus: string;
  @Input('titleEnSavoirPlus') titleEnSavoirPlus: string;
  @Input('showTag') showTag: boolean = true;
  @Input('showCategory') showCategory: boolean = true;
  @Input('articleExtract') extract: string;

  constructor() { }

  ngOnInit(): void {
  }

}
