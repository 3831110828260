import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Faq } from 'src/app/models/faqs/faq.model';
import { createHttpParams } from 'src/app/shared/utils/http-params';
import { environment } from 'src/environments/environment';
import { FaqDetail } from '../../models/faqs/faq-detail.model';

@Injectable({
  providedIn: 'root',
})
export class FaqsService {
  private REST_API_SERVER = '';
  private FAQ_DETAIL = '';
  private faqUrn = '';
 

  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getFaqDetailById(id: number): Observable<FaqDetail> {
    this.FAQ_DETAIL = this.ressourcePath.getFaqDetailById(id);
    return this.http.get<FaqDetail>(this.REST_API_SERVER + this.FAQ_DETAIL);
  }
  public getFaqDetails(categoryId: number, index?: number, limit?: number): Observable<FaqDetail[]> {
    const params: HttpParams = createHttpParams({
      index: index?.toString(),
      limit: limit?.toString(),
      categoryId: categoryId?.toString(),
   });
    this.FAQ_DETAIL = this.ressourcePath.getFaqDetails();
    return this.http.get<FaqDetail[]>(this.REST_API_SERVER + this.FAQ_DETAIL, {params});
  }
  public getFaqs(): Observable<Faq[]>{
    this.faqUrn = this.ressourcePath.getFaqs();
    return this.http.get<Faq[]>(this.REST_API_SERVER + this.faqUrn);
  }
}
