import { Component, OnInit, AfterViewInit, OnDestroy, Input, ViewChild, ElementRef, ChangeDetectionStrategy, Renderer2, ContentChildren, QueryList } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, switchMapTo, tap, finalize } from 'rxjs/operators';
import { CarouselSlideDirective } from '../../directives/carousel-slide.directive';

@Component({
  selector: 'dep-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() ariaLabel: string;
  @ViewChild("carousel") carouselView: ElementRef;
  @ContentChildren(CarouselSlideDirective) carouselSlides: QueryList<CarouselSlideDirective>;
  private destroy$ = new Subject<void>();

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const carousel = this.carouselView.nativeElement;
    const mousedown$ = fromEvent(carousel, 'mousedown');
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(carousel, 'mouseup');

    let startX: number = 0;
    let scrollLeft: number = 0;

    mousedown$.pipe(
      tap((event: any) => {
        startX = event.pageX - carousel.offsetLeft;
        scrollLeft = carousel.scrollLeft;
        this.renderer.addClass(this.carouselView.nativeElement, 'active');
      }),
      switchMapTo(mousemove$.pipe(
        tap((event: any) => {
          event.preventDefault();
          const x = event.pageX - carousel.offsetLeft;
          const SCROLL_SPEED = 3;
          const walk = (x - startX) * SCROLL_SPEED;
          carousel.scrollLeft = scrollLeft - walk;
        }),
        takeUntil(mouseup$),
        finalize(() => this.renderer.removeClass(this.carouselView.nativeElement, 'active'))
      )),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
