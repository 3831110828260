<dep-navbar-public-space></dep-navbar-public-space>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Envie d'entreprendre">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Envie d'entreprendre</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- La technologie au service du marché pour changer le monde -->
  <dep-page-section sectionTitle="La technologie au service du marché pour changer le monde" sectionTheme="medium">
    <dep-text>
      <p>
        Une startup Deeptech nait de la rencontre d’un besoin marché et d’une technologie de rupture pour apporter à ses
        clients un service ou un produit nouveau à forte valeur ajoutée.
      </p>
      <p>
        Sa création est le résultat d’un travail d’équipe entre chercheurs et entrepreneurs qui mettront à profit leurs
        compétences respectives pour résoudre les 9 composantes présentées ci-dessous.
      </p>
      <p>
        Dans cette quête, chercheurs et entrepreneurs sont entourés par tout un écosystème d’accompagnement, de
        financement, de formation qui pourra guider et accélérer l’émergence, la création et la croissance du projet!
      </p>
    </dep-text>
  </dep-page-section>
  <!-- FIN de La technologie au service du marché pour changer le monde -->

  <!-- Timeline -->
  <dep-page-section>
    <dep-timeline>

      <!-- De l’émergence d’un projet… -->
      <dep-timeline-section sectionTitle="De l’émergence d’un projet…">

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/resultat-de-recherche.html']">Exploiter un résultat de recherche</a></dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/declarer-invention.html']">Déclarer une invention</a></dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/protection-propriete-intellectuelle.html']">Protéger une invention</a></dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/entreprendre-en-equipe.html']">Monter une équipe fondatrice</a></dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/plan-de-financement.html']">Concevoir son
            business plan</a></dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/acces-au-marche.html']">Dérisquage
            technico-économique du projet</a></dep-step>

      </dep-timeline-section>

      <!-- À la création d’une startup Deeptech -->
      <dep-timeline-section sectionTitle="À la création d’une startup Deeptech">
        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/transfert-de-technologies.html']">Créer la startup et intégrer la PI</a></dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/financer-son-projet.html']">Financer son projet</a></dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/accompagnement-startup.html']">Se faire accompagner</a></dep-step>

      </dep-timeline-section>

      <!-- Et après ? -->
      <dep-timeline-section sectionTitle="Et après ?">

      </dep-timeline-section>
    </dep-timeline>
    <article class="article">
    <p>
      Ca y est votre entreprise est créée, vous avez constitué une équipe de choc et vous vous sentez prêt à changer le
      monde. L’aventure ne fait que commencer et nous vous proposons de la vivre avec d’autres !
    </p>
    <p>
      Rejoindre la communauté Les Deeptech c’est :
    </p>
    <ul class="content-list">
      <li>Partager ses joies et ses peines avec d’autres entrepreneurs qui comprennent ce que vous vivez</li>
      <li>Accéder à du contenu de qualité pour continuer à apprendre d’entrepreneurs plus expérimentés</li>
      <li>Bénéficier de temps d’accompagnement organisés pour répondre à vos besoins.</li>
    </ul>

    <!-- Bouton rejoindre la communauté  -->
    <ng-container cta>
      <a [routerLink]="['/communaute-startups-deeptech-france/inscription.html']" class=" service__cta ds-btn ds-btn--primary">
        Rejoindre la communauté
      </a>
      <a [routerLink]="['/communaute-startups-deeptech-france/.']" class=" service__cta ds-btn ds-btn--secondary">
        En savoir plus
      </a>
    </ng-container>
    <!--  -->
  <!-- FIN de la timeline -->

  <!-- Un exemple - le système D -->
  <h2 class="page-section__title"> Un exemple - le système D</h2>
    <p>
      Une douzaine d’entrepreneurs de toute la France se réunissent digitalement pour partager leurs expériences sur un
      sujet précis. Chaque échange étant formalisé dans une fiche outil à disposition du reste de la communauté
    </p>
    <br />
    <div class="resources grid-container">
      <dep-resource class="lg-grid-col-4 md-grid-col-4" resourceTitle="Fiche 1 - Préparer sa levée de fond"
        resource="/assets/docs/se-lancer/systeme-d-n1-lever-des-fonds.pdf" [noDecoration]="true">
        10 entrepreneurs partagent leurs expériences pour préparer une levée de fonds.​ <br/>Session animée par
        <b>Jack Habra, CEO de Reminiz</b>
      </dep-resource>

      <dep-resource class="lg-grid-col-4 md-grid-col-4" resourceTitle="Fiche 2 - Optimiser son temps d'entrepreneur deeptech​"
        resource="/assets/docs/se-lancer/systeme-d-n2-optimiser-son-quotidien.pdf" [noDecoration]="true">
        Il est fondamental d’optimiser son quotidien d'entrepreneur, et d'autant plus quand la startup croit rapidement. La loi de Pareto le confirme, 20% du travail réalisé représente 80% des rendus finaux. <br/>Session animée par <b>Julien Autebert, CEO d'Inorevia.</b>
      </dep-resource>

      <!-- <dep-redirection class="lg-grid-col-4 md-grid-col-4" redirectionTitle="Communauté DPT​" titleLink="Rejoindre"
      [redirection]="['/inscription-tribu']" [noDecoration]="true">
        Venez partager vos réussites, échecs, conseils, bonnes pratiques et surtout de bons moments avec d'autres
        entrepreneurs Deeptech de toute la France​.
      </dep-redirection> -->
    </div>
    </article>
  </dep-page-section>
  <!-- FIN de Un exemple - le système D -->

  <!-- Les auteurs -->
  <dep-page-section sectionTitle="Les auteurs" sectionTheme="medium">
    <dep-key-contributors>
      <dep-contributor class="lg-grid-col-2" name="CNRS Innovation"
        logo="/assets/images/les-auteurs/logo-bpifrance.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2 dep-launch__logo" name="CEA" logo="/assets/images/les-auteurs/logo_reseaucurie_rvb.png">
      </dep-contributor>
    </dep-key-contributors>
  </dep-page-section>
  <!-- FIN de Les auteurs -->

  <!-- Les contributeurs -->
  <dep-page-section sectionTitle="Les contributeurs">
    <dep-key-contributors>
      <dep-contributor class="lg-grid-col-2" name="CNRS Innovation"
        logo="/assets/images/contributeurs/cnrs-innovation@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="CEA" logo="/assets/images/contributeurs/cea@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Grenoble INP" logo="/assets/images/contributeurs/grenoble-inp@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Clermont Auvergne Innovation"
        logo="/assets/images/contributeurs/clermont-auvergne-innovation@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Toulouse Tech Transfer"
        logo="/assets/images/contributeurs/toulouse-tech-transfer@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Aix Marseille Université"
        logo="/assets/images/contributeurs/aix-marseille-universite@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Ifremer" logo="/assets/images/contributeurs/ifremer@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Normandie Université"
        logo="/assets/images/contributeurs/normandie-universite@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Linksium" logo="/assets/images/contributeurs/linksium@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Ouest Valorisation"
        logo="/assets/images/contributeurs/ouest-valorisation@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Inserm transfert"
        logo="/assets/images/contributeurs/inserm-transfert@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Aquitaine Science Transfert"
        logo="/assets/images/contributeurs/aquitaine-science-transfert@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Cergy Paris Université"
        logo="/assets/images/contributeurs/cergy-paris-universite@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Conectus Alsace"
        logo="/assets/images/contributeurs/conectus-alsace@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Assistance Publique Hôpitaux de Paris"
        logo="/assets/images/contributeurs/aphp@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Capagro" logo="/assets/images/contributeurs/capagro@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Aster" logo="/assets/images/contributeurs/aster@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Karista" logo="/assets/images/contributeurs/karista@3x.png">
      </dep-contributor>

      <dep-contributor class="lg-grid-col-2" name="Université Montpellier"
        logo="/assets/images/contributeurs/universite-montpellier@3x.png">
      </dep-contributor>
    </dep-key-contributors>
  </dep-page-section>
  <!-- FIN de Les contributeurs -->

  <!-- Pour aller plus loin -->
  <dep-page-section sectionTheme="medium">
    <dep-go-further titre='Pour aller plus loin'>
      <ng-container text>
        Les structures d'accompagnement de l'écosystème Deeptech français vous proposent un éventail de service
        pour accompagner le lancement et la croissance de vos startups (par exemple : trouver un associé scientifique ou
        business, être mis en relation avec des investisseurs, se former).
      </ng-container>
      <ng-container cta>
        <a [routerLink]="'/'" class="ds-btn ds-btn--primary">Découvrir les services</a>
      </ng-container>
    </dep-go-further>
  </dep-page-section>
  <!-- FIN de Pour aller plus loin -->
</main>

<dep-footer-public-space></dep-footer-public-space>