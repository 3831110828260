<div class="carousel-container">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <dep-image-card 
    *ngFor="let content of contents; let i = index" 
     [content]="content" 
     [currentIndex]="currentIndex"
     [isActive]="currentIndex === i"
     [index]="i" 
     [isDifferentTags]="true"
     ngxSlickItem >
    </dep-image-card>
    </ngx-slick-carousel>
</div>
