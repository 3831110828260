import { Component, Input, OnInit } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-actuality-thumbnail',
  templateUrl: './actuality-thumbnail.component.html',
  styleUrls: ['./actuality-thumbnail.component.scss'],
})
export class ActualityThumbnailComponent implements OnInit {
  @Input() actuality: Actuality;
  @Input() actualityIndex: number;
  @Input() isRegionTag : boolean =false;
  @Input() showDesc: boolean = true;
  public url: string;

  constructor(private urlFormatter: FormatUrlPipe) { }

  ngOnInit(): void {

  }

  replaceUrl(): string {
    this.actuality.tags.forEach((tag,index,array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length==1) {
        this.url = "/actualites/Autres/" + this.actuality.id + '-' + this.urlFormatter.transform(this.actuality.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length >1){
        array.splice(index,1)
      } else{
        this.url = "/actualites/" + this.urlFormatter.transform(array[0].name) + "/" + this.actuality.id + '-' + this.urlFormatter.transform(this.actuality.url) + '.html'
      }
      
    });
    
    return this.url;
  }
}
