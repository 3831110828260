<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main">
  <!-- Sticky header -->
  <dep-article-sticky-header [articleTitle]="study?.title" [articleCategories]="study?.tags" [buttonIcon]="'icon-download'"
    [buttonText]="'Télécharger'" (buttonAction)="scroll()"></dep-article-sticky-header>

  <!-- Page header -->
  <dep-page-header-image class="page-header-wrapper"
    [headerImage]="study?.bannerImageUrl ? study?.bannerImageUrl : study?.imageUrl" [altImage]="study?.altImage">
    <!-- Article header content -->
    <dep-article-header [articleTitle]="study?.title"
      [articleDate]="study?.publishDate | date :'d MMMM yyy' | titlecase" [articleDateTime]="study?.publishDate"
      [articleReadingTime]="study?.readingTime" [buttonIcon]="'icon-download'" [buttonText]="documentExist ?'Télécharger':null"
      (buttonAction)="scroll()" content>
      <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
        <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
        <dep-breadcrumb-item [itemRouterLink]="'/actualites-ressources/.'" *appBreadcrumbItem>Actualités et
          ressources</dep-breadcrumb-item>
        <dep-breadcrumb-item *appBreadcrumbItem>Études</dep-breadcrumb-item>
      </dep-breadcrumb>
    </dep-article-header>

    <!-- Themes -->
    <dep-article-categories [articleCategories]="study?.tags" subheader></dep-article-categories>
  </dep-page-header-image>

  <!-- Article content -->
  <div class="article-wrapper">
    <ng-container *ngFor="let studyContent of study?.contents">

      <!-- Text -->
      <ng-container *ngIf="studyContent.textId">
        <p [innerHTML]="studyContent.paragraph | markdown"></p>
      </ng-container>

      <!-- Quote -->
      <ng-container *ngIf="studyContent.quoteId">
        <dep-article-quote [quote]="studyContent.quote" [quoteSource]="studyContent.authorName">
        </dep-article-quote>
      </ng-container>

      <!-- Related content -->
      <ng-container *ngIf="studyContent.linkId">
        <dep-article-related-content [relatedContent]="studyContent.linkTitle">
        </dep-article-related-content>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="studyContent.videoId && studyContent.videoUrl">
        <dep-article-video [videoUrl]="studyContent.videoUrl" [videoType]="'video/mp4'"
          [videoTitle]="studyContent.videoTitle" [videoSource]="studyContent.videoSource">
        </dep-article-video>
      </ng-container>

      <!-- YOUTUBE video -->
      <!-- TODO : Allow youtube urls in inputs -->
      <ng-container *ngIf="studyContent.videoId && studyContent.videoUrl == '' && studyContent.videoSource">
        <dep-article-youtube-video [videoUrl]="studyContent.videoSource" [videoTitle]="studyContent.videoTitle">
        </dep-article-youtube-video>
      </ng-container>

      <!-- Images carousel -->
      <ng-container *ngIf="studyContent.imagesId">
        <dep-article-image-carousel [carouselTitle]="studyContent.slideShowTitle"
          [carouselSource]="studyContent.imagesSource">
          <ng-container *ngFor="let image of studyContent.imagesUrls">
            <dep-article-image-carousel-item [imageSrc]="image"
              [imageAlt]="studyContent.altImages" *appCarouselSlide></dep-article-image-carousel-item>
          </ng-container>
        </dep-article-image-carousel>
      </ng-container>

      <!-- Image -->
      <ng-container *ngIf="studyContent.imageId">
        <dep-article-image-carousel [carouselTitle]="studyContent.imageTitle"
          [carouselSource]="studyContent.imageSource">
          <dep-article-image-carousel-item [imageSrc]="studyContent.imageUrl"
            [imageAlt]="studyContent.altImage" *appCarouselSlide></dep-article-image-carousel-item>
        </dep-article-image-carousel>
      </ng-container>

      <!-- Document -->
      <section>
        <ng-container *ngIf="studyContent.documentId">
          <dep-article-document [fileName]="studyContent.documentTitle"
            [fileButtonName]="studyContent.downloadButtonTitle"
            [fileURL]="studyContent.documentUrl" id="myElem"></dep-article-document>
        </ng-container>
      </section>

      <!-- Podcast -->
    <ng-container *ngIf="studyContent.podcastId">
      <dep-article-podcast [podcast]="studyContent.iframe"></dep-article-podcast>
    </ng-container>

    </ng-container>

  </div>
</main>

<!-- <aside class="suggestions">
  <dep-page-section-actualities [sectionTheme]="'light'" [sectionTitle]="'Suggestions'"
    [sectionRouterLink]="studiesTypes[1].route" [sectionLinkIcon]="studiesTypes[1].icon" *ngIf="studiesTypes[1]">
    <div class="suggestions__list">
      <ng-container *ngFor="let n of [].constructor(3); let i = index">
        <app-actuality-thumbnail-prototype [actualityCategories]="['Biotech', 'Agritech']"
          [actualityImage]="'/assets/images/actualites-ressources/EtudeBiotech.jpg'"
          [actualityTitle]="'Un bio-stimulant pour remplacer les produits chimiques'" [actualityDate]="'15 décembre'"
          [actualityDuration]="'8 minutes'"
          [actualityIntro]="'Obtenir un meilleur rendement et une meilleure qualité des plants sans produit chimique. C’est le défi relevé par MycoPhyto, une solution naturelle qui aide les plantes à se nourrir.'"
          [actualityIndex]="i" [actualityRouterLink]="'/actualite-v2021/.'">
        </app-actuality-thumbnail-prototype>
      </ng-container>
    </div>
  </dep-page-section-actualities>
</aside> -->

<dep-scroll-to-top></dep-scroll-to-top>
<div *ngIf="isOpen">
  <dep-newsletter [newsletter] = "newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-main-footer></dep-main-footer>
