<a class="card_container" href="{{content.directAccessLinks[0].linkUrl}}">
    <div class="card_wrapper">
        <div class="card_content">
            <p class="text">{{content.directAccessLinks[0].linkTitle}}</p>
            <div class="tag_container">
                <span class="tag">{{content.title}}</span>
            </div>
        </div>
    </div>
    <div class="icon_wrapper">
        <img src="/assets/images/icones/arrow.svg" alt="icon arrow" class="icon customised" />
    </div>
</a>