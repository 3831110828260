import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FeatureFlippingService } from "../services/feature-flippling/feature-flipping.service";

@Injectable()
export class UnderMaintenanceService  {
  constructor(private router: Router,private featureService:FeatureFlippingService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const env=this.featureService.getFeatureFlagsByEnv(environment.environmentName);
    if (env.inMaintenance) {
      
      this.router.navigate(["/under-maintenance/."]);
      return false;
    } else {
      return true;
    }
  }
}
