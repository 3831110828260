import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'dep-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfPath: string;
  @Input() mediumSize: boolean = false;
  @Input() largeSize: boolean = false;
  geniusPdfPath: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.geniusPdfPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfPath);
  }

}
