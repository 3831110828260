import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent implements OnInit {
  @Input() services: any[];
  @Input() isHomePage: boolean;
  @Input() responsiveDisplay: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
