import { Component, Input, OnInit } from '@angular/core';
import { Brief } from 'src/app/models/briefs/brief.model';

@Component({
  selector: 'dep-briefs-list',
  templateUrl: './briefs-list.component.html',
  styleUrls: ['./briefs-list.component.scss'],
})
export class BriefsListComponent implements OnInit {
  @Input() briefs: Brief[];

  constructor() {}

  ngOnInit() {}
}
