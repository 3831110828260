import { Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'dep-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.2s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ScrollToTopComponent implements OnInit, OnDestroy {
  private eventOptions: boolean | { capture?: boolean, passive?: boolean };
  windowScrollTop: number;
  showButtonTrigger: number = 400;
  showButton: boolean = false;

  constructor(private ngZone: NgZone, private ref: ChangeDetectorRef, private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.initiateScrollListener();
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, <any>this.eventOptions);
  }

  initiateScrollListener(): void {
    let passiveSupported = false;
    try {
      const options = {
        get passive() { // This function will be called when the browser
          //   attempts to access the passive property.
          passiveSupported = true;
          return false;
        }
      };
    } catch (err) {
      passiveSupported = false;
    }
    if (passiveSupported) { //use the implementation on mozilla
      this.eventOptions = {
        capture: true,
        passive: true
      };
    } else {
      this.eventOptions = true;
    }
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scroll, <any>this.eventOptions);
    });
  }

  scroll = (): void => {
    this.windowScrollTop = window.document.documentElement.scrollTop;
    this.toggleButtonDisplay();
  }

  toggleButtonDisplay(): void {
    if (!this.showButton && (this.windowScrollTop >= this.showButtonTrigger)) {
      this.ngZone.run(() => {
        this.showButton = true;
        this.ref.detectChanges();
      });
    } else if (this.showButton && (this.windowScrollTop < this.showButtonTrigger)) {
      this.ngZone.run(() => {
        this.showButton = false;
        this.ref.detectChanges();
      });
    }
  }

  scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

}
