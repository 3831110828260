
export class Membership {
    /**
     * Miscellanious
     */
    confidentiality: boolean;
    recaptchaResponse:string;
    newsletter:boolean

    /**
     * subscriber
     */
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    job: string;

    /**
     * startup
     */
    startup: string;
    siren: string;
    website: string;
    yearOfCreation: number;
    postalCode: string;
    bpiClient: boolean;

    /**
     * domain
     */
    techField: string;
    activity: string;
    addedValue: string;
    researchLink: string;
    beneficiaryTTO: boolean;
    organization: string;
    technology: string;
    patent: boolean;
	patentOrg: string;
    observatory:boolean=false;
    communityKnowledge:string;

}



