<article class="service customised" *ngIf="service" [ngClass]="{ 'gradient-blue': index % 2 === 0, 'gradient-yellow': index % 2 !== 0,'resize-card':!isHomePage }">
  <div class="service__container customised">
    <div class="service__image customised">
          <a class="service__image-wrapper customised" [routerLink]="['/mon-espace-partenaire/services/',service.id+'-'+(service.name.toLowerCase() | formatUrl)+'.html']">
           <img src="{{service.imageUrl}}" alt="{{service.altImage}}" loading="lazy" class="transparent">
         </a>
    </div>
    <a [routerLink]="['/mon-espace-partenaire/services/',service.id+'-'+(service.name.toLowerCase() | formatUrl)+'.html']" class="service__link title__container customised">
    <p class="service__title customised">{{service.name}}</p>
    </a>
    <div class="service__save-btn customised">
    <input type="checkbox" class="save-btn transparent" [(ngModel)]="isChecked" (click)="toggle()">
    </div>
    <a [routerLink]="['/mon-espace-partenaire/services/',service.id+'-'+(service.name.toLowerCase() | formatUrl)+'.html']" class="service__content service__link customised">
    <p class="service__subtitle customised">{{subTitle}}</p>
    <p class="service__description transparent">{{description}}</p>
    </a>
  </div>
</article>