<section class="page-header">
  <div class="page-header__container">
    <div class="page-header__content">
      <button type="button" class="page-header__back" (click)="back()">
        <dep-icon class="icon" [icon]="'icon-arrow_back_ios'"></dep-icon>
        Retour
      </button>

      <div class="page-header__breadcrumb">
        <ng-content select="[breadcrumb]"></ng-content>
      </div>

      <h1 class="page-header__title" [ngClass]="{'title-small': titleSize =='small'}"><ng-content select="[pageTitle]"></ng-content></h1>
      <p class="page-header__text"><ng-content select="[pageText]"></ng-content></p>
    </div>
  </div>
</section>
