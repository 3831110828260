<div class="quote-block">
  <img src="/assets/images/redesign-v2021/quote-block-quote-yellow.svg" class="quote-block__decoration" width="53" height="36" alt="" loading="lazy">
  <p class="quote-block__quote" [ngClass]="{'quote-block__quote-largeSize':isEuroquity }">{{quoteText}}</p>
  <div class="quote-block__author">
    <div class="quote-block__photo" *ngIf="quotePhoto">
      <img src="{{quotePhoto}}" alt="" loading="lazy">
    </div>
    <p class="quote-block__author-name">{{quoteAuthorName}}</p>
    <p  class="quote-block__author-occupation">
      {{quoteAuthorJob}} <a href="{{quoteAuthorLink}}" aria-label="quote author link" target="_blank" rel="noopener noreferrer">{{quoteAuthorLinkLabel}}</a>
    </p>
  </div>
  <a class="link quote-block__link" [routerLink]="quoteRouterLink" *ngIf="quoteRouterLink">Lire cette tribune</a>
  <div class="quote-block__background" *ngIf="quoteBackground">
    <img src="/assets/images/actualites-ressources/EtudeAgri.jpg" alt="" loading="lazy" class="customised">
  </div>
</div>
