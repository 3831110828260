<div class="go-further">
  <h2 class="go-further__title">{{titre}}</h2>

  <p class="go-further__text">
    <ng-content select=[text]></ng-content>
  </p>

  <div class="go-further__cta">
    <ng-content select=[cta]></ng-content>
  </div>
</div>
