import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "underscoreRemover",
})
export class UnderscoreRemoverPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return "";
    }
    const modifierString = value.replace(/_/g, " ");
    return modifierString;
  }
}
