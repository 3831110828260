import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Region } from 'src/app/models/region/region.model';
import { SectorMenu } from 'src/app/models/sectors/sector-menu.model';
import { RegionService } from 'src/app/services/region/region.service';
import { SectorService } from 'src/app/services/sectors/sector.service';
import { AuthService } from '../../services/authentification/auth.service';
import { SearchService } from '../../services/search/search.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { User } from 'src/app/models/user/user';

@Component({
  selector: 'dep-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoaded: boolean = false;
  menuIsOpen: boolean = false;
  selectedItem: string = '';
  mainNav = null;
  private user: User;
  public sectorsMenus: SectorMenu[] = [];
  public regions: Region[] = [];
  public authentified: boolean;
  public searchBar: boolean = false;
  public isTooltip : boolean = false;

  constructor(
    router: Router,
    private authService: AuthService,
    private searchService : SearchService,
    private oidcSecurityService: OidcSecurityService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoaded = false;
      }
      if (event instanceof NavigationEnd) {
        setTimeout(() => this.isLoaded = true, 100);
      }
    });
  }


  ngOnInit(): void {
    // if (this.authService.isLoggedin()) {
    //   this.authentified = true;
    // } else {
    //   this.authentified = false;
    // }
    this.oidcSecurityService.isAuthenticated$.subscribe(
      response => this.authentified = response.isAuthenticated
    );

  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any): void {
    const searchButton = document.getElementById('searchButton');
    const searchIcon = document.getElementById('iconSearch');
    const searchBar = document.getElementById('search-input');
    if (targetElement !== searchButton && targetElement !== searchIcon && targetElement !== searchBar) {
      this.searchBar = false;
    } else {
      this.searchBar = true;
    }
  }

  ngAfterViewInit(): void {
    // Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
    // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
    // This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
    this.mainNav = document.querySelector('#main-nav');
  }

  ngOnDestroy(): void {
    clearAllBodyScrollLocks();
  }

  toggleSelectedMenuItem(item: any, parentItem: any = ''): void {
    if (this.selectedItem.startsWith(item)) {
      this.selectedItem = parentItem;
    } else {
      this.selectedItem = item;
    }
  }

  toggleMenu(): void {
    this.menuIsOpen = !this.menuIsOpen

    if (this.menuIsOpen) {
      // Disable body scroll
      disableBodyScroll(this.mainNav);
    } else {
      // Re-enable body scroll
      enableBodyScroll(this.mainNav);
    }
  }

  closeMenu(): void {
    this.menuIsOpen = false;

    // Re-enable body scroll
    enableBodyScroll(this.mainNav);
  }

  showSearchBar(): void {
    this.searchBar = !this.searchBar;
    this.searchService.cleanSuggestions();
  }

  cancelSearchBar(): void {
    this.searchService.cleanSuggestions();
    this.searchBar = false;
  }

  public onSearchBarChange(value: boolean) {
    this.searchBar = value;
  }

  showTooltip() : void {
    this.isTooltip = true;
  }
  
  hideTooltip() : void {
    this.isTooltip = false;
  }

  login(): void{
    this.authService.login();
  }
}
