import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/** @title Select with custom trigger text */
@Component({
  selector: 'dep-filtre',
  templateUrl: 'filtre.component.html',
  styleUrls: ['filtre.component.scss'],
})
export class FiltreComponent {
  toppings = new UntypedFormControl();
  toppings2 = new UntypedFormControl();

  toppingsLabel = 'Localisation';
  toppingList: string[] = ['Paris', 'Rennes', 'Nantes', 'Lille', 'Lyon', 'Toulouse'];

  toppings2Label = 'Localisation';
  topping2List: string[] = ['Paris', 'Rennes', 'Nantes', 'Lille', 'Lyon', 'Toulouse'];
}
