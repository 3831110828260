
<div class="pagination" *ngIf="(pageContacts$|async) !=null && (pageContacts$|async).content.length > 0">
    <div class="pagination_info">{{ (((pageContacts$|async).number) * (pageContacts$|async).size)+1 }}-{{
        (pageContacts$|async).content.length + ((pageContacts$|async).number) * (pageContacts$|async).size }} sur {{
        (pageContacts$|async).totalElements }} contacts</div>
    <div class="numbers_container">

        <button [disabled]="(pageContacts$|async).number === 0" (click)="prevPage()">
            <dep-icon [icon]="'icon-arrow_back_ios'" class="icon-arrow"></dep-icon>
        </button>
        
        <button *ngFor="let page of visiblePages | async" [class.active]="page === ((pageContacts$|async).number+1)"
            class="customised" (click)="goToPage(page)">{{ page }}</button>
      
        <button [disabled]="(pageContacts$|async).number === (pageContacts$|async).totalPages-1" (click)="nextPage()">
            <dep-icon [icon]="'icon-arrow_forward_ios'" class="icon-arrow"></dep-icon>
        </button>

    </div>
</div>

<div class="contacts_container" aria-live="polite" *ngIf="contacts != null">
    <ng-container *ngFor="let contact of contacts">
        <dep-contact [contact]="contact"></dep-contact>
    </ng-container>
</div>

<ng-container *ngIf="contacts != null && contacts?.length < 1">
    <div class="results_msg">
        <img src="/assets/images/not-found.png" alt="not-found" class="customised" />
        <h1>Nous n'avons pas de résultat pour votre recherche....</h1>
        <p>Mais vous pouvez toujours retenter votre chance : vérifiez l'orthographe ou essayez avec des filtres de
            recherche moins spécifiques</p>
    </div>
</ng-container>


<div class="pagination" *ngIf="(pageContacts$|async) !=null && (pageContacts$|async).content.length > 0">
    <div class="pagination_info">{{ (((pageContacts$|async).number) * (pageContacts$|async).size)+1 }}-{{
        (pageContacts$|async).content.length + ((pageContacts$|async).number) * (pageContacts$|async).size }} sur {{
        (pageContacts$|async).totalElements }} contacts</div>
    <div class="numbers_container">

        <button [disabled]="(pageContacts$|async).number === 0" (click)="prevPage()">
            <dep-icon [icon]="'icon-arrow_back_ios'" class="icon-arrow"></dep-icon>
        </button>

        <button *ngFor="let page of visiblePages | async" [class.active]="page === ((pageContacts$|async).number+1)"
            class="customised" (click)="goToPage(page)">{{ page }}</button>

        <button [disabled]="(pageContacts$|async).number === (pageContacts$|async).totalPages-1" (click)="nextPage()">
            <dep-icon [icon]="'icon-arrow_forward_ios'" class="icon-arrow"></dep-icon>
        </button>

    </div>
</div>