<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main">
  <!-- Sticky header -->
  <dep-article-sticky-header [articleTitle]="forum?.title" [articleCategories]="forum?.tags" [buttonIcon]="'icon-share'" [buttonText]="'Partager'"
    (buttonAction)="copyUrl()" [buttonMessage]="'Lien copié !'" [showButtonMessage]="clipboardCopySuccess"></dep-article-sticky-header>

  <!-- Page header -->
  <dep-page-header-image class="page-header-wrapper" [headerImage]="forum?.imageUrl" [altImage]="forum?.altImage">
    <!-- Article header content -->
    <dep-article-header [articleTitle]="forum?.title" 
     [articleDate]="forum?.publishDate | date :'d MMMM yyy' | titlecase" 
     [articleDateTime]="'2020-12-15'"
     [articleReadingTime]="forum?.readingTime"
     [buttonIcon]="'icon-share'"
     [buttonText]="'Partager'" 
     [isPopover]="true" content>
      <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
        <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
        <dep-breadcrumb-item [itemRouterLink]="'/actualites-ressources/.'" *appBreadcrumbItem>Actualités et ressources
        </dep-breadcrumb-item>
        <dep-breadcrumb-item *appBreadcrumbItem>Tribunes</dep-breadcrumb-item>
      </dep-breadcrumb>
    </dep-article-header>

    <!-- Themes -->
    <dep-article-categories [articleCategories]="forum?.tags" subheader></dep-article-categories>
  </dep-page-header-image>

  <!-- Article content -->
  <div class="article-wrapper">
    <ng-container *ngFor=" let forumContent of forum?.contents">

      <!-- Text -->
      <ng-container *ngIf="forumContent.textId">
        <p [innerHTML]="forumContent.paragraph | markdown"></p>
      </ng-container>

      <!-- Quote -->
      <ng-container *ngIf="forumContent.quoteId">
        <dep-article-quote [quote]="forumContent.quote" [quoteSource]="forumContent.authorName">
        </dep-article-quote>
      </ng-container>

      <!-- Related content -->
      <ng-container *ngIf="forumContent.linkId">
        <dep-article-related-content [relatedContent]="forumContent.linkTitle" [relatedLinkContent]="forumContent.linkUrl">
        </dep-article-related-content>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="forumContent.videoId && forumContent.videoUrl">
        <dep-article-video [videoUrl]="forumContent.videoUrl" [videoType]="'video/mp4'" [videoTitle]="forumContent.videoTitle"
          [videoSource]="forumContent.videoSource">
        </dep-article-video>
      </ng-container>

      <!-- YOUTUBE video -->
      <ng-container *ngIf="forumContent.videoId && forumContent.videoSource && !forumContent.videoUrl">
        <dep-article-youtube-video [videoUrl]="forumContent.videoSource" [videoTitle]="forumContent.videoTitle"></dep-article-youtube-video>
      </ng-container>

      <!-- Image carousel -->
      <ng-container *ngIf="forumContent.imagesId">
        <dep-article-image-carousel [carouselTitle]="forumContent.slideShowTitle" [carouselSource]="forumContent.imagesSource">
          <ng-container *ngFor=" let carouselPictures of forumContent.imagesUrls">
            <dep-article-image-carousel-item [imageSrc]="carouselPictures" [imageAlt]="forumContent.altImages" *appCarouselSlide></dep-article-image-carousel-item>
          </ng-container>

        </dep-article-image-carousel>
      </ng-container>

      <!-- Image -->
      <ng-container *ngIf="forumContent.imageId">
        <dep-article-image-carousel [carouselTitle]="forumContent.imageTitle" [carouselSource]="forumContent.imageSource">
          <dep-article-image-carousel-item [imageSrc]="forumContent.imageUrl" [imageAlt]="forumContent.altImage" *appCarouselSlide></dep-article-image-carousel-item>
        </dep-article-image-carousel>
      </ng-container>

      <!-- Document -->
      <ng-container *ngIf="forumContent.documentId">
        <dep-article-document [fileName]="forumContent.documentTitle" [fileButtonName]="forumContent.downloadButtonTitle" [fileURL]="forumContent.documentUrl"></dep-article-document>
      </ng-container>

      <!-- Podcast -->
      <ng-container *ngIf="forumContent.podcastId">
        <dep-article-podcast></dep-article-podcast>
      </ng-container>

    </ng-container>
  </div>
</main>

<!-- <aside class="suggestions">
  <dep-page-section-actualities
    [sectionTheme]="'light'"
    [sectionTitle]="'Suggestions'"
    [sectionRouterLink]="actualitiesTypes[1].route"
    [sectionLinkIcon]="actualitiesTypes[1].icon"

  >
    <div class="suggestions__list">
      <ng-container *ngFor="let n of [].constructor(3); let i = index">
        <app-actuality-thumbnail-prototype
          [actualityCategories]="['Biotech', 'Agritech']"
          [actualityImage]="'/assets/images/actualites-ressources/EtudeBiotech.jpg'"
          [actualityTitle]="'Un bio-stimulant pour remplacer les produits chimiques'"
          [actualityDate]="'15 décembre'"
          [actualityDuration]="'8 minutes'"
          [actualityIntro]="'Obtenir un meilleur rendement et une meilleure qualité des plants sans produit chimique. C’est le défi relevé par MycoPhyto, une solution naturelle qui aide les plantes à se nourrir.'"
          [actualityIndex]="i"
          [actualityRouterLink]="'/actualite-v2021/.'"
        >
        </app-actuality-thumbnail-prototype>
      </ng-container>
    </div>
  </dep-page-section-actualities>
</aside> -->

<dep-scroll-to-top></dep-scroll-to-top>
<div *ngIf="isOpen">
  <dep-newsletter [newsletter] = "newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-footer-public-space></dep-footer-public-space>
