import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRemainingdays'
})
export class DateRemainingdaysPipe implements PipeTransform {

  transform(value: Date): string | null {
    if (!value) {
      return '';
    }
    const remainingDays: number = this.calculateDiff(value);

    if (remainingDays > 1 && remainingDays <= 7){
      return remainingDays + ' jours restants';
    }
    if (remainingDays === 1){
      return remainingDays + ' jour restant';
    }
    if (remainingDays === 0){
      return 'Aujourd\'hui';
    }

  };

  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.abs(Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24)));
}
}
