<div class="service__container" *ngIf="service">
    <div class="service__image">
        <img src="{{service.imageUrl}}" alt="{{service.altImage}}" loading="lazy">
    </div>
    <div class="service__content">
        <p class="service__title">{{service.name}}</p>
        <div class="service__partners">
            <ng-container *ngFor="let partner of partners">
                <a [routerLink]="['/mon-espace-partenaire/acteurs/',partner.id+'-'+(partner.name.toLowerCase() | formatUrl)+'.html']">{{partner.name}}</a>&nbsp;&nbsp;                
            </ng-container>
        </div>
        <p class="service__description"  [innerHTML]="service.description | markdown"></p>
        <div class="service__btn" *ngIf="service.link && isAuthorized">
            <a href="{{service.link}}" target="_blank" rel="noopener noreferrer" class="ds-btn ds-btn--primary">Accéder au service</a>
        </div>
        <div *ngIf="!isAuthorized">
            <a class="msg-error" style="color: #BE0E25;" >Vous n’avez pas accès à ce service.</a>
            <br><br>
            <a  [routerLink]="'/contact.html'" [state]="{source:'espace partenaire'}" class="service__link">
                <dep-icon [icon]="'icon-open_in_new'" class="icon"></dep-icon>Demander l’accès
            </a>
        </div>
        <div class="service__save-btn btn-mobile">
            <input type="checkbox" class="save-btn" [(ngModel)]="isChecked" (click)="toggle()">
        </div>
    </div>
    <div class="save">
        <div class="service__save-btn">
            <input type="checkbox" class="save-btn" [(ngModel)]="isChecked" (click)="toggle()">
        </div>
        <p>Épingler au tableau de bord</p>
    </div>
</div>