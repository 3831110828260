import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss']
})
export class FormSectionComponent implements OnInit {
  @Input('formSectionTitle') title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
