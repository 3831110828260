<div class="newsletter" *ngIf = "newsletter">
    <p class="newsletter__title">{{newsletter.title}}</p>
      <p class="newsletter__text">
       {{newsletter.text}}
      </p>
      <p class="newsletter__subtext">
        En m'inscrivant, j'accepte les &nbsp;
        <a href="./assets/docs/CGU lesdeeptech.fr site vitrine v2.pdf" target="_blank"  rel="noopener noreferrer"
          >conditions générales d'utilisation.</a
        >
      </p>
    <div class="newsletter__register">
      <form class="register__form" #f="ngForm" (ngSubmit)="onSubmit(f)">
        <label for="email" class="register__input-wrapper">
          <input
            id="email"
            class="register__input"
            [class.register__input_status_error]="
              f.submitted && (email.errors || emailFound)
            "
            type="email"
            name="email"
            placeholder="Votre email"
            #email="ngModel"
            ngModel
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
            required
          />
          <span class="sr-only">Votre email</span>
        </label>
        <ng-container
          *ngIf="
            f.submitted &&
            email.value.length === 0 &&
            email.errors &&
            !emailFound
          "
        >
          <p class="register__input-feedback">
            Veuillez renseigner votre adresse email
          </p>
        </ng-container>
        <ng-container
          *ngIf="
            f.submitted &&
            email.value.length !== 0 &&
            email.errors &&
            !emailFound
          "
        >
          <p class="register__input-feedback">
            Veuillez renseigner une adresse email valide (mon-email@exemple.fr)
          </p>
        </ng-container>

        <button type="submit" area-label="s'inscrire" class="register__submit">S’inscrire</button>
      </form>
    </div>
    <button type="button" class="newsletter__close" area-label="close" (click)="close()">
      <dep-icon [icon]="'icon-close'"></dep-icon>
    </button>
</div>
