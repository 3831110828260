import { Component, Input, OnInit } from '@angular/core';
import { DirectAccess } from 'src/app/models/direct-access/directAccess.model';

@Component({
  selector: 'dep-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {
  @Input() content : DirectAccess;
  constructor() { }

  ngOnInit(): void {
  }

}
