<div class="sticky-header" [@inOutAnimation] *ngIf="showStickyHeader">
  <!-- Logo -->
  <a class="sticky-header__logo" routerLink="/" aria-label="Accueil">
    <img src="/assets/images/deep-tech/Logo-Lesdeeptech-bleu.png" alt="Les deeptech">
  </a>

  <!-- Title -->
  <p class="sticky-header__title">{{articleTitle}}</p>

  <!-- Categories -->
  <p class="sticky-header__categories" *ngIf="articleCategories">
    <span class="sticky-header__category" *ngFor="let category of articleCategories">{{category.name}}</span>
  </p>

  <!-- Action button -->
  <div class="sticky-header__action-wrapper" aria-live="polite" *ngIf = "buttonIcon && buttonText">
    <button class="sticky-header__action" (click)="this.buttonAction.emit();">
      <dep-icon class="icon" [icon]="buttonIcon"></dep-icon>&nbsp;{{buttonText}}
    </button>
    <p class="sticky-header__action-message" [@inOutAnimation] *ngIf="showButtonMessage">{{buttonMessage}}</p>
  </div>

  <!-- Scroll indicator -->
  <div class="sticky-header__progressbar-container" #progressbarContainer>
    <div class="sticky-header__progressbar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" #progressbar></div>
  </div>
</div>
