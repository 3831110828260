<section class="page-header">
  <div class="page-header__image">
    <img [src]="headerImage" alt="{{altImage}}">
  </div> 
  <div class="page-header__container">
    <div class="page-header__content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
  <div class="page-header__subheader">
    <ng-content select="[subheader]"></ng-content>
  </div>
</section>
