import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-page-section',
  templateUrl: './page-section.component.html',
  styleUrls: ['./page-section.component.scss']
})
export class PageSectionComponent implements OnInit {
  @Input('sectionTitle') title: string;
  @Input('sectionTheme') theme: string;
  @Input('sectionBgText') bgtext: string;
  @Input('sectionBgImage') bgimage: string;
  @Input('logoTitle') logoTitle: string;
  @Input() isPartnerSpace :boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
