<article class="podcast-thumbnail" [ngClass]="{'podcast-size-l': isLargeSize}">
    <div class="podcast-thumbnail__image">
        <div class="podcast-thumbnail__image-wrapper">
            <img src="{{podcast.imageUrl}}" alt="{{podcast.altImage}}" loading="lazy" draggable="false">
        </div>
    </div>
    <div class="podcast-thumbnail__meta">
        <span class="podcast-thumbnail__title">{{podcast.title}}</span>
        <p class="podcast-thumbnail__author">{{podcast.authorNameAndRole}}</p>
        <p class="podcast-thumbnail__intro">{{podcast.description}}</p>
        <p class="podcast-thumbnail__duration">
            <dep-icon class="icon" [icon]="'icon-timer'"></dep-icon>
            {{podcast.duration}}
        </p>
        <a  [routerLink]="replaceUrl()" class="podcast-thumbnail__button">
            Écouter
            <!-- hidden text to complete the link label (for screen readers and bots)  -->
            <span class="sr-only"> le podcast : {{podcast.title}}</span>
        </a>
    </div>
</article>