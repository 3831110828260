import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { Podcast } from '../../models/podcasts/podcast.model';
import { PodcastRequest } from 'src/app/models/podcasts/podcast-request.model';

@Injectable({
  providedIn: 'root'
})
export class PodcastService {
  private PODCASTS_PATH = '';
  private REST_API_SERVER = '';
  private PODCASTS_COUNT = '';

  constructor(private ressourcePath: GlobalURLs,
              private http: HttpClient) {
      this.REST_API_SERVER = environment.apiUrl;
     }

  public getPodcasts(request:PodcastRequest): Observable<Podcast[]> {

    this.PODCASTS_PATH = this.ressourcePath.getPodcasts();
    return this.http.post<Podcast[]>(this.REST_API_SERVER + this.PODCASTS_PATH, request);
  }

  public getPodcastsCount(isActusRessources?:boolean, tagIds?:number[]): Observable<number> {
    this.PODCASTS_COUNT = this.ressourcePath.getPodcastsCount();
    let params = new HttpParams();
    if (isActusRessources) {
      params = params.append("isActusRessources", isActusRessources);
    }
    if (tagIds) {
      params = params.appendAll({ "tagIds": tagIds });
    }
    return this.http.get<number>(this.REST_API_SERVER + this.PODCASTS_COUNT, { params });
  }

  public getPodcastById(id: number): Observable<Podcast> {
    this.PODCASTS_PATH = this.ressourcePath.getPodcastId(id);
    return this.http.get<Podcast>(this.REST_API_SERVER + this.PODCASTS_PATH);
  }
}
