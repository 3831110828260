import { Component, Input, OnInit } from '@angular/core';
import { Podcast } from 'src/app/models/podcasts/podcast.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-podcast-thumbnail',
  templateUrl: './podcast-thumbnail.component.html',
  styleUrls: ['./podcast-thumbnail.component.scss']
})
export class PodcastThumbnailComponent implements OnInit {
  @Input('podcast') podcast: Podcast;
  @Input() isLargeSize : boolean =false;
  replacePodcastTag: boolean = true;
  constructor(private urlFormatter: FormatUrlPipe) { }
  public url: string = "";

  ngOnInit(): void {
  }

  replaceUrl(): string {
    this.podcast.tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        this.url = "/podcasts/Autres/" + this.podcast.id + '-' + this.urlFormatter.transform(this.podcast.url) + '.html';
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1);
      } else {
        this.url = "/podcasts/" + this.urlFormatter.transform(array[0].name) + "/" + this.podcast.id + '-' + this.urlFormatter.transform(this.podcast.url) + '.html'
      }

    });


    return this.url;
  }

}
