import { ExpertiseArea } from "../expertise-area/expertise-area.model"
import { InterventionLocation } from "../intervention-location/intervention-location.model"

export class User {
    id: string
    realm: string
    firstName: string
    lastName: string
    email: string
    organization: Organization
    habilitations: Habilitation[]
    memberApplication: MemberApplication
    cguValidated: boolean
    firstConnection: boolean
    showContact: boolean
    status: UserStatus
    level:UserLevel

    phone: string
    linkedinUrl: string
    job: string
    photoUrl: string
    interventionLocations: InterventionLocation[]
    expertiseAreas: ExpertiseArea[]

    hasAcceptedHabilitation(habilitation: string): boolean{
        return this.habilitations.find( hab => hab.habilitationStrapiCopy.name == habilitation && hab.status == StatusName.ACCEPTE) !=null;
    }

    hasHabilitation(habilitation: string): boolean{
        return this.habilitations.find( hab => hab.habilitationStrapiCopy.name == habilitation) !=null;
    }

}

export class Organization {
    name: string
    type: string
}


export class MemberApplication {
    idRequest: number
    isPui: boolean
    pui: string
    otherStructure: Organization
}


export class Habilitation {
    id: number
    habilitationStrapiCopy: HabilitationStrapiCopy
    status: StatusName
}

export class HabilitationStrapiCopy{
    name:string
}

export enum StatusName {   
    REFUSE="REFUSE",
    NON_DEMANDE="NON_DEMANDE",
    DEMANDE="DEMANDE",
    ACCEPTE="ACCEPTE"
}

export enum UserStatus {   
    NEW="NEW",
    MEMBER="MEMBER",
    PENDING="PENDING",
    PENDING_DELETION="PENDING_DELETION"
}

export enum UserLevel {
    ADMIN="ADMIN",
    SUPER_ADMIN="SUPER_ADMIN"
}