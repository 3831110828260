import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/authentification/auth.service';


@Component({
  selector: 'dep-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() btnTitle: string;
  @Input() btnUrl: string;
  @Input() title: string;
  @Input() message: string;
  public close: boolean = false;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  closePopup(): void {
    this.close = true;
    this.authService.logout();
  }

}
