<article>
  <a [routerLink]="offerRouterLink" class="offer-list-item">
    <div class="offer-list-item__image">
      <img *ngIf="offerImage" [src]="offerImage" [alt]="offerImageAlt">
      <img *ngIf="offerImage===null && offerType=='tandem'" src="assets/images/logo_tandem.jpg" alt="logo tandem">
      <img *ngIf="offerImage===null && offerType=='advisor'" src="assets/images/logo_tango.jpg" alt="logo tango">
      <img *ngIf="offerImage===null && offerType=='mercato'" src="assets/images/mercato.png" alt="logo mercato">
    </div>
    <p class="offer-list-item__categories">
      <span class="offer-list-item__category" *ngFor="let category of offerCategoriesToDisplay">{{category}}</span>
    </p>
    <p class="offer-list-item__info offer-list-item__date">
      <dep-icon class="icon" [icon]="'icon-time'"></dep-icon>{{(offerDate | nbDaysElapsed)}}
    </p>
    <p class="offer-list-item__info offer-list-item__location">
      <dep-icon class="icon" [icon]="'icon-pin'"></dep-icon>{{offerLocation}}
    </p>
    <p class="offer-list-item__info offer-list-item__contract">
      <dep-icon class="icon" [icon]="'icon-associes'"></dep-icon>{{offerContract}}
    </p>
    <h3 class="offer-list-item__title">{{offerTitle}}</h3>
    <p class="offer-list-item__intro" *ngIf="offerIntro" [innerHTML]="offerIntro"></p>
  </a>
</article>