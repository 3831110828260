import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-article-document',
  templateUrl: './article-document.component.html',
  styleUrls: ['./article-document.component.scss']
})
export class ArticleDocumentComponent implements OnInit {
  @Input() fileName: string;
  @Input() fileURL: string;
  @Input('fileButtonName') fileButtonName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
