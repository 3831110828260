import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'dep-page-header-text-image',
  templateUrl: './page-header-text-image.component.html',
  styleUrls: ['./page-header-text-image.component.scss']
})
export class PageHeaderTextImageComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() headerImage: string;
  //TODO: temporary solution 
  @Input() isObs:boolean=false;
  @Input() isEuroquity:boolean=false;
  @Input() isInscriptionForm:boolean=false;
  @Input() isTandem:boolean=false;
  @Input() isAdvisor:boolean=false;
  @Input() isSkillsTransfer:boolean=false;


  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  back(): void {
    this.location.back()
  }

}
