<a href="{{event.externalLink}}" target="_blank" rel="noopener noreferrer" >
    <div class="event_container">
        <div class="event_image">
            <img src="{{event.imageUrl}}" alt="event image" class="image customised" />
        </div>
        <div class="event_content">
            <div class="tags_wrapper">
                <div class="tag" style="background-color: #FFCD00;" *ngFor="let sector of event.secteurs"><span>{{sector.title}}</span></div>
                <div class="tag" *ngFor="let format of event.formats"><span>{{format.title}}</span></div>
            </div>
            <p class="date" *ngIf="!event.endDate"> {{event.date | date :'d MMMM YYYY' | titlecase}} - {{event.location}}</p>
            <p class="date" *ngIf="event.endDate"> Du {{event.date | date :'dd/MM/YY' | titlecase}} au {{event.endDate | date :'dd/MM/YY' | titlecase}} - {{event.location}}</p>
            <p class="title" >{{event.title}}</p>
            <p class="description" [innerHTML]="event.description"></p>
            <div class="cta">
                <a href="" class="cta-link">Voir l’évènement
                    <img src="/assets/images/icones/arrow_forward.svg" alt="icon arrow" class="customised" />
                </a>
            </div>
        </div>
    </div>
</a>