import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { ReferenceType } from 'src/app/models/favorites/referenceType';
import { Partner } from 'src/app/models/partners/partner.model';
import { PartnerRequest } from 'src/app/models/partners/partnerRequest.model';
import { environment } from 'src/environments/environment';
import { FavoriteService } from '../favorites/favorite.service';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  private REST_API_SERVER = '';
  private PARTNERS = '';
  private PARTNER = '';
  private PARTNERS_COUNT = '';
  
  public partnersBehavior = new BehaviorSubject<Partner[]>([]);
  public partners$ = this.partnersBehavior.asObservable();
 


  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs,
    ) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getPartners(partnerRequest?: PartnerRequest): Observable<Partner[]> {
    this.PARTNERS = this.ressourcePath.getPartners();
    return this.http.post<Partner[]>(this.REST_API_SERVER + this.PARTNERS, partnerRequest).pipe(
      map(features => features.map(feature=>{
        feature.type = ReferenceType.PARTNER;
        return feature;
      }))
    );
  }

  public getPartnersCount(): Observable<number> {
    let params = new HttpParams();
    this.PARTNERS_COUNT = this.ressourcePath.getPartnersCount();
    return this.http.get<number>(this.REST_API_SERVER + this.PARTNERS_COUNT, { params });
  }

  public getPartnerById(id: number): Observable<Partner> {
    this.PARTNER = this.ressourcePath.getPartnerById(id);
    return this.http.get<Partner>(this.REST_API_SERVER + this.PARTNER).pipe(
      map(feature=>{
        feature.type = ReferenceType.PARTNER;
        return feature;
      })
    );
  }

}
