import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dep-partner-space-footer',
  templateUrl: './partner-space-footer.component.html',
  styleUrls: ['./partner-space-footer.component.scss']
})
export class PartnerSpaceFooterComponent implements OnInit {

  pdfCGU : string;

  constructor() { 
    this.pdfCGU = environment.privateCguURL;
  }

  ngOnInit(): void {
  }

}
