import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-steps-grid-item',
  templateUrl: './steps-grid-item.component.html',
  styleUrls: ['./steps-grid-item.component.scss']
})
export class StepsGridItemComponent implements OnInit {
  @Input() stepLabel: string;
  @Input() stepTitle: string;


  constructor() { }

  ngOnInit(): void {
  }

}
