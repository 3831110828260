<div
  class="ds-input-group"
  [class.ds-input-group--required]="required"
  [class.ds-input-group--error]="error"
  >
  <label for="{{name}}" class="ds-input-group__label">{{label}}</label>

  <input
    type="{{type}}"
    id="{{name}}"
    class="ds-input-group__input"
    name="{{name}}"
    placeholder="{{placeholder}}"
    pattern="{{pattern}}"
    [attr.required]="required"
    ngModel
    [ngModelOptions]="{name: 'name'}"
  />

  <ng-container *ngIf="info">
    <i class="ds-input-group__icon ds-input-group__icon--info fa fa-question-circle"></i>
    <div class="ds-input-group__info">
        <div class="ds-input-group__info--inner">
            {{info}}
        </div>
    </div>
  </ng-container>

  <ng-container *ngIf="error">
    <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
    <div class="ds-input-group__feedback">
        {{errorMessage}}
    </div>
  </ng-container>
</div>
