import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { Study } from '../../models/studies/study.model';
import { createHttpParams } from '../../shared/utils/http-params';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  private REST_API_SERVER = '';
  private STUDIES = '';
  private STUDY = '';
  private STUDIES_COUNT = '';

  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getStudies(index?: number, limit?: number, sectorId?: number, tagIds?: number[]): Observable<Study[]> {
    let params: HttpParams = createHttpParams({
      index: index?.toString(),
      limit: limit?.toString(),
      sectorId: sectorId?.toString()
    });

    if (tagIds)
      params = params.appendAll({ "tagIds": tagIds })

    this.STUDIES = this.ressourcePath.getStudies();
    return this.http.get<Study[]>(this.REST_API_SERVER + this.STUDIES, { params });
  }

  public getStudiesCount(tagIds?: number[]): Observable<number> {
    let params: HttpParams = createHttpParams({
    });

    if (tagIds)
      params = params.appendAll({ "tagIds": tagIds })

    this.STUDIES_COUNT = this.ressourcePath.getStudiesCount();
    return this.http.get<number>(this.REST_API_SERVER + this.STUDIES_COUNT, { params });
  }

  public getStudyById(id: number): Observable<Study> {
    this.STUDY = this.ressourcePath.getStudyById(id);
    return this.http.get<Study>(this.REST_API_SERVER + this.STUDY);
  }
}
