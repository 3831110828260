import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Witness } from 'src/app/models/witness/witness.model';
import { WitnessFilter } from 'src/app/models/witness/witnessFilter.model';
import { WitnessRequest } from 'src/app/models/witness/witnessRequest.model';
import { WitnessResponse } from 'src/app/models/witness/witnessResponse.model';
import { WitnessKeyNumber } from 'src/app/models/witness/witnesskeynumber.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WitnessService {


  private REST_API_SERVER = '';
  private URL = '';


  public index = 0;
  public pageSize = 20;
  public page = 1;

  public witnessResponseBehavior = new BehaviorSubject<WitnessResponse>(null);
  public witnessResponse$ = this.witnessResponseBehavior.asObservable();

  public pagesBehavior = new BehaviorSubject<number[]>([]);
  public pages$ = this.pagesBehavior.asObservable();

  public indexBehavior = new BehaviorSubject<number>(this.index);
  public index$ = this.indexBehavior.asObservable();

  public pageSizeBehavior = new BehaviorSubject<number>(this.pageSize);
  public pageSize$ = this.pageSizeBehavior.asObservable();

  public pageBehavior = new BehaviorSubject<number>(this.page);
  public page$ = this.pageBehavior.asObservable();

  public witnessRequest: WitnessRequest = new WitnessRequest();
  public pages: number[] = [];

  public witnessSelectedBehavior = new BehaviorSubject<Witness>(null);
  public witnessSelected$ = this.witnessSelectedBehavior.asObservable();
  
  public lastwitnessSelectedBehavior = new BehaviorSubject<Witness>(null);
  public lastwitnessSelected$ = this.lastwitnessSelectedBehavior.asObservable();

  public witnessScrollBehavior = new BehaviorSubject<String>(null);
  public witnessScroll$ = this.witnessScrollBehavior.asObservable();


  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs
  ) {
    this.REST_API_SERVER = environment.apiUrl;

    this.witnessRequest.index = this.pageSize * this.index;
    this.witnessRequest.limit = this.pageSize * (this.index + 1);
    this.witnessRequest.search = "";
    this.witnessRequest.jobs = [];
    this.witnessRequest.activitySector = [];
    this.witnessRequest.expertiseSector = [];
    this.witnessRequest.locations = [];
    this.witnessRequest.profiles = [];
    this.witnessRequest.contests = [];
    this.witnessRequest.presential = false;
    this.witnessRequest.distancial = false;

    this.witnessSelected$.subscribe(
      witnessSelected => {
        if (witnessSelected !== null) {
          this.setWitnessSelectedToLocal(witnessSelected);
        }
      }
    );

    this.lastwitnessSelected$.subscribe(
      lastwitnessSelected => {
        if (lastwitnessSelected !== null) {
          this.setWitnessSelectedToLocal(lastwitnessSelected);
        }
      }
    );

    let witnessSelectedFromLocalStorage = this.getWitnessSelectedFromLocal();
    if (witnessSelectedFromLocalStorage !== null) {
      this.setWitnessSelectedToLocal(witnessSelectedFromLocalStorage);
    }
  }

  public getWitnesses(witnessRequest: WitnessRequest): Observable<WitnessResponse> {
    this.URL = this.ressourcePath.getWitnesses();
    return this.http.post<WitnessResponse>(this.REST_API_SERVER + this.URL, witnessRequest);
  }

  public getLastWitnesses(): Observable<Witness[]> {
    this.URL = this.ressourcePath.getLastWitnesses();
    return this.http.get<Witness[]>(this.REST_API_SERVER + this.URL);
  }

  public getWitnessById(id: number): Observable<Witness> {
    this.URL = this.ressourcePath.getWitnessById(id);
    return this.http.get<Witness>(this.REST_API_SERVER + this.URL);
  }

  public getWitnessFilter(): Observable<WitnessFilter> {
    this.URL = this.ressourcePath.getWitnessFilter();
    return this.http.get<WitnessFilter>(this.REST_API_SERVER + this.URL);
  }

  public getWitnessKeyNumber(): Observable<WitnessKeyNumber> {
    return this.http.get<WitnessKeyNumber>(this.REST_API_SERVER +this.ressourcePath.getWitnesses()+ this.ressourcePath.apiWitnessKeyNumber);
  }

  public filterWitnesses() {

    this.witnessRequest.index = this.pageSize * (this.page - 1);
    this.witnessRequest.limit = this.pageSize * this.page;

    this.getWitnesses(this.witnessRequest).subscribe(
      response => {
        this.witnessResponseBehavior.next(response);

        const pageCount = Math.ceil(response.total / this.pageSize);
        this.pages = [];
        for (let i = 1; i <= pageCount; i++) {
          this.pages.push(i);
        }
        this.pagesBehavior.next(this.pages)
      }
    )
  }


  public prevPage(): void {
    this.page--;
    this.pageBehavior.next(this.page);
    this.filterWitnesses();
    this.witnessSelectedBehavior.next(null);
  }

  public nextPage(): void {
    this.page++;
    this.pageBehavior.next(this.page);
    this.filterWitnesses();
    this.witnessSelectedBehavior.next(null);
  }

  public goToPage(page: number): void {
    this.page = page;
    this.pageBehavior.next(this.page);
    this.filterWitnesses();
    this.witnessSelectedBehavior.next(null);
  }

  getWitnessSelectedFromLocal(): Witness {
    return JSON.parse(sessionStorage.getItem('witnessSelected'));
  }

  setWitnessSelectedToLocal(witness: Witness) {
    sessionStorage.setItem('witnessSelected', JSON.stringify(witness));
  }

}
