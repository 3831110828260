<ul
  class="carousel"
  [attr.aria-label]="ariaLabel ? ariaLabel : null"
  aria-live="polite"
  tabindex="0"
  #carousel
>
  <ng-container *ngFor="let slide of carouselSlides">
    <li class="carousel__slide-wrapper">
      <ng-container [ngTemplateOutlet]="slide.slideTemplate"></ng-container>
    </li>
  </ng-container>
</ul>
