import { Component, Input, OnInit } from '@angular/core';
import { Video } from 'src/app/models/videos/videos.model';

@Component({
  selector: 'dep-video-card-list',
  templateUrl: './video-card-list.component.html',
  styleUrls: ['./video-card-list.component.scss']
})
export class VideoCardListComponent implements OnInit {

  @Input() videos: Video[];
  
  constructor() { }
  
  ngOnInit(): void {
  }

}
