import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieNewsletterService {
  isOpen: boolean;
  constructor(private cookieService: CookieService) {}

  public checkCookie(cookieName? :string ): boolean {
    if (this.cookieService.check(cookieName)) {
      this.isOpen = this.cookieService.get(cookieName) === 'true' ? true : false;
    } else {
      this.setCookie(cookieName, 'true', { sameSite: 'Lax' } );
      this.isOpen = true;
    }
    return this.isOpen ;
  }
  public setCookie( cookieName?: string, value?: string,  options?: {sameSite?: string}): void{
    this.cookieService.set(cookieName, value);
  }
}
