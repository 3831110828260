<dep-navbar-public-space></dep-navbar-public-space>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Financer son projet" [longTitle]="true">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup/.'" *appBreadcrumbItem>Envie d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Financer son projet</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>


  <dep-vagues-bleu-clair titleVaguesBleu="Financer son projet">
    <p>
      La Deeptech représente un enjeu stratégique majeur pour l’Etat qui a concrétisé son engagement dans cette
      course
      à l’innovation par le lancement en 2019 du Plan Deeptech : 2,5 milliards d’euros mis à disposition et
      ventilés
      dans différentes offres de financement à destination des startups et fonds d’investissement Deeptech. Le
      secteur
      privé s’est également emparé du sujet avec plus de 1,5 milliards d’euros levés par les startups Deeptech en
      2019.
    </p>
    <p>
      En France l’offre de financement est riche et les acteurs nombreux, le financement n’est pas un frein à la
      création et croissance de votre startup ! Il est important de garder en tête que votre projet est unique et
      donc
      que le parcours de financement qui le soutiendra le sera également.
    </p>
  </dep-vagues-bleu-clair>

  <!-- Contenu éditorial -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Pourquoi chercher des financements ?</h2>

          <p>
            La temporalité des projets deeptech (avec de longs cycles de R&D et une perspective de commercialisation
            lointaine) rend ces projets très gourmands en capital. Les besoins en financement sont donc conséquents et
            étalés dans le temps.
          </p>

          <p>
            Il est important de comprendre que le financement est toujours un moyen d’atteindre un objectif et jamais
            une fin en soi. Voici quelques exemples d’éléments à financer :
          </p>

          <h3>Idéation</h3>
          <ul class="un">
            <li>
              La protection de la propriété intellectuelle,
            </li>
            <li>
              La R&D pour dérisquer son produit technologique (passage d’un concept scientifique à une proof of concept
              testée auprès d’un premier partenaire),
            </li>
            <li>
              Autres : études de marchés, étude juridique, salaire d’un associé fondateur etc.
            </li>
          </ul>

          <h3>Lancement</h3>
          <ul>
            <li>
              Les salaires de l’équipe (fondateurs et premières recrues),
            </li>
            <li>
              La R&D (évolution du produit en fonction des retours clients),
            </li>
            <li>
              Autres : distribution des produits, communication, bureau etc.
            </li>
          </ul>

          <h3>Croissance</h3>
          <ul>
            <li>
              Les salaires de l’équipe (eg. seniorisation des recrutements),
            </li>
            <li>
              L’industrialisation de la production,
            </li>
            <li>
              Autres : l’internationalisation ; expansion sur de nouveaux segments de marché etc.
            </li>
          </ul>

          <h2>Les dispositifs de financements en France ?</h2>

          <p>
            Le plan Deeptech opéré par Bpifrance et la montée en puissance des acteurs privés sur le financement des
            entreprises Deeptech ont permis la création d’un véritable continuum de financement à disposition des
            créateurs d’entreprise.
          </p>
          <p>
            Ainsi quel que soit le stade de maturité de votre projet, il existe <a
              href="https://eldorado.co/blog/2020/07/01/les-solutions-de-financement-public-pour-les-startups-deeptech"
              target="_blank" rel="noopener noreferrer">des solutions de financement adaptées pour en soutenir le développement.</a>
          </p>

          <p>
            On distingue deux grands types de financement :
          </p>
          <ol class="content-list">
            <li>
              Les financements non-dilutifs, c’est-à-dire qui n’impactent pas la répartition du capital de votre
              entreprise, se matérialisent sous forme d’aides fiscales, de subventions et de prêts (d’honneur et
              bancaires),
            </li>
            <li>
              Les financements dilutifs qui ouvrent le capital de votre startup à de nouveaux investisseurs et qui
              se matérialisent sous forme de levées de fonds auprès de vos proches (love money), d’investisseurs
              particuliers (business angel et crowdequity) ou de fonds d’investissments (venture capitalist).
            </li>
          </ol>

          <p>
            Le niveau de risque élevé d’un jeune projet deeptech donne à l’Etat un rôle primordial dans l’émergence des
            startups Deeptech. A travers les structures de valorisation il est souvent le seul à investir dans les
            projets avant même la création de l’entreprise. Au fur et à mesure du développement technologique et
            commercial du projet le risque diminue et d’autres acteurs privés entrent en jeu.
          </p>

          <p>
            Le caractère innovant des startups Deeptech leur donnent accès à la majorité des dispositifs de financement
            de la French Tech et des Régions. La Bourse Frenchtech emergence, l’aide au développement deeptech et les
            obligations French Tech Seed ont toutefois été créées spécifiquement pour les startups Deeptech.
          </p>

          <p>
            Il est donc conseillé de prendre contact avec les directions régionales de Bpifrance.
          </p>


          <dep-quote author="Sébastion Gravier, Cofondateur du Vulkam" style="margin-top: var(--spacing-l);"
            [quote]="'Le financement est une phase clé car elle est une première confrontation de la technologie à une réalité du marché'">
          </dep-quote>

          <dep-quote author="Jean-Luc Maria, Cofondateur et CTO de Exotrail" style="margin-top: var(--spacing-l);"
            [quote]="'Mon conseil est de toujours essayer d’anticiper les étapes à venir (techniques, organisationnelles, financières). Chez Exotrail, nous avons créé l’entreprise et préparé la première levée de fonds dès la fin de la première année de maturation, et nous avions déjà en tête la série A lors des négociations avec les fonds d’amorçage, notamment par la construction d’une vision à moyen et long terme de l’entreprise. Pour ma part, j’ai également dû anticiper bien en amont ma mise à disposition du CNRS, processus qui a pris environ 6 mois.'">
          </dep-quote>
        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-idea ideaTitle="La Bourse French Tech Emergence (BFTE)"
            externalRedirection="https://www.bpifrance.fr/catalogue-offres/soutien-linnovation/bourse-french-tech-emergence"
            titleLink="Découvrir">
            Jusqu’à 90 000 d’euros d’aide pour financer les dépenses liées aux études de conception, définition et
            faisabilité de projet à conduire.
          </dep-idea>
          <dep-idea ideaTitle="Aide au développement Deeptech (ADD)" ideaAlignOffset="medium"
            externalRedirection="https://www.bpifrance.fr/Toutes-nos-solutions/Aides-concours-et-labels/Aides-a-l-innovation-projets-individuels/Aide-au-developpement-deeptech"
            titleLink="Découvrir">
            Mélange de subvention et d’avance récupérable jusqu’à 2m€ pour développer un projet à forte.
          </dep-idea>
          <dep-idea ideaTitle="French Tech Seed" ideaAlignOffset="medium"
            externalRedirection="https://www.bpifrance.fr/catalogue-offres/soutien-linnovation/la-french-tech-seed-obligations-convertibles"
            titleLink="Découvrir">
            Obligations convertibles pour un montant compris entre 50k et 250k€ accessibles aux entreprises de moins de
            trois ans, développant une technologie de rupture et venant de clôturer une levée de fonds.
          </dep-idea>
          <dep-autres-ressources ideaTitle="Autres ressources" ideaAlignOffset="medium">
            <a href="https://eldorado.co/blog/2020/07/01/les-solutions-de-financement-public-pour-les-startups-deeptech"
              target="_blank" rel="noopener noreferrer" class="autres-ressources__margin">Eldorado <img
                src="/assets/images/icones/icon-lien.png" /></a>
            <a href="https://www.maddyness.com/agenda/?category=concours&type=all&city=all&date=all" target="_blank" rel="noopener noreferrer"
              class="autres-ressources__margin">Maddyness <img src="/assets/images/icones/icon-lien.png" /></a>
            <a href="https://business.lesechos.fr/entrepreneurs/aides-reseaux/start-up-entrepreneurs-concours-appels-a-projets-et-a-candidatures-211148.php"
              target="_blank" rel="noopener noreferrer" class="autres-ressources__margin">Les Echos entrepreneurs <img
                src="/assets/images/icones/icon-lien.png" /></a>
            <a href="https://les-aides.fr/" target="_blank" rel="noopener noreferrer" class="autres-ressources__margin">Les aides publics <img
                src="/assets/images/icones/icon-lien.png" /></a>
            <a href="https://bpifrance-creation.fr/encyclopedie/aides-a-creation-a-reprise-dentreprise/aides-a-linnovation/recapitulatif-principales"
              target="_blank" rel="noopener noreferrer" class="autres-ressources__margin">Bpifrance création <img
                src="/assets/images/icones/icon-lien.png" /></a>
          </dep-autres-ressources>
        </aside>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-12 md-grid-col-8 sm-grid-col-4">
          <h2>5 conseils pour vous financer</h2>

          <div class="resources grid-container">
            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Rapprochez-vous de votre Direction Régionale Bpifrance pour un étude en détail de votre projet.
            </dep-resource>

            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Définissez vos besoins de financement en investissement et en trésorerie pour établir avec votre structure
              d’accompagnement votre roadmap de financement.
            </dep-resource>
            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Tirez parti des retours d'expériences d’autres entrepreneurs deeptech passés avant vous (Tribu).
            </dep-resource>
            <dep-resource class="lg-grid-col-6 md-grid-col-4" [noDecoration]="true">
              Gardez à l’esprit que votre objectif est de vendre votre produit et non d’obtenir des financements.
              L’obtention d’une référence client à tous les stades de votre développement sera un levier majeur pour
              mobiliser les investisseurs à suivre votre projet.
            </dep-resource>
            <dep-resource class="lg-grid-col-6 md-grid-col-4" [noDecoration]="true">
              Privilégiez des financeurs qui partagent la vision et les valeurs de votre projet même s’ils ne proposent
              pas le plus gros montant d’investissement. L’aventure est longue et votre couple fondateur investisseur
              doit tenir plusieurs années !
            </dep-resource>
          </div>
        </div>
      </div>


      <!-- Les autres composantes -->
      <h2>Les autres composantes</h2>
      <div class="article__section grid-container">

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
            [routerLink]="['/creer-sa-startup/transfert-de-technologies.html']">Créer la startup et intégrer la PI</a>
        </dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/accompagnement-startup.html']">Se faire accompagner</a></dep-step>

      </div>
    </article>
  </dep-page-section>
  <!-- FIN de Les autres composantes -->
</main>

<dep-main-footer></dep-main-footer>