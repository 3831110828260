import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Ressource } from 'src/app/models/ressource/ressource.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RessourceService {


  private REST_API_SERVER = '';
  private RESSOURCE = '';
  

  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs
  ) {
    this.REST_API_SERVER = environment.apiUrl;
  }


  public getRessourceById(id: number): Observable<Ressource> {
    this.RESSOURCE = this.ressourcePath.getRessourceById(id);
    return this.http.get<Ressource>(this.REST_API_SERVER + this.RESSOURCE);
  }

  public getRessourceByFaqId(id: number): Observable<Ressource> {
    this.RESSOURCE = this.ressourcePath.getRessourceByFaqId(id);
    return this.http.get<Ressource>(this.REST_API_SERVER + this.RESSOURCE);
  }


}