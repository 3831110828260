import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

/**
 * Tabs list component
 */
@Component({
  selector: 'dep-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, AfterContentInit {
  @Input() tabsLabel: string;
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Output() tabEvent = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first, 0);
    }
  }

  selectTab(tab: TabComponent, i: number): void {
    // deactivate all tabs
    this.tabs.toArray().forEach((t) => (t.active = false));

    // activate the tab the user has clicked on.
    if (tab){
      tab.active = true;
      tab.index = i;
      this.tabEvent.emit(tab.elementId);
    }
  }
}
