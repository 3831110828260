import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule.forRoot({
      config: [
        {
          configId: 'mcb',
          authority: environment.issuer,
          redirectUrl: `${encodeURI(window.location.origin )}`,
          // redirectUrl: `${encodeURI(window.location.origin)}`,
          postLogoutRedirectUri: window.location.origin,
          clientId: environment.clientId,
          scope: 'openid profile email',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          ignoreNonceAfterRefresh: true, 
          triggerRefreshWhenIdTokenExpired: true,
          logLevel: LogLevel.Debug,
          secureRoutes: ['http://localhost:9092/'],
        },
        {
          configId: 'isam',
          authority: environment.issuer2,
          redirectUrl: environment.redirectUrl,
          // redirectUrl: window.location.origin,
          postLogoutRedirectUri: window.location.origin,
          clientId: environment.clientId2,
          scope: 'openid profile email offline_access',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          ignoreNonceAfterRefresh: true, 
          triggerRefreshWhenIdTokenExpired: true,
          logLevel: LogLevel.Debug,
          secureRoutes: ['http://localhost:9092/'],
        }
      ],
    }),
  ]
})
export class AuthConfigModule { }
