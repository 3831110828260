import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-page-header-image',
  templateUrl: './page-header-image.component.html',
  styleUrls: ['./page-header-image.component.scss']
})
export class PageHeaderImageComponent implements OnInit {
  @Input() headerImage: string;
  @Input() altImage: string;

  constructor() { }

  ngOnInit(): void {
  }

}
