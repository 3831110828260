<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main">
  <!-- Sticky header -->
  <dep-article-sticky-header [articleTitle]="podcast?.title"
    [articleCategories]="podcast?.tags"
    [buttonIcon]="'icon-share'" 
    [buttonText]="'Partager'"
    (buttonAction)="copyUrl()"
    [buttonMessage]="'Lien copié !'"
    [showButtonMessage]="clipboardCopySuccess">
  </dep-article-sticky-header>

  <!-- Page header -->
  <dep-page-header-image class="page-header-wrapper"
    [headerImage]="podcast?.imageUrl" [altImage]="podcast?.altImage">
    <!-- Article header content -->
    <dep-article-header [articleTitle]="podcast?.title"
      [articleDate]="podcast?.publishDate | date :'d MMMM yyy' | titlecase"
      [articleDateTime]="podcast?.publishDate | date :'d MMMM yyy'"
      [articleReadingTime]="podcast?.duration"
      [buttonIcon]="'icon-share'" 
      [buttonText]="'Partager'"
      (buttonAction)="copyUrl()"
      [isPopover]="true"
      [showButtonMessage]="clipboardCopySuccess" content>
      <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
        <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
        <dep-breadcrumb-item [itemRouterLink]="'/actualites-ressources/.'" *appBreadcrumbItem>
          Actualités et ressources
        </dep-breadcrumb-item>
        <dep-breadcrumb-item *appBreadcrumbItem>Podcasts</dep-breadcrumb-item>
      </dep-breadcrumb>
    </dep-article-header>

    <!-- Themes -->
    <dep-article-categories [articleCategories]="podcast?.tags" subheader></dep-article-categories>
  </dep-page-header-image>

  <!-- Article content -->
  <div class="article-wrapper">
    <ng-container *ngFor="let podcastContent of podcast?.contents">

      <!-- Text -->
      <ng-container *ngIf="podcastContent.textId">
        <p [innerHTML]="podcastContent.paragraph | markdown"></p>
      </ng-container>

      <!-- Quote -->
      <ng-container *ngIf="podcastContent.quoteId">
        <dep-article-quote [quote]="podcastContent.quote" [quoteSource]="podcastContent.authorName">
        </dep-article-quote>
      </ng-container>

      <!-- Related content -->
      <ng-container *ngIf="podcastContent.linkId">
        <dep-article-related-content [relatedContent]="podcastContent.linkTitle"
          [relatedLinkContent]="podcastContent.linkUrl">
        </dep-article-related-content>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="podcastContent.videoId && podcastContent.videoUrl">
        <dep-article-video [videoUrl]="podcastContent.videoUrl"
          [videoType]="'video/mp4'" [videoTitle]="podcastContent.videoTitle"
          [videoSource]="podcastContent.videoSource">
        </dep-article-video>
      </ng-container>

      <!-- YOUTUBE video -->
      <!-- TODO : Allow youtube urls in inputs -->
      <ng-container *ngIf="podcastContent.videoId && podcastContent.videoSource && !podcastContent.videoUrl">
        <dep-article-youtube-video [videoUrl]="podcastContent.videoSource"
          [videoTitle]="podcastContent.videoTitle"></dep-article-youtube-video>
      </ng-container>

      <!-- Images carousel -->
      <ng-container *ngIf="podcastContent.imagesId">
        <dep-article-image-carousel [carouselTitle]="podcastContent.slideShowTitle"
          [carouselSource]="podcastContent.imagesSource">
          <ng-container *ngFor="let image of podcastContent.imagesUrls">
            <dep-article-image-carousel-item [imageSrc]="image"
              [imageAlt]="podcastContent.altImages" *appCarouselSlide></dep-article-image-carousel-item>
          </ng-container>
        </dep-article-image-carousel>
      </ng-container>

      <!-- Image -->
      <ng-container *ngIf="podcastContent.imageId">
      <dep-article-image-carousel [carouselTitle]="podcastContent.imageTitle" [carouselSource]="podcastContent.imageSource">
        <dep-article-image-carousel-item [imageSrc]="podcastContent.imageUrl"
          [imageAlt]="podcastContent.altImage" *appCarouselSlide></dep-article-image-carousel-item>
      </dep-article-image-carousel>
      </ng-container>

      <!-- Document -->
      <ng-container *ngIf="podcastContent.documentId">
        <section>
          <dep-article-document [fileName]="podcastContent.documentTitle"
            [fileButtonName]="podcastContent.downloadButtonTitle"
            [fileURL]="podcastContent.documentUrl">
          </dep-article-document>
        </section>
      </ng-container>

      <!-- Podcast -->
    <ng-container *ngIf="podcastContent.podcastId">
      <dep-article-podcast [podcast]="podcastContent.iframe" id="myElem"></dep-article-podcast>
    </ng-container>

    </ng-container>

  </div>
</main>

<!-- <aside class="suggestions">
  <dep-page-section-actualities
    [sectionTheme]="'light'"
    [sectionTitle]="'Suggestions'"
    [sectionRouterLink]="actualitiesTypes[1].route"
    [sectionLinkIcon]="actualitiesTypes[1].icon"
    *ngIf="actualitiesTypes[1]"
  >
    <div class="suggestions__list">
      <ng-container *ngFor="let n of [].constructor(3); let i = index">
        <app-actuality-thumbnail-prototype
          [actualityCategories]="['Biotech', 'Agritech']"
          [actualityImage]="'/assets/images/actualites-ressources/EtudeBiotech.jpg'"
          [actualityTitle]="'Un bio-stimulant pour remplacer les produits chimiques'"
          [actualityDate]="'15 décembre'"
          [actualityDuration]="'8 minutes'"
          [actualityIntro]="'Obtenir un meilleur rendement et une meilleure qualité des plants sans produit chimique. C’est le défi relevé par MycoPhyto, une solution naturelle qui aide les plantes à se nourrir.'"
          [actualityIndex]="i"
          [actualityRouterLink]="'/actualite-v2021/.'"
        >
        </app-actuality-thumbnail-prototype>
      </ng-container>
    </div>
  </dep-page-section-actualities>
</aside> -->

<dep-scroll-to-top></dep-scroll-to-top>
<div *ngIf="isOpen">
  <dep-newsletter [newsletter] = "newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-main-footer></dep-main-footer>
