<article class="project">
    <a [href]="projectExternalLink" target="_blank" rel="noopener noreferrer" class="project__container" [attr.aria-labelledby]="'project-' + projectIndex">
        <p class="project__categories">
            <span class="project__type">{{projectType | underscoreRemover}}</span>
            <span class="project__category" *ngFor="let tag of projectTags">{{tag.name}}</span>
        </p>
        <div class="project__image">
            <div class="project__image-wrapper"><img src="{{projectImage}}" alt="{{projectaltImage}}" loading="lazy">
            </div>
        </div>
        <div class="project__remaining-days" *ngIf="projectRemainingDays">{{projectRemainingDays}}</div>
        <h3 id="project-{{projectIndex}}" class="project__title">
            {{projectTitle}}
            <dep-icon class="icon" [icon]="'icon-call_made'"></dep-icon>
        </h3>
        <div class="project__dates">
            <dep-icon class="icon" [icon]="'icon-date_range'"></dep-icon>
            <p>Se déroule du {{projectStart | date:'dd MMMM yyyy'}} au {{projectEnd | date:'dd MMMM yyyy'}}</p>
        </div>
        <p class="project__intro">{{projectIntro}}</p>
    </a>
</article>