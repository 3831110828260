import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Witness } from 'src/app/models/witness/witness.model';
import { WitnessResponse } from 'src/app/models/witness/witnessResponse.model';
import { WitnessService } from 'src/app/services/witness/witness.service';

@Component({
  selector: 'dep-temoins-list',
  templateUrl: './temoins-list.component.html',
  styleUrls: ['./temoins-list.component.scss']
})
export class TemoinsListComponent implements OnInit {
  @Input() temoins: Witness[];
  showPagination: boolean = false;
  pages: number[] = [];
  visiblePages: Observable<number[]> =  this.witnessService.pages$
  startIndex: number = 0;
  iconClose: boolean = false;
  public showDetail: boolean = false;
  @Output("showDetail") showDetailTemoin = new EventEmitter();


  public witnessResponse$: Observable<WitnessResponse> = this.witnessService.witnessResponse$
  public curentPage$: Observable<number> = this.witnessService.page$
  public pages$: Observable<number[]> = this.witnessService.pages$
  public pageSize$: Observable<number> = this.witnessService.pageSize$
  
  constructor(
    private witnessService:WitnessService
  ) { }


  ngOnInit(): void {}

  prevPage() {
    this.witnessService.prevPage();
  }

  nextPage() {
    this.witnessService.nextPage();
  }

  goToPage(page: number) {
    this.witnessService.goToPage(page);
  }

  showDetails($event: any): void {
    this.showDetail = $event;
    this.showDetailTemoin.emit(this.showDetail);
  }
}
