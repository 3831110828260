import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Offer } from 'src/app/models/offers/offer.model';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerOfferService {


  private REST_API_SERVER = '';
  private PARTNER_OFFER = '';
  

  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs,
    private sharedService: SharedService
  ) {
    this.REST_API_SERVER = environment.apiUrl;
    
  }


  public getPartnerOffers(): Observable<Offer[]> {
    const httpOptions = this.sharedService.getSecureHeaders();
    this.PARTNER_OFFER = this.ressourcePath.getPartnerOffers();
    return this.http.get<Offer[]>(this.REST_API_SERVER + this.PARTNER_OFFER, httpOptions)
  }

  public updatePartnerOffer(offer: Offer): Observable<Offer[]> {
    const httpOptions = this.sharedService.getSecureHeaders();
    this.PARTNER_OFFER = this.ressourcePath.updatePartnerOfferById(offer.id);
    return this.http.put<Offer[]>(this.REST_API_SERVER + this.PARTNER_OFFER, offer, httpOptions)
  }


}
