import { NewsletterType } from './../../models/newsletters/newsletter-type.model';
export const NEWSLETTER_TYPES: NewsletterType[] = [
    {
        id: 1,
        title: "Inscrivez-vous à la newsletter Tandem",
        text: "Restez informé par email des dernières offres d'associé deeptech",
        cookieName : "newsletter-tandem",
        url: "https://2a7ddc53.sibforms.com/serve/MUIEAH5cqT3viAOTH-d4W-8ewvApjIUoU3LzcOVQMHVIamUViZGUFvOEVGY5h3VIVbPrPsjshXRrAF6vUxKkx8OioqjJdE8TzFeN6H7P75OGj-atauGM1-eLDLV-iglZUejlRdmdKghnCENQsyUFk7VNpRLZyjRMAZqb1wH4widmFclpjTIvTqFPmSYn-7bnHV8WMcdsY7jyV43a"
      },
      {
        id: 2,
        title: "Inscrivez-vous à la newsletter",
        text: "Recevez notre newsletter directement dans votre boite mail.",
        cookieName : "newsletter-actualities",
        url: "https://2a7ddc53.sibforms.com/serve/MUIEAP3fmIoF3OzhF0IxopyZgZrH3HJiY-mzLBlV-j_zgWPJl6_qzOY01zaFi4WRarPPqETAcDcrxMOuCvqrIUlYYkYJDsiUDUL9L3dGUX65P_e0i6et-U4FJH8h7D92wBFOPoYDEX64TaOHq-SJQF4lsqM-OVO9M8Cdv01ONdqt66RKWfdn2Sora78TY12kmXSw31Q3m37BMlMl"
      },
      {
        id:3,
        title:"Inscrivez-vous à la newsletter Tango",
        text:"Restez informé par email des dernières offres d'advisor deeptech",
        cookieName:"newsletter-tango",
        url:"https://2a7ddc53.sibforms.com/serve/MUIEAH5cqT3viAOTH-d4W-8ewvApjIUoU3LzcOVQMHVIamUViZGUFvOEVGY5h3VIVbPrPsjshXRrAF6vUxKkx8OioqjJdE8TzFeN6H7P75OGj-atauGM1-eLDLV-iglZUejlRdmdKghnCENQsyUFk7VNpRLZyjRMAZqb1wH4widmFclpjTIvTqFPmSYn-7bnHV8WMcdsY7jyV43a"
      },
      {
        id:4,
        title:"Inscrivez-vous à la newsletter Mercato",
        text:"Restez informé par email des dernières offres de mission deeptech",
        cookieName:"newsletter-mercato",
        url:"https://2a7ddc53.sibforms.com/serve/MUIEAH5cqT3viAOTH-d4W-8ewvApjIUoU3LzcOVQMHVIamUViZGUFvOEVGY5h3VIVbPrPsjshXRrAF6vUxKkx8OioqjJdE8TzFeN6H7P75OGj-atauGM1-eLDLV-iglZUejlRdmdKghnCENQsyUFk7VNpRLZyjRMAZqb1wH4widmFclpjTIvTqFPmSYn-7bnHV8WMcdsY7jyV43a"
      }
]