<div
  id="tab-{{index}}-panel"
  class="tabs__tabpanel"
  role="tabpanel"
  [attr.aria-labelledby]="'tab' + index"
  tabindex="0"
  *ngIf="active"
>
  <ng-content></ng-content>
</div>
