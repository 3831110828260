<a href="{{document}}" class="document" target="_blank" rel="noopener noreferrer">
  <div class="document__icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-756 -1636) translate(0 661) translate(252 757) translate(480 190) translate(16 20.5) translate(8 8)" />
        <path fill="#FFF" d="M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71zM5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1z" transform="translate(-756 -1636) translate(0 661) translate(252 757) translate(480 190) translate(16 20.5) translate(8 8)" />
      </g>
    </svg>
  </div>

  <div>
    <p class="document__name">{{name}}</p>
    <p class="document__size">{{size}}</p>
  </div>
</a>
