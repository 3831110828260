import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-timeline-section',
  templateUrl: './timeline-section.component.html',
  styleUrls: ['./timeline-section.component.scss']
})
export class TimelineSectionComponent implements OnInit {
  @Input('sectionTitle') title: string;
  @Input('sectionDescription') description: string;

  constructor() { }

  ngOnInit(): void {
  }

}
