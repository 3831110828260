import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    private suggestions = null;
    private searchUrl = "/search"
    public suggestionsSubject$: Subject<boolean> = new Subject<boolean>();
    public searchValue : string = null;

    constructor(private http: HttpClient) {
    }

    public changeSuggestions(suggestions : any) {
        this.setSuggestions(suggestions);

        if (suggestions != null){
            this.suggestionsSubject$.next(true);
        } else {
            this.suggestionsSubject$.next(false);
        }
    }

    private getSuggestionsFromElastic(value : string): Observable<object> {
        const request = {
            request : value,
            from : 0,
            size : 50
        }

        return this.http.post(environment.apiUrlAws + this.searchUrl, request);
    }

    public updateSuggestions(value: string) {
        this.setSearchValue(value);
        this.getSuggestionsFromElastic(value).subscribe(
            results => {
                this.changeSuggestions(results);
            }
        );
    }

    public cleanSuggestions() {
        this.changeSuggestions(null);
    }

    public getSuggestions() {
        if (this.suggestions != null){
            return this.suggestions["hits"]["hits"];
        }

        return null;
    }

    public setSuggestions(suggestions : any[]){
        this.suggestions = suggestions;
    }

    public getNumberOfSuggestions() {
        if (this.suggestions != null){
            return this.suggestions["hits"]["hits"].length;
        }

        return 0;
    }

    public getSearchValue(){
        return this.searchValue;
    }

    public setSearchValue(value : string){
        this.searchValue = value;
    }
}