<div class="main-container">
    <a *ngIf="documentUrl" class="bd-card__container" [href]="documentUrl" target="_blank" rel="noopener noreferrer">
        <div class="bd-card__image"  >
            <div class="bd-card__image-wrapper">
                <img src="{{bd.imageUrl}}" alt="{{bd.altImage}}" loading="lazy" draggable="false" class="customised img-bd" >
            </div>
        </div>
        <div class="bd-card__header">
            <p>Bande dessinée</p>
            <p>publié le {{bd.publishDate  | date: 'dd/MM/yyyy'}}</p>
            <p class="bd-duration">{{bd.duration}}
                <img src="/assets/images/icones/timer.png" alt="icon-timer" class="customised" />
            </p>
        </div>
        <p class="bd-card__title">{{bd.title}}</p>
        <p class="bd-card__description">{{bd.description}}</p>
    </a>
    <a *ngIf="!documentUrl" class="bd-card__container" >
        <div class="bd-card__image" >
            <div class="bd-card__image-wrapper">
                <img src="{{bd.imageUrl}}" alt="{{bd.altImage}}" loading="lazy" draggable="false" class="customised img-bd" >
            </div>
        </div>
        <div class="bd-card__header">
            <p>Bande dessinée</p>
            <p>publié le {{bd.publishDate  | date: 'dd/MM/yyyy'}}</p>
            <p class="bd-duration">{{bd.duration}}
                <img src="/assets/images/icones/timer.png" alt="icon-timer" class="customised" />
            </p>
        </div>
        <p class="bd-card__title">{{bd.title}}</p>
        <p class="bd-card__description">{{bd.description}}</p>
    </a>
    <a *ngIf="documentUrl" [href]="documentUrl" target="_blank" rel="noopener noreferrer" download="{{fileName}}">
        <div class="bd-card__button" >
            <button class="ds-btn ds-btn--secondary">
            Télécharger
            <dep-icon [icon]="'icon-download-2'" class="icon-size"></dep-icon>
            </button>
        </div>
    </a>
</div>
