<div class="popup-container">
    <div class="popup">
        <div class="btn-container">
            <button class="btn-close" (click)="closePopup()">
                <img src="/assets/images/icones/cancel.svg" alt="icon close" class="customised" />
            </button>
        </div>
        <!-- content projection -->
        <ng-content></ng-content>
    </div>
</div>