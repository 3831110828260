import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dep-tag-background-color]'
})
export class TagBackgroundColorDirective implements OnInit{

  @Input() 
  tagName: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
      
   }
   ngOnInit(): void {
    if (this.tagName) {
        this.tagBackgroundColor();
      }       
   }

  private tagBackgroundColor() {
    switch (this.tagName) {
      case 'Valorisation de la recherche':
        this.renderer.addClass(this.el.nativeElement, 'formation__tag_type_1')
        break;
      case 'Ingénierie contractuelle':
        this.renderer.addClass(this.el.nativeElement, 'formation__tag_type_2')
        break;
      case 'Propriété intellectuelle':
        this.renderer.addClass(this.el.nativeElement, 'formation__tag_type_3') 
        break;
      case 'Logiciel':
        this.renderer.addClass(this.el.nativeElement, 'formation__tag_type_4')
        break;
      case 'Les modules transversaux':
        this.renderer.addClass(this.el.nativeElement, 'formation__tag_type_5')
        break;
    }
  }
}
