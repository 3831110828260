import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'dep-collapse-menu',
  templateUrl: './collapse-menu.component.html',
  styleUrls: ['./collapse-menu.component.scss']
})
export class CollapseMenuComponent implements OnInit {
  @Input() title : string;
  @Input() selector : string;
  @Input() isFocus : boolean = false;
  isOpen : boolean = false;
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  toggleArrow() : void {
    this.isOpen = !this.isOpen;
    let elements= document.querySelectorAll(this.selector);
    if(this.isOpen && document.querySelectorAll(this.selector)){
      elements.forEach((element) => {
        this.renderer.addClass(element, "show");
        this.renderer.removeClass(element, "hide");
      })
     
    }else {
      elements.forEach((element) => {
        this.renderer.addClass(element, "hide");
        this.renderer.removeClass(element, "show");
      }) 
    }
  }

}
