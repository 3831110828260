<section
  class="page-section"
  [ngClass]="{
    'page-section_theme_dark': sectionTheme === 'dark',
    'page-section_theme_medium': sectionTheme === 'medium',
    'page-section_theme_light': sectionTheme === 'light',
    'page-section_overflow_none': sectionOverflow === 'none',
    'page-section_overflow_right': sectionOverflow === 'right',
    'page-section_overflow_both': sectionOverflow === 'both',
    'page-section_background-image': sectionBackground
  }"
  #section
>
  <div class="page-section__background" *ngIf="sectionBackground" aria-hidden="true"></div>
  <div class="page-section__headerwrapper" *ngIf="sectionTitle || sectionRouterLink">
    <header class="page-section__header" *ngIf="sectionTitle">
      <h2 class="page-section__title">{{sectionTitle}}</h2>
      <a [routerLink]="sectionRouterLink" class="link page-section__see-more" *ngIf="sectionRouterLink">
        <dep-icon [icon]="sectionLinkIcon" class="icon" *ngIf="sectionLinkIcon"></dep-icon>
        <span>Voir plus</span>
        <!-- hidden text to complete the link label (for screen readers and bots) -->
        <span class="sr-only">de {{sectionTitle}}</span>
      </a>
    </header>
  </div>
  <div class="page-section__container">
    <div class="page-section__inner-container">
      <ng-content></ng-content>
    </div>
  </div>
</section>
