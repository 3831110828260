import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'dep-btn-help',
  templateUrl: './btn-help.component.html',
  styleUrls: ['./btn-help.component.scss']
})
export class BtnHelpComponent implements OnInit {
  buttonBottom: string = '8px';
  innerWidth: number;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const button = document.querySelector('.btn-container');
    this.innerWidth = window.innerWidth;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (this.innerWidth <= 599) {
       this.buttonBottom = '150px';
      }else{
        this.buttonBottom = '90px';
      }
    } else {
      this.buttonBottom = '8px';
    }
  }

}
