<div class="partner__container" *ngIf="partner">
    <div class="partner__image">
        <img src="{{partner.imageUrl}}" alt="{{partner.altImage}}" loading="lazy">
    </div>
    <div class="partner__content">
        <p class="partner__title">{{partner.name}}</p>
        <p class="partner__description" [innerHTML]="partner.description | markdown"></p>
        <div class="partner__btn" *ngIf="partner.link">
            <img src="/assets/images/icones/open-link.png" alt="icon open link">
            <a class="partner__link" href="{{partner.link}}" target="_blank" rel="noopener noreferrer">Site de {{partner.name}}</a>
        </div>
        <div class="partner__save-btn btn-mobile">
            <input type="checkbox" class="save-btn" [(ngModel)]="isChecked" (click)="toggle()">
        </div>
    </div>
    <div class="save">
        <div class="partner__save-btn">
            <input type="checkbox" class="save-btn" [(ngModel)]="isChecked" (click)="toggle()">
        </div>
        <p>Épingler au tableau de bord</p>
    </div>
</div>
