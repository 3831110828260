import { Component, Input, OnInit } from '@angular/core';
import { Service } from 'src/app/models/services/service.model';
import { User } from 'src/app/models/user/user';
import { EmailSenderService } from 'src/app/services/email-sender/email-sender.service';
import { AuthService } from 'src/app/shared/services/authentification/auth.service';

@Component({
  selector: 'dep-service-bloc',
  templateUrl: './service-bloc.component.html',
  styleUrls: ['./service-bloc.component.scss']
})
export class ServiceBlocComponent implements OnInit {

  @Input() service: Service ;
  @Input() user: User;

  toasterSuccess: boolean = false;
  toasterFailure: boolean = false;

  showSpinner: boolean = false;

  constructor(
    private emailSenderService: EmailSenderService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  accessRequest(){

    this.showSpinner = true;

    let body = 
      "<strong>&ensp;&ensp;&ensp;&ensp; Nom de l’acteur :  </strong>" + this.user.lastName + "<br>" +
      "<strong>&ensp;&ensp;&ensp;&ensp; Prénom de l’acteur : </strong>" + this.user.firstName + "<br>" +
      "<strong>&ensp;&ensp;&ensp;&ensp; Adresse mail de l'acteur : </strong>" + this.user.email + "<br>" +
      "<strong>&ensp;&ensp;&ensp;&ensp; Structure partenaire : </strong>" + this.user.organization + "<br>" +
      "<strong>&ensp;&ensp;&ensp;&ensp; Service auquel je souhaite accéder : </strong>" + this.service.name + "<br>";

    let subject = "Espace partenaire : Demande d’accès à un service";

    this.authService.requestHabilitation(this.service.habilitations.map(h=>h.name)).subscribe(
      response => {
        this.emailSenderService.sendEmail(body,subject).subscribe(
          response => {
            this.toasterSuccess = true;
            this.showSpinner = false;
          },
          error => {
            this.toasterFailure = true;
            this.showSpinner = false;
          }
        )
      },
      error=>{
        this.toasterFailure = true;
        this.showSpinner = false;
      }
    )
   

  }

  closeToaster(): void {
    this.toasterFailure = false;
    this.toasterSuccess = false;
  }

  truncateDescription(description: string): string {
    return description.length > 350 ? description.substring(0, 350) + '...' : description;
  }
}
