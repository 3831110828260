<div
  class="services-list" [ngClass]="{'tree-columns': isHomePage, 'four-columns': !isHomePage}"
  [class.services-list_responsive-carousel]="responsiveDisplay == 'carousel'"
  aria-live="polite"
>
  <ng-container *ngFor="let service of services, let i=index">
    <dep-service-thumbnail
      [service]="service"
      [index]="i"
      [isHomePage]="isHomePage"
    >
    </dep-service-thumbnail>
  </ng-container>
</div>