<div #memberContainer>
<div class="member_container" *ngIf="!isSelected">
    <div class="member_wrapper">
        <div class="member_content">
            <div class="temoin_image">
                <img src="{{photo}}" alt="profil" [class.opacity]="temoin.reactivationDate"
                    class="img-member customised" />
            </div>
            <div class="member_infos">
                <p>{{temoin.name}} {{temoin.lastName}}</p>
                <p>{{temoin.jobs[0].nom_poste}}</p>
                <p> {{temoin.companyName}}</p>
                <p>{{temoin.activitySectors[0].nom_secteur_entreprise}}</p>
            </div>
        </div>
        <div class="btn_cta">
            <button (click)="showDetails()" class="btn_secondary">Consulter</button>
            <p *ngIf="showDate">Sollicité le <br> {{temoin.lastSolicitationDate | date :'dd/MM/YYYY' }}</p>
        </div>
    </div>
</div>
<div [ngStyle]="{'position': showDate ? 'relative' : 'absolute'}" *ngIf="isSelected" class="wrapper">
    <div class="member_details" [ngStyle]="{'position': showDate ? 'relative' : 'absolute'}">
        <div class="member_details-wrapper" [ngStyle]="{'width.px': containerWidth }">
            <div class="btn_container">
                <button class="btn_close" (click)="closeDetails()">
                    <img src="/assets/images/icones/cancel.svg" alt="icon-cancel" class="icon customised" />
                </button>
            </div>
            <div class="member_content">
                <div class="temoin_image">
                    <img src="{{photo}}" alt="profil" [class.opacity]="temoin.reactivationDate"
                        class="img-member customised" />
                </div>
                <div class="member_infos">
                    <p>{{temoin.name}} {{temoin.lastName}}</p>
                    <p>{{temoin.jobs[0].nom_poste}}</p>
                    <p> {{temoin.companyName}}</p>
                    <p>{{temoin.activitySectors[0].nom_secteur_entreprise}}</p>
                </div>
            </div>
            <div class="member_detail">
                <p *ngIf="getCareerPaths()"><strong>Profil : </strong> {{ getCareerPaths() }} </p>
                <p *ngIf="getExpertiseSectors()"><strong>Secteur : </strong> {{ getExpertiseSectors() }} </p>
                <p *ngIf="temoin.city"><strong>Ville : </strong> {{temoin.city}} </p>
            </div>
            <div class="member_detail">
                <p *ngIf="temoin.companyName"><strong>Entreprise : </strong>{{temoin.companyName}}</p>
                <div *ngIf="temoin.companySite" class="btn_cta">
                    <a href="{{websiteUrl}}" target="_blank" rel="noopener noreferrer" class="btn_secondary">
                        <dep-icon [icon]="'icon-open_in_new'" class="icon-link" ></dep-icon>
                        Site web
                    </a>
                </div>
                <p *ngIf="temoin.companyCreationDate"><strong>Date de création : </strong>{{temoin.companyCreationDate}}
                </p>
                <p *ngIf="getInnovationContests()"><strong>Concours innovants gagnés :
                    </strong>{{getInnovationContests()}}</p>
                <p *ngIf="temoin.supportReceived"><strong>Accompagnements reçus : </strong>{{temoin.supportReceived}}
                </p>
                <p *ngIf="getActivitySectors()"><strong>Secteurs : </strong>{{getActivitySectors()}}</p>
            </div>
            <div class="btn_contact">
                <ng-container *ngIf="temoin.reactivationDate">
                    <div class="disclaimer">
                        <img src="/assets/images/icones/info.svg" alt="icon info" class="customised" />
                        <span>De nouveau disponible le {{getReactiveDate()}}</span>
                    </div>
                </ng-container>
                <div class="btn-container">
                    <button *ngIf="temoin.reactivationDate" class="ds-btn ds-btn--primary btn-contact" disabled>Contacter</button>
                    <a *ngIf="!temoin.reactivationDate" class="ds-btn ds-btn--primary btn-contact"
                        [routerLink]="['/mon-espace-partenaire/ambassador-contact.html']">Contacter
                    </a>
                    <div class="btn_cta btn_linkedin" *ngIf="temoin.linkedin">
                        <a href="{{temoin.linkedin}}" target="_blank" rel="noopener noreferrer" class="btn_secondary">
                            <dep-icon [icon]="'icon-open_in_new'" class="icon-link"></dep-icon>
                            LinkedIn
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>