import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Glossary } from 'src/app/models/Glossary/Glossary';

@Component({
  selector: 'dep-definitions-list',
  templateUrl: './definitions-list.component.html',
  styleUrls: ['./definitions-list.component.scss']
})
export class DefinitionsListComponent implements OnInit {

  @Input() glossaries: Glossary[];
  @Input() anchorTitle:string;

  constructor() { }

  ngOnInit(): void {
  }

}
