import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appStepsItem]'
})
export class StepsItemDirective {
  public stepsItemTemplate: TemplateRef<any>;

  constructor(private templateRef: TemplateRef<any>) {
    this.stepsItemTemplate = this.templateRef;
  }
}
