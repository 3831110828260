import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'dep-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {

  email: string;
  emailFound: boolean;
  public date: number;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.date = new Date().getFullYear();
  }

  onSubmit(f: NgForm): void {
    if (f.valid) {
      const formData: any = new FormData();
      formData.append('EMAIL', f.value.email);
      formData.append('OPT_IN', '1');
      formData.append('email_address_check', '');
      formData.append('locale', 'fr');

      this.http.post('https://2a7ddc53.sibforms.com/serve/MUIEAP3fmIoF3OzhF0IxopyZgZrH3HJiY-mzLBlV-j_zgWPJl6_qzOY01zaFi4WRarPPqETAcDcrxMOuCvqrIUlYYkYJDsiUDUL9L3dGUX65P_e0i6et-U4FJH8h7D92wBFOPoYDEX64TaOHq-SJQF4lsqM-OVO9M8Cdv01ONdqt66RKWfdn2Sora78TY12kmXSw31Q3m37BMlMl', formData).subscribe(
        (response) => console.log('REPONSE', response),
        (error) => console.log('ERREUR', error)
      );
      // window.location.href = 'https://sibforms.com/confirmation/success/subscription/simple?locale=fr';
      window.open(
        'https://sibforms.com/confirmation/success/subscription/simple?locale=fr',
        '_blank' // <- This is what makes it open in a new window.
      );
    }

  }

}
