export class PartnerContactForm {
    name: string;
    email: string;
    website: string;
    twitter:string;
    typeStructure: string[];
    description: string;
    region: string;
    recaptchaResponse: string;
}
