import { Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { StepsItemDirective } from 'src/app/shared/directives/steps-item.directive';

@Component({
  selector: 'dep-large-steps',
  templateUrl: './large-steps.component.html',
  styleUrls: ['./large-steps.component.scss']
})
export class LargeStepsComponent implements OnInit {
  @ContentChildren(StepsItemDirective) steps: QueryList<StepsItemDirective>;
  constructor() { }

  ngOnInit(): void {
  }

}
