<section class="home-intro">
  <div class="home-intro__content-wrapper">
    <div class="home-intro__content">
      <h2 class="page-section__title">{{titleVaguesBleu}}</h2>
      <dep-text>
        <p>
          <ng-content></ng-content>
        </p>
      </dep-text>
    </div>
  </div>
</section>