import { Component, Input, OnInit } from '@angular/core';
import { Podcast } from 'src/app/models/podcasts/podcast.model';

@Component({
  selector: 'dep-podcasts-list',
  templateUrl: './podcasts-list.component.html',
  styleUrls: ['./podcasts-list.component.scss']
})
export class PodcastsListComponent implements OnInit {
  @Input() podcasts: Podcast[];

  constructor() { }

  ngOnInit(): void {
  }

}
