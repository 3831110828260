<div class="news-carousel">
  <div class="news-carousel__carousel">
    <dep-carousel [ariaLabel]="'Dernières brèves'">
      <ng-container *ngFor="let brief of briefs; let i = index">
        <dep-news-thumbnail [brief]="brief" *appCarouselSlide>
        </dep-news-thumbnail>
      </ng-container>
    </dep-carousel>
  </div>
</div>
