import { Component, Input, OnInit } from '@angular/core';
import { BandeDessinee } from 'src/app/models/bande-dessinee/bd.model';

@Component({
  selector: 'dep-bd-thumbnail',
  templateUrl: './bd-thumbnail.component.html',
  styleUrls: ['./bd-thumbnail.component.scss']
})
export class BdThumbnailComponent implements OnInit {
  @Input() bd : BandeDessinee;

  public documentUrl;
  public fileName;

  constructor() { }

  ngOnInit(): void {
    this.documentUrl = this.bd.contents[0].documentUrl;
    let split = this.bd.contents[0].documentUrl.split('/')
    this.fileName = split[split.length-1];
  }


}
