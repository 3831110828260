import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-quote-block',
  templateUrl: './quote-block.component.html',
  styleUrls: ['./quote-block.component.scss']
})
export class QuoteBlockComponent implements OnInit {
  @Input() quoteText: string;
  @Input() quotePhoto: string;
  @Input() quoteAuthorName: string;
  @Input() quoteAuthorJob: string;
  @Input() quoteBackground: string;
  @Input() quoteRouterLink: string;
  @Input() quoteAuthorLink : string;
  @Input() quoteAuthorLinkLabel : string;
  @Input() isEuroquity:boolean=false;

  constructor() { }

  ngOnInit(): void {
  }

}
