import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-article-image-carousel-item',
  templateUrl: './article-image-carousel-item.component.html',
  styleUrls: ['./article-image-carousel-item.component.scss']
})
export class ArticleImageCarouselItemComponent implements OnInit {
  @Input() imageSrc: string;
  @Input() imageAlt: string;

  constructor() { }

  ngOnInit(): void {
  }

}
