import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {
  @Input() content : any;
  @Input() isActive: boolean;
  @Input() index: number;
  @Input() currentIndex: number;
  @Input() isSmall: boolean = false;
  @Input() isDifferentTags : boolean = false;
  @Input() isExpanded : boolean = false;

  text: string = "";
  url: string = "";

  constructor(private urlFormatter: FormatUrlPipe) {
  }

  ngOnInit(): void {

    this.text = this.content.description;

    if(this.content.description.length>250){
      this.text = this.content.description.substring(0,250);
      this.text += "...";
    }

    //EVENT
    if(this.isDifferentTags ){
      this.url = this.content.externalLink;
    }
    //ACTU
    else{
      this.url =  this.replaceUrl();
    }
      
  }

  replaceUrl(): string {
    this.content.tags.forEach((tag,index,array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length==1) {
        this.url = "/mon-espace-partenaire/les-actualites/Autres/" + this.content.id + '-' + this.urlFormatter.transform(this.content.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length >1){
        array.splice(index,1)
      } else{
        this.url = "/mon-espace-partenaire/les-actualites/" + this.urlFormatter.transform(array[0].name) + "/" + this.content.id + '-' + this.urlFormatter.transform(this.content.url) + '.html'
      }
      
    });
    
    return this.url;
  }
  
}
