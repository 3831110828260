import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormArray, FormGroupDirective, NgControl, NgForm, NgModel, Validators, FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PartnerContactForm } from 'src/app/models/partner-contact-form/partner-contact-form.model';
import { EmailSenderService } from 'src/app/services/email-sender/email-sender.service';
import { environment } from 'src/environments/environment';

declare var grecaptcha: any;
@Component({
  selector: 'dep-partner-contact',
  templateUrl: './partner-contact.component.html',
  styleUrls: ['./partner-contact.component.scss']
})
export class PartnerContactComponent implements OnInit, AfterViewInit {
  partnerContact: PartnerContactForm;
  siteKeyCaptcha: string;
  captchaError = false;
  responseCaptcha: string;
  count = 0;
  private limit = 3;
  @ViewChildren('checkers') checkboxes: ElementRef[];


  show = true;
  types = [
    'Organisme de Transfert de Technologie',
    'Acteur académique',
    'Incubateur',
    'Accélérateur',
    'Startup Studio',
    'Fonds d\'investissement',
    'Collectivité territoriale',
    'Laboratoire de recherche',
    'Plateforme mutualisée',
    'Pôle de compétitivité',
    'Autre'
  ];
  regions = [
    'Auvergne-Rhone-Alpes',
    'Bourgogne-Franche Comté',
    'Bretagne',
    'Centre-Val de Loire',
    'Grand Est',
    'Hauts-de-France',
    'Ile-de-France',
    'Normandie',
    'Nouvelle-Aquitaine',
    'Occitanie',
    'Pays de la Loire',
    'Provence-Alpes-Côte d\'Azur'
  ];

  partnerForm : FormGroup = this.fb.group({
    nameCtrl: ['', Validators.required],
    emailCtrl: ['', [Validators.required,Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
    twitterCtrl: [''],
    websiteCtrl: ['', [Validators.required,Validators.pattern('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')]],
    typeStructureCtrl: this.fb.array([]),
    regionSelectCtrl: ['', Validators.required],
    descriptionCtrl: ['', Validators.required],
  });

  get nameCtrl() { return this.partnerForm.get('nameCtrl'); }
  get emailCtrl() { return this.partnerForm.get('emailCtrl'); }
  get twitterCtrl() { return this.partnerForm.get('twitterCtrl'); }
  get websiteCtrl() { return this.partnerForm.get('websiteCtrl'); }
  get typeStructureCtrl() { return this.partnerForm.get('typeStructureCtrl') as FormArray; }
  get regionSelectCtrl() { return this.partnerForm.get('regionSelectCtrl'); }
  get descriptionCtrl() { return this.partnerForm.get('descriptionCtrl'); }

  private _subject: string = "[Réseaux Bpifrance] Ajout d'une nouvelle structure partenaire";
  private _body: string = '';
  toasterSuccess = false;
  toasterFailure = false;
  public checkedTypes: string[] = [];
  private regionInput: string = "";
  region = '';



  constructor(
    private titleService: Title,
    private router: Router,
    private meta: Meta,
    private emailSender: EmailSenderService,
    private fb: FormBuilder
    ) {
    this.siteKeyCaptcha = environment.siteKeyCaptcha;
    window["getResponseCaptcha"] = this.getResponseCaptcha.bind(this);
    this.partnerContact = new PartnerContactForm();

    if (this.router.getCurrentNavigation().extras.state)
      this.regionInput = this.router.getCurrentNavigation().extras.state.region;


  }
  ngAfterViewInit(): void {
    this.region = this.regionInput;
  }


  ngOnInit(): void {

    this.titleService.setTitle('Demande d’ajout d’une structure partenaire');
    this.meta.addTag({
      name: 'description',
      content: 'Deeptech - Demande d’ajout d’une structure partenaire',
    });
    this.initRecaptcha();

  }

  getResponseCaptcha(): void {
    this.responseCaptcha = grecaptcha.getResponse();
    if (this.responseCaptcha.length === 0) {
      this.captchaError = true;
    } else {
      this.captchaError = false;
      this.partnerContact.recaptchaResponse = this.responseCaptcha;
    }
  }
  private initRecaptcha(): void {
    setTimeout(() => {
      grecaptcha?.render('captcha_element', {
        sitekey: this.siteKeyCaptcha,
      });
    }, 1000);
  }

  showCheckboxes() {
    let checkboxes = document.getElementById("checkBoxes");

    if (this.show) {
      checkboxes.style.display = "block";
      this.show = false;
    } else {
      checkboxes.style.display = "none";
      this.show = true;
    }
  }

  limitCheckbox(event) {
    if (!event.target.checked) {
      this.count--;
      this.checkedTypes.splice(this.checkedTypes.findIndex(value => value.includes(event.target.value)), 1);
    }
    if (this.count >= this.limit) {
      event.target.checked = false;

    } else {
      if (event.target.checked) {
        this.count++;
        this.checkedTypes.push(event.target.value);
      }
    }
  };


  onSubmit() {
    this.getResponseCaptcha();
    if (this.partnerForm.valid && this.checkedTypes.length != 0 && !this.captchaError) { 
      this.checkedTypes.map(checktype => {
        return this.typeStructureCtrl.push(this.fb.control(checktype));
      })
      this.body = this.partnerForm.value;
      this.emailSender.sendEmail(this._body, this._subject).subscribe(
        (result) => {
          if (result.message === "success") {
            this.partnerForm.reset();
            this.typeStructureCtrl.clear();
            grecaptcha.reset();
            this.checkboxes.forEach(box => { box.nativeElement.checked = false });
            this.count = 0;
            this.checkedTypes = [];
            this.toasterSuccess = true;
          }
        },
        (error) => {
          console.log('error :: ' + error);
          this.toasterFailure = true;
        }
      );
    }
    else{
      Object.keys(this.partnerForm.controls).forEach(field => { 
        const control = this.partnerForm.get(field);  
        control.markAsDirty();
      });
    }
  }

  public set body(partnerForm : FormGroup) {
    this._body = `\t<strong>Informations de la structure:</strong></br>\n\t
    <ul>\n \t\t<li><strong>Nom : </strong>${this.nameCtrl.value}</li>\n\t\t
    <li><strong>Adresse mail : </strong>${this.emailCtrl.value}</li>\n
    ${this.twitterCtrl.value ? "\t\t<li><strong>Compte Twitter : </strong>" + this.twitterCtrl.value + "</li>\n" : ''}\t\t
    <li><strong>Site internet : </strong>${this.websiteCtrl.value}</li>\n\t\t
    <li><strong>Type structure : </strong>${this.typeStructureCtrl.value}</li>\n\t\t
    <li><strong>Description structure : </strong>${this.descriptionCtrl.value}</li>\n\t\t
    <li><strong>Région : </strong>${this.regionSelectCtrl.value}</li>\n\t</ul>\n`;
  }


  closeToaster(): void {
    this.toasterFailure = false;
    this.toasterSuccess = false;
  }
}
