<article class="structure">
    <div class="structure__tags">
        <div class="structure__tag" *ngFor="let tag of structureTags">{{tag.name}}</div>
    </div>
    <img src="{{structureImage}}" class="structure__image" alt="">
    <h2 class="structure__title">{{structureTitle}}</h2>
    <p class="structure__text" [innerHTML]="structureText | markdown" ></p>
    <!-- Temporary hide it -------
    <div class="structure__location">
        <dep-icon [icon]="'icon-place'"></dep-icon>
        {{structureLocation}}
    </div>
    -->
    <a [href]="structureExternalLink | safe" target="_blank" rel="noopener noreferrer" class="link structure__link">
        <span>
            Découvrir<span class="sr-only"> {{structureTitle}}</span>
        </span>
        <dep-icon class="icon" [icon]="'icon-call_made'"></dep-icon>
    </a>
</article>