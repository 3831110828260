import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'dep-article-video',
  templateUrl: './article-video.component.html',
  styleUrls: ['./article-video.component.scss']
})
export class ArticleVideoComponent implements OnInit, AfterViewInit {
  @ViewChild("player") playerRef: ElementRef;
  @ViewChild("controls") controlsRef: ElementRef;
  @Input() videoUrl: string;
  @Input() videoType: string;
  @Input() videoTitle: string;
  @Input() videoSource: string;

  player: HTMLVideoElement;
  controls: HTMLVideoElement;
  currentTimeString: string = '00:00';
  durationString: string = '00:00';
  playerStatus: string = 'stopped';
  playerId: string;

  constructor(private renderer: Renderer2) {
    this.playerId = 'video-' + Math.random().toString(36).substring(2);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.player = this.playerRef.nativeElement;
    this.controls = this.controlsRef.nativeElement;

    // Remove native <video> controls (we keep the attribute on the html tag in case our script fails)
     this.renderer.removeAttribute(this.player, 'controls');
     this.renderer.setAttribute(this.player, 'tabindex', '-1');
  }

  /**
   * Show the video controls when a button gets focus
   */
  showControls(): void {
    this.renderer.addClass(this.controls, 'is-visible');

    setTimeout(() => {
      if (this.playerStatus === "playing") {
        this.renderer.removeClass(this.controls, 'is-visible');
      }
    }, 1000);
  }

  /**
   * Alternate between play / pause when clicking the button
   */
  togglePlayPause(): void {
    if (this.player.paused) {
      this.player.play();
      this.playerStatus = "playing";
    } else {
      this.player.pause();
      this.playerStatus = "paused";
    }

    this.showControls();
  }

  /**
   * Stop video playback
   */
  stop(): void {
    this.player.pause();
    this.player.currentTime = 0;
    this.playerStatus = 'stopped';
  }

  /**
   * Adds paused status to the video when playback has ended. (Useful for older browsers)
   */
  end(): void {
    this.player.pause();
    this.playerStatus = 'stopped';
  }

  /**
   * Mute / demute the video
   */
  toggleMute(): void {
    this.player.muted = !this.player.muted;
  }

  /**
   * Get the current play time and convert it to a string
   */
  updatePlayTime(): void {
    this.currentTimeString = this.convertTimeToString(this.player.currentTime);
  }

  /**
   * Get the video duration and convert it to a string
   */
  getVideoDuration(): void {
    this.durationString = this.convertTimeToString(this.player.duration);
  }

  /**
   * Convert a time in a 00:00 format string
   * @param {number} value - a number of seconds
   * @returns {string}
   */
  convertTimeToString(value: number): string {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }
}
