import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Witness } from 'src/app/models/witness/witness.model';
import { WitnessService } from 'src/app/services/witness/witness.service';

@Component({
  selector: 'dep-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {
  @Input() temoin: Witness;
  @Input() showDate: boolean = false;
  @Input() lastWitness: boolean = false;
  @ViewChild('memberContainer') memberContainer: ElementRef;
  containerWidth: number;
  
  websiteUrl : string;

  isSelected: boolean = false;

  public photo = "/assets/images/espace-partenaire/personas/persona4.png";

  constructor(
    private witnessService: WitnessService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.containerWidth = this.memberContainer.nativeElement.offsetWidth;
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.containerWidth = this.memberContainer.nativeElement.offsetWidth;
  }

  ngOnInit(): void {
    if (this.temoin && this.temoin.photo) {
      this.photo = this.temoin.photo;
    }

    if (this.lastWitness) {
      this.witnessService.lastwitnessSelected$.subscribe(
        response => {
          if (response != null) {
            this.isSelected = response.id === this.temoin.id;
          }
          else {
            this.isSelected = false;
          }
        }
      )
    } else {
      this.witnessService.witnessSelected$.subscribe(
        response => {
          if (response != null) {
            this.isSelected = response.id === this.temoin.id;
          }
          else {
            this.isSelected = false;
          }
        }
      )
    }
    
    if (!this.temoin.companySite.startsWith('http://') && !this.temoin.companySite.startsWith('https://')) {
      this.websiteUrl = 'http://' + this.temoin.companySite;
    }else{
      this.websiteUrl = this.temoin.companySite;
    }
    
    }

  showDetails(): void {
    if (this.lastWitness){
      this.witnessService.lastwitnessSelectedBehavior.next(this.temoin);
      this.witnessService.witnessSelectedBehavior.next(null);
    }
    else{
      this.witnessService.witnessSelectedBehavior.next(this.temoin);
      this.witnessService.lastwitnessSelectedBehavior.next(null);
    }
      
  }

  closeDetails(): void {
    if (this.lastWitness)
      this.witnessService.lastwitnessSelectedBehavior.next(null);
    else
      this.witnessService.witnessSelectedBehavior.next(null);
  }

  getCareerPaths(): string {
    if (this.temoin && this.temoin.careerPaths.length > 0) {
      return this.temoin.careerPaths.map(path => path.nom_parcours).join(', ');
    }
    return null;
  }

  getActivitySectors(): string {
    if (this.temoin && this.temoin.activitySectors.length > 0) {
      return this.temoin.activitySectors.map(path => path.nom_secteur_entreprise).join(', ');
    }
    return null;
  }

  getExpertiseSectors(): string {
    if (this.temoin && this.temoin.expertiseSectors.length > 0) {
      return this.temoin.expertiseSectors.map(path => path.nom_secteur_expertise).join(', ');
    }
    return null;
  }

  getInnovationContests(): string {
    if (this.temoin && this.temoin.innovationContests.length > 0) {
      return this.temoin.innovationContests.map(path => path.nom_concours_innovation).join(', ');
    }
    return null;
  }
  getReactiveDate(): string {
    if (this.temoin && this.temoin.reactivationDate.length > 0) {
      return new Date(Date.parse(this.temoin.reactivationDate)).toLocaleDateString("fr");
    }
    return null;
  }
}
