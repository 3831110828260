import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-new-carousel-item',
  templateUrl: './new-carousel-item.component.html',
  styleUrls: ['./new-carousel-item.component.scss']
})
export class NewCarouselItemComponent implements OnInit {
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  constructor() { }

  ngOnInit(): void {
  }

}
