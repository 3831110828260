import { Component, OnInit, Input } from '@angular/core';
import { Study } from 'src/app/models/studies/study.model';

@Component({
  selector: 'dep-study-thumbnail',
  templateUrl: './study-thumbnail.component.html',
  styleUrls: ['./study-thumbnail.component.scss']
})
export class StudyThumbnailComponent implements OnInit {
  @Input() studyIndex: number;
  @Input('study') study: Study;
  
  public documentUrl : string;

  constructor() { }

  ngOnInit(): void {
    this.study.contents.find(content=>{
      if(content.documentUrl){
        this.documentUrl = content.documentUrl;
      }
      
    });
  }

}
