<div class="podcast__container" [routerLink]="replaceUrl()">
    <div class="podcast__image">
        <div class="podcast__image-wrapper">
            <img src="{{podcast.imageUrl}}" alt="{{podcast.altImage}}" class="customised img-podcast" >
        </div>
    </div>
    <div class="podcast__header">
        <p>{{podcast.tags[0].name}}</p>
        <p class="podcast__duration">{{podcast.duration}}
            <img src="/assets/images/icones/timer.png" alt="icon-timer" class="customised" />
        </p>
    </div>
    <p class="podcast__title">{{podcast.title}}</p>
    <p class="podcast__description">{{podcast.description}}</p>
    <a [routerLink]="replaceUrl()" class="podcast__link">Écouter 
        <dep-icon [icon]="'icon-call_made'" class="icon-size"></dep-icon>
    </a>
    <div class="podcast__button">
        <button class="ds-btn ds-btn--secondary" [routerLink]="'/podcasts/.'">
            Voir toutes les autres podcasts
          <dep-icon [icon]="'icon-surround_sound'" class="icon-size"></dep-icon>
        </button>
    </div>
</div>