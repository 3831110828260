import { Component, OnInit, Input } from '@angular/core';
declare const dsLib: any;

@Component({
  selector: 'dep-input-tel',
  templateUrl: './input-tel.component.html',
  styleUrls: ['./input-tel.component.scss']
})
export class InputTelComponent implements OnInit {
  @Input('inputName') name: string;
  @Input('inputLabel') label: string;

  constructor() { }

  ngOnInit(): void {
    new dsLib.InputPhone(this.name);
  }

}
