import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from "src/app/models/page/page.model";
import { ContactRequest } from "src/app/models/user/contact-request";
import { User } from 'src/app/models/user/user';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private REST_API_SERVER = '';
  private USERS_URL = '/users'
  private CONTACTS = '/contacts';


  public contactsSubject$ = new BehaviorSubject<Page<User>>(null);
  public contacts$ = this.contactsSubject$.asObservable();

  public contactSelectedSubject$ = new BehaviorSubject<User>(null);
  public contactSelected$ = this.contactSelectedSubject$.asObservable();

  public pages: number[] = []
  public pagesSubject$ = new BehaviorSubject<number[]>(this.pages);
  public pages$ = this.pagesSubject$.asObservable();

  public contactRequest = new ContactRequest();
  public contactsRequestSubject$ = new BehaviorSubject<ContactRequest>(this.contactRequest);
  public contactsRequest$ = this.contactsRequestSubject$.asObservable();

  constructor(
    private sharedService: SharedService,
    private http: HttpClient
  ) {

    this.REST_API_SERVER = environment.apiUrl;

    this.contactsRequest$.subscribe(
      response => {
        this.getContacts(response).subscribe(
          response => {
            this.contactsSubject$.next(response);
            this.pages = [];
            for (let i = 1; i <= response.totalPages; i++) {
              this.pages.push(i);
            }
            this.pagesSubject$.next(this.pages)
          }
        )
      }
    )

  }


  getContacts(contactRequest?: ContactRequest): Observable<Page<User>> {
    let params = new HttpParams();
    if (contactRequest?.page) {
      params = params.append("page", contactRequest.page);
    }
    if (contactRequest?.pageSize) {
      params = params.append("pageSize", contactRequest.pageSize);
    }
    if (contactRequest?.searchName) {
      params = params.append("searchName", contactRequest.searchName);
    }
    if (contactRequest?.structures && contactRequest.structures.length > 0) {
      params = params.appendAll({"structures": contactRequest.structures});
    }
    if (contactRequest?.favorites) {
      params = params.appendAll({"favorites": contactRequest.favorites});
    }
    const httpOptions = this.sharedService.getSecureHeaders(null,params);


    return this.http.get<Page<User>>(this.REST_API_SERVER + this.USERS_URL + this.CONTACTS, httpOptions);
  }


  public prevPage(): void {
    this.contactRequest = this.contactsRequestSubject$.getValue();
    this.contactRequest.page--;
    this.contactsRequestSubject$.next(this.contactRequest);
  }

  public nextPage(): void {
    this.contactRequest = this.contactsRequestSubject$.getValue();
    this.contactRequest.page++;
    this.contactsRequestSubject$.next(this.contactRequest);
  }

  public goToPage(page: number): void {
    this.contactRequest = this.contactsRequestSubject$.getValue();
    this.contactRequest.page = page;
    this.contactsRequestSubject$.next(this.contactRequest);
  }







}
