import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dep-cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.scss']
})
export class CardsListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
