import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Witness } from 'src/app/models/witness/witness.model';
import { WitnessResponse } from 'src/app/models/witness/witnessResponse.model';
import { WitnessService } from 'src/app/services/witness/witness.service';

@Component({
  selector: 'dep-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss']
})
export class MembersListComponent implements OnInit {
  @Input() temoins: Witness[];
  pages: number[] = [];
  visiblePages: Observable<number[]> =  this.witnessService.pages$
  startIndex: number = 0;

  public witnessResponse$: Observable<WitnessResponse> = this.witnessService.witnessResponse$
  public curentPage$: Observable<number> = this.witnessService.page$
  public pages$: Observable<number[]> = this.witnessService.pages$
  public pageSize$: Observable<number> = this.witnessService.pageSize$
  
  constructor(
    private witnessService:WitnessService
  ) { }


  ngOnInit(): void {}

  prevPage() {
    this.witnessService.prevPage();
  }

  nextPage() {
    this.witnessService.nextPage();
  }

  goToPage(page: number) {
    this.witnessService.goToPage(page);
  }

}
