import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dep-page-section-bpi',
  templateUrl: './page-section-bpi.component.html',
  styleUrls: ['./page-section-bpi.component.scss']
})
export class PageSectionBpiComponent implements OnInit {
  @Input() sectionTheme: string = 'light';
  @Input() sectionOverflow: string = 'none';
  @Input() sectionRouterLink: string;
  @Input() sectionLinkLabel: string;
  @Input() sectionLinkIcon: string;
  @Input() hideHeader: boolean = false;
  @Input() isPartnerSpace: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
