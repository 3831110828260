import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {
  @Input('redirectionTitle') title: string;
  @Input('redirectionAlignOffset') offset: string;
  @Input() redirection: string;
  @Input() externalRedirection: string;
  @Input() noDecoration: boolean;
  @Input() titleLink: string;

  constructor() { }

  ngOnInit(): void {
  }

}
