import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dep-number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss']
})
export class NumberCardComponent implements OnInit {
  @Input() number: string;
  @Input() numberLabel: string;

  constructor() { }

  ngOnInit(): void {
  }

}
