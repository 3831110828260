import { Component, Input, OnInit } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { RegionDetails } from 'src/app/models/observatory/regions-details/regions-details';
import { Podcast } from 'src/app/models/podcasts/podcast.model';
import { Video } from 'src/app/models/videos/videos.model';

@Component({
  selector: 'dep-cards-carousel',
  templateUrl: './cards-carousel.component.html',
  styleUrls: ['./cards-carousel.component.scss']
})
export class CardsCarouselComponent implements OnInit {
  @Input() startups: RegionDetails[];
  @Input() isActuality : boolean = false;
  @Input() isStartup : boolean = false;
  @Input() isVideo : boolean = false;
  @Input() isPodcast : boolean = false;
  @Input() showDesc:boolean = true;
  @Input() actualities : Actuality[];
  @Input() videos : Video[];
  @Input() podcasts : Podcast[];
  @Input() slideConfig : any;
   
  constructor() { }
 
  ngOnInit(): void
   { }
  
}