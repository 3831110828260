import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { finalize, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { CarouselSlideDirective } from '../../directives/carousel-slide.directive';

@Component({
  selector: 'dep-new-carousel',
  templateUrl: './new-carousel.component.html',
  styleUrls: ['./new-carousel.component.scss']
})
export class NewCarouselComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() carouselTitle: string;
  @Input() carouselSource: string;
  @Input('carouselContent') carouselContent: any[];
  @ViewChild('carousel') carouselView: ElementRef;
  @ContentChildren(CarouselSlideDirective) carouselSlides: QueryList<CarouselSlideDirective>;
  private destroy$ = new Subject<void>();

  // Scroll buttons
  @ViewChild('buttonPrevious') buttonPrevious: ElementRef;
  @ViewChild('buttonNext') buttonNext: ElementRef;
  carouselTotalWidth: number;
  carouselVisibleWidth: number;
  carouselScrollLeft: number;

  public swipeElement: number;
  public swipeDirection: string;
  currentButton: number = 0;
  private currentCarouselWidth: number = 0;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    if(this.carouselContent ||this.carouselTitle ||this.carouselSource ){
      document.getElementById("btnPrevious").classList.add('arrow__text');
      document.getElementById("btnNext").classList.add('arrow__text');
      document.getElementById("circle-container").classList.add('circle-position');

    }
  }

  ngAfterViewInit(): void {
    const carousel = this.carouselView.nativeElement;
    const mousedown$ = fromEvent(carousel, 'mousedown');
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(carousel, 'mouseup');

    this.setButtonStates();
    // Swipe events
    let startX = 0;
    let scrollLeft = 0;

    mousedown$.pipe(
      tap((event: any) => {
        startX = event.pageX - carousel.offsetLeft;
        scrollLeft = carousel.scrollLeft;
        this.renderer.addClass(carousel, 'active');
      }),
      switchMapTo(mousemove$.pipe(
        tap((event: any) => {
          event.preventDefault();
          const x = event.pageX - carousel.offsetLeft;
          const SCROLL_SPEED = 4;
          const walk = (x - startX) * SCROLL_SPEED;
          carousel.scrollLeft = scrollLeft - walk;
        }),
        takeUntil(mouseup$),
        finalize(() => {
          this.renderer.removeClass(carousel, 'active');
          this.calcScrollPercent()
        })
      )),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
/************************************* */
/*             Méthodes               */
/*********************************** */
  scrollToDirection(direction: number): void {
    if (this.currentButton > direction) {
      direction = -1;
      this.currentButton += -1;
    } else if (this.currentButton <= direction) {
      this.currentButton += 1;
      direction = 1;
    }
    this.setButtonStates();
    const carousel = this.carouselView.nativeElement;
    carousel.scrollBy({
      left: direction * carousel.clientWidth,
      behavior: 'smooth'
    });
  }

  calcScrollPercent(): void {
    const carousel = this.carouselView.nativeElement;
    this.carouselTotalWidth = carousel.scrollWidth;
    this.carouselVisibleWidth = carousel.clientWidth;
    this.carouselScrollLeft = carousel.scrollLeft;

    if (this.carouselScrollLeft > this.currentCarouselWidth && this.currentButton < this.carouselSlides.length - 1) {
      this.currentButton += +1;
      this.setButtonStates();
    } else if (this.currentButton > 0 && this.currentCarouselWidth > this.carouselScrollLeft) {
      this.currentButton += -1;
      this.setButtonStates();
    }
    this.currentCarouselWidth = this.carouselScrollLeft;
  }

  setButtonStates(): void {

    if (this.buttonPrevious && this.currentButton === 0) {
      this.renderer.setAttribute(this.buttonPrevious.nativeElement, 'disabled', 'true');
    } else if (this.buttonPrevious) {
      this.renderer.removeAttribute(this.buttonPrevious.nativeElement, 'disabled');
    }
    if (this.buttonNext && this.currentButton === this.carouselSlides.length - 1) {
      this.renderer.setAttribute(this.buttonNext.nativeElement, 'disabled', 'true');
    } else if (this.buttonNext) {
      this.renderer.removeAttribute(this.buttonNext.nativeElement, 'disabled');
    }
  }
}
