<div class="card_container">
    <div class="circle" *ngIf="structure.logoUrl">
        <img src="{{structure.logoUrl}}" alt="logo structure" class="logo_structure customised" />
    </div>
    <div class="circle" *ngIf="!structure.logoUrl">
        <img src="/assets/images/icones/domain_24px.svg" alt="logo structure" class="logo customised" />
    </div>
    <div class="card_wrapper">
        <div class="tags">
            <ng-container *ngFor="let prestataire of typeTags.slice(0,2)">
                <span class="prestataire">{{prestataire.titre}}</span>
            </ng-container>
            <ng-container *ngFor="let region of regionTags.slice(0,3)">
                <span class="region">{{region.titre}}</span>
            </ng-container>
        </div>
        <div class="card_content">
            <div class="structure_description">
                <p class="title">{{structure.name}}</p>
              <!-- dropdown prestations -->
              <div class="prestations__container" [class.add-border]="isListOpen">
                <button class="prestations__btn" (click)="toggleList()">
                    <span class="prestations__btn-libelle title">Prestations</span>
                    <dep-icon [icon]="'icon-keyboard_arrow_down'" [class.rotate]="isListOpen"></dep-icon>
                </button>
                <div class="prestations__list" *ngIf="isListOpen">
                    <div class="prestation" *ngFor="let prestation of prestations">
                        <p class="prestation__description">{{prestation.description}}</p>
                        <p class="prestation__price"><strong>Tarifs indicatifs : </strong>{{prestation.price}} {{prestation.bftPriceUnity.titre}}</p>
                    </div>
                </div>
              </div>
            </div>
            <div class="structure_infos" *ngIf="structure.email!=null || structure.phoneNumber!=null">
                <div class="info_wrapper" *ngIf="structure.email">
                    <strong>Adresse E-mail :</strong>
                    <div class="copy-info">
                        <p>{{structure.email}}</p>
                        <span class="btn-copy" (click)="copyText(structure.email, true)">
                            <img src="/assets/images/icones/icon-copy.svg" alt="icon copy" class="icon-copy customised" />
                            Copier
                        </span>
                    </div>
                    <div *ngIf="clipboardCopySuccess && isEmail" class="tooltip">
                        <span class="info-copied">Copié !</span>
                    </div>
                </div>
                <div class="info_wrapper" *ngIf="structure.phoneNumber">
                    <strong>Numéro de téléphone :</strong>
                    <div class="copy-info">
                        <p>{{structure.phoneNumber}}</p>
                        <span class="btn-copy" (click)="copyText(structure.phoneNumber, false)">
                            <img src="/assets/images/icones/icon-copy.svg" alt="icon copy" class="icon-copy customised" />
                            Copier
                        </span>
                    </div>
                    <div *ngIf="clipboardCopySuccess && !isEmail" class="tooltip">
                        <span class="info-copied">Copié !</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-container" [class.add-margin]="isListOpen">
            <a href="{{structure.brochureUrl}}" target="_blank" rel="noopener noreferrer" class="ds-btn ds-btn--primary btn-contact" *ngIf="structure.brochureUrl">
                Voir la présentation
            </a>
            <a href="{{structure.website}}" target="_blank" rel="noopener noreferrer" class="btn_secondary" *ngIf="structure.website">
                <dep-icon [icon]="'icon-open_in_new'" class="icon-link"></dep-icon>
                Site web
            </a>
        </div>
    </div>
</div>