<div class="glossary" aria-live="polite">
    <ng-container *ngFor="let glossary of glossaries; let i = index">
        <div id="{{glossary.keyword}}">
            <dep-definitions-list-item [anchorTitle]="anchorTitle"
                [keyword]="glossary.keyword"
                [definition]="glossary.description"
                [index]="glossary.id">
            </dep-definitions-list-item>
        </div>
    </ng-container>
</div>