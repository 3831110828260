import { Component, Input } from '@angular/core';
import { InsideboardNews } from 'src/app/models/insideboard/insideboard-news';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dep-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent {
  @Input() post: InsideboardNews;

  public ssoCodeUrl:string = environment.ssoCodeUrl;

  truncateDescription(description: string,size: number): string {
    return description.length > size ? description.substring(0, size) + '... voir plus' : description;
  }

  isUrl(text: string): boolean{
    try {
      new URL(text);
    } catch (_) {
      return false;  
    }
    return true;
  }

  getInitials(text: string): string{
    let split = text.split(' ');
    return split[0].charAt(0).toUpperCase() + split[split.length-1].charAt(0).toUpperCase();
  }
}
