import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { EventRequest } from 'src/app/models/events/eventrequest.model';
import { EventCalendar } from 'src/app/models/events/events-calendar.model';
import { Event } from 'src/app/models/events/events.model';
import { Wrapper } from 'src/app/models/wrapper/wrapper.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private REST_API_SERVER = '';
  private EVENTS = '';
  private EVENTSCALENDAR = '';
  private EVENTS_COUNT = '';

  constructor(
    private http: HttpClient,
    private ressourcePath: GlobalURLs,
    private datePipe: DatePipe,
  ) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getEvents(eventRequest: EventRequest): Observable<Wrapper<Event>> {
    this.EVENTS = this.ressourcePath.getEvents();
    let params = new HttpParams();
    let date = this.datePipe.transform(eventRequest.startDate, 'yyyy-MM-dd');



    params = params.append("monthly", eventRequest.monthly);

    if (eventRequest.startDate) {
      params = params.append("startDate", date);
    }
    if (eventRequest.endDate) {
      params = params.append("endDate", this.datePipe.transform(eventRequest.endDate, 'yyyy-MM-dd'));
    }
    if (eventRequest.page) {
      params = params.append("page", eventRequest.page);
    }
    if (eventRequest.pageSize) {
      params = params.append("pageSize", eventRequest.pageSize);
    }
    if (eventRequest.sectorIds) {
      params = params.appendAll({ "sectorIds": eventRequest.sectorIds });
    }
    if (eventRequest.excludeTagId) {
      params = params.append("excludeTagId", eventRequest.excludeTagId);
    }
    if (eventRequest.tagId) {
      params = params.append("tagIds", eventRequest.tagId);
    }
    if (eventRequest.regionalBranchIds && eventRequest.regionalBranchIds.length > 0) {
      params = params.appendAll({ "regionalBranchIds": eventRequest.regionalBranchIds });
    }
    if (eventRequest.technicalsTagCodes && eventRequest.technicalsTagCodes.length > 0) {
      params = params.appendAll({ "technicalsTagCodes": eventRequest.technicalsTagCodes });
    }
    if (eventRequest.formatIds)
      params = params.appendAll({ "formatIds": eventRequest.formatIds });
    if (eventRequest.regionEspacePartenaireFilterIds)
      params = params.appendAll({ "regionEspacePartenaireFilterIds": eventRequest.regionEspacePartenaireFilterIds })

    return this.http.get<Wrapper<Event>>(this.REST_API_SERVER + this.EVENTS, { params });
  }

  public getEventsCalendar(date: string, excludeTagId: number, startIndex: number | undefined, limit: number | undefined, technicalTagCodes?: string[]): Observable<EventCalendar[]> {

    let params = new HttpParams()
      .set('date', date)
    if (startIndex) {
      params = params.append('index', startIndex);
    }
    if (limit) {
      params = params.append('limit', limit);
    }
    if (excludeTagId) {
      params = params.append('excludeTagId', excludeTagId);
    }
    if (technicalTagCodes)
      params = params.appendAll({ 'technicalTagCodes': technicalTagCodes });

    this.EVENTSCALENDAR = this.ressourcePath.getEventsCalendar();
    return this.http
      .get<EventCalendar[]>(this.REST_API_SERVER + this.EVENTSCALENDAR, { params })
      .pipe(
        map(
          (eventCalendars: EventCalendar[]) => eventCalendars.map(
            (eventCalendar: EventCalendar) => {
              const elem: EventCalendar = new EventCalendar;
              elem.year = eventCalendar.year;
              elem.month = new Date(eventCalendar.year, eventCalendar.month.valueOf() - 1, 1);
              elem.count = eventCalendar.count;
              return elem;
            }
          )
        )
      );
  }
  /**
   * 
   * @param actualDate 
   * @returns Count of events with date >= to today
   */
  public getEventsCount(actualDate: string): Observable<number> {
    let params = new HttpParams().set('actualDate', actualDate);
    this.EVENTS_COUNT = this.ressourcePath.getEventsCount();
    return this.http.get<number>(this.REST_API_SERVER + this.EVENTS_COUNT, { params });
  }
}