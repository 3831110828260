import { Injectable } from '@angular/core';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Forum } from 'src/app/models/forums/forum.model';


@Injectable({
  providedIn: 'root'
})
export class ForumService {
  private restApiServer = '';
  private forums = '';
  private forumsCount = '';
  
  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.restApiServer = environment.apiUrl;
  }

  public getForums(start: number, limit: number, tagIds?: number[]): Observable<Forum[]> {

    let params = new HttpParams()
      .set('index', start.toString())
      .set('limit', limit.toString());

    if (tagIds && tagIds.length>0) {
      params = params.appendAll({"tagIds":  tagIds});
    }

    this.forums = this.ressourcePath.getForums();
    
    return this.http.get<Forum[]>(this.restApiServer + this.forums, { params });
  }

  public getForumById(id: number): Observable<Forum> {
    this.forums = this.ressourcePath.getForumById(id);
    return this.http.get<Forum>(this.restApiServer + this.forums);;
  }

  public getForumsCount(tagIds?: number[]): Observable<number> {
    let params = new HttpParams();
    if (tagIds && tagIds.length>0) {
      params = params.appendAll({ "tagIds": tagIds });
    }
    this.forumsCount = this.ressourcePath.getForumsCount();
    return this.http.get<number>(this.restApiServer + this.forumsCount, { params });
  }

}
