<!-- Gallery of studies -->
<div
  class="studies-list"
  [class.studies-list_responsive-carousel]="responsiveDisplay == 'carousel'"
  aria-live="polite"
>
  <ng-container *ngFor="let study of studies; let i = index">
    <dep-study-thumbnail
      class="studies-list__study"
      [studyIndex]="i"
      [study]="study">
    </dep-study-thumbnail>
  </ng-container>
</div>
