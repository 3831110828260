import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { CookieNewsletterService } from 'src/app/services/cookie-newsletter/cookie-newsletter.service';
import { NewsletterType } from './../../../models/newsletters/newsletter-type.model';

@Component({
  selector: 'dep-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit {
  email: string;
  emailFound: boolean;

  public show: boolean;
  @Output() closeNewsletter = new EventEmitter<boolean>();
  @Input() newsletter: NewsletterType;
  @Input() showBorder: boolean = false;

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private cookieNewsletterService: CookieNewsletterService
  ) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit() {}

  onSubmit(f: NgForm): void {
    if (f.valid) {
      const formData: any = new FormData();
      formData.append('EMAIL', f.value.email);
      formData.append('OPT_IN', '1');
      formData.append('email_address_check', '');
      formData.append('locale', 'fr');

      this.http.post(this.newsletter.url, formData).subscribe(
        (response) => console.log('REPONSE', response),
        (error) => console.log('ERREUR', error)
      );

      window.open(
        'https://sibforms.com/confirmation/success/subscription/simple?locale=fr',
        '_blank' // <- This is what makes it open in a new window.
      );
      this.close();
    }
  }

  public close(): void {
    this.show = false;
    this.cookieNewsletterService.setCookie(this.newsletter.cookieName, 'false');
    this.closeNewsletter.emit(this.show);
  }
}
