import { Component, OnInit, Input } from '@angular/core';
import { Contest } from 'src/app/models/contests/contest.model';

@Component({
  selector: 'dep-contests-list',
  templateUrl: './contests-list.component.html',
  styleUrls: ['./contests-list.component.scss']
})
export class ContestsListComponent implements OnInit {
  @Input('contests') contests:Contest[];
  @Input() responsiveDisplay: string;
  constructor() { }

  ngOnInit(): void {
    
  }

}
