<div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let actu of actualities">
        <a class="carousel-item" href="#" target="_blank" rel="noopener noreferrer">
          <div class="card-content">
            <span class="date-wrapper">
              <dep-icon class="icon" [icon]="'icon-time'"></dep-icon>
              <p>{{(actu.date | nbDaysElapsed)}}</p>
            </span>
            <p class="title">{{ actu.title }}</p>
            <p class="description" [innerHTML]="actu.description | slice:0:100 "></p>
          </div>
          <div class="card-footer">
            <p>{{actu.author}}</p>
            <a href="{{actu.link}}" target="_blank" rel="noopener noreferrer">Lire
              <dep-icon class="icon" [icon]="'icon-open_in_new'" style="font-size: 16px;"></dep-icon>
            </a>
          </div>
        </a>
      </div>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
    <!-- Add Navigation -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>