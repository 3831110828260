import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'dep-finance-project',
  templateUrl: './finance-project.component.html',
  styleUrls: ['./finance-project.component.scss'],
})
export class FinanceProjectComponent implements OnInit {
  constructor(
    private viewportScroller: ViewportScroller,
    private meta: Meta,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Envie d\'entreprendre - panorama des financements');
    this.meta.addTag({
      name: 'description',
      content:
        'Financer son entreprise et le développement d\'un produit technologique disruptif est un frein identifié par beaucoup d\'entrepreneurs, il existe pourtant tout un panel de solutions.',
    });
  }

  // Pour le scroll vers l'ancre
  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
