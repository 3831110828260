<button type="button" class="offers-header__back" area-label="back button" (click)="back()">
    <dep-icon class="icon" [icon]="'icon-arrow_back_ios'"></dep-icon>
    Retour
  </button>
  
  <div class="offers-header__breadcrumb">
    <ng-content select="[breadcrumb]"></ng-content>
  </div>
  
  <!-- <p class="offers-header__last-update">Mis à jour {{offersUpdate}}</p> -->
  <h1 class="offers-header__title">{{offersTitle}}</h1>
  
  <ng-container *ngIf="!isAdvisor">
  <div class="offers-header__total" >{{offersTotal}} offre<ng-container *ngIf="offersTotal > 1">s</ng-container> trouvée<ng-container *ngIf="offersTotal > 1">s</ng-container></div>
  </ng-container>

  <!-- si c'est la page advisor on doit remplacer " offre" par "projet" / "trouvée" par "trouvé"-->
  <ng-container *ngIf="isAdvisor">
  <div class="offers-header__total">{{offersTotal}} projet<ng-container *ngIf="offersTotal > 1">s</ng-container> trouvé<ng-container *ngIf="offersTotal > 1">s</ng-container></div>
  </ng-container>