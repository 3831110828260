import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-article-related-content',
  templateUrl: './article-related-content.component.html',
  styleUrls: ['./article-related-content.component.scss']
})
export class ArticleRelatedContentComponent implements OnInit {
  @Input() relatedContent: string;
  @Input('relatedLinkContent') relatedLinkContent: string;

  constructor() { }

  ngOnInit(): void {
  }

}
