import { Component, Input, OnInit } from '@angular/core';
import { Brief } from 'src/app/models/briefs/brief.model';

@Component({
  selector: 'dep-news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrls: ['./news-carousel.component.scss']
})
export class NewsCarouselComponent implements OnInit {
  @Input() briefs: Brief[];

  constructor() { }

  ngOnInit(): void {
  }

}
