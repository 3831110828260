<article class="study">
  <div class="study__container">
    <p class="study__categories">
      <span class="study__category" *ngFor="let tag of study.tags">{{tag.name}}</span>
    </p>
    <div class="study__image">
      <div class="study__image-wrapper"><img src="{{study.imageUrl}}" alt="{{study.altImage}}" loading="lazy"></div>
    </div>
    <ng-container *ngIf="documentUrl">
      <a href="{{documentUrl}}" class="study__file" target="_blank" rel="noopener noreferrer" attr.aria-describedby="study-{{studyIndex}}">
        <dep-icon [icon]="'icon-download'" class="icon"></dep-icon>
        <span class="sr-only">Télécharger l’étude</span>
      </a>
    </ng-container>

    <h3 id="study-{{studyIndex}}" class="study__title">
      <a [routerLink]="['/etudes', (study.tags[0].name | formatUrl), study.id +'-'+ study.url + '.html']">{{study.title}}</a>
    </h3>
    <p class="study__date">
      {{study.publishDate | date :'d MMMM' | titlecase}}
      <span>&nbsp;-&nbsp;</span>
    </p>
    <p class="study__duration">{{study.readingTime}}</p>
  </div>
</article>
