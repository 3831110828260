<div class="popup-cgu_container transparent" [ngClass]="{'hide':close}">
    <div class="popup-cgu_wrapper">
      <div class="popup-cgu_content">
        <div class="popup-cgu_header">
          <p>Conditions générales d'utilisation</p>
        </div>
        <div class="popup-cgu_pdf">
          <dep-pdf-viewer pdfPath="{{pdfUrl}}?#zoom=90" [mediumSize]="true"></dep-pdf-viewer>
        </div>
        <div class="popup-cgu_checkbox">
          <input type="checkbox" name="checkbox" (click)="disableButton()" >
          <span>En cochant le bouton « ACCEPTER », je reconnais avoir pris connaissance et accepter en mon nom et au nom de l’Organisme que je représente les présentes Conditions Générales d’Utilisation, et j’atteste avoir pris connaissance de l’article 9 relatif à la protection de mes données.<br/><br/>
                L'acceptation des CGU est obligatoire pour accéder au site.
          </span>
        </div>
        <div class="popup-cgu_buttons">
          <button class="ds-btn ds-btn--secondary" area-label="refuser" (click)="refuseCGU()" >REFUSER</button>
          <button class="ds-btn ds-btn--primary" area-label="accepter" (click)="acceptCGU()" [routerLink]="" [disabled]="disabled">ACCEPTER</button>
        </div>
      </div>
    </div>
  </div>
