import { Component, Input, OnInit } from '@angular/core';
import { JobOffer } from 'src/app/models/job-offers/job-offer.model';

@Component({
  selector: 'dep-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {
  @Input() offer : JobOffer ;
  @Input() urlOffer : string;
  constructor() { }

  ngOnInit(): void {
  }

  truncateDescription(description: string): string {
    const truncated = description.length > 355 ? description.substring(0, 355) + '<span>...</span>' : description;
    return truncated.replace(/<p>/g, '<p class="change-margin">').replace(/<li>/g, '<li class="change-margin">').replace(/<ul>/g, '<ul class="change-margin">')
}
}
