import { Component, Input, OnInit } from '@angular/core';
import { Tag } from 'src/app/models/tags/tag.model';

@Component({
  selector: 'dep-project-thumbnail',
  templateUrl: './project-thumbnail.component.html',
  styleUrls: ['./project-thumbnail.component.scss']
})
export class ProjectThumbnailComponent implements OnInit {
  @Input() projectType: string;
  @Input() projectTags: Tag[];
  @Input() projectImage: string;
  @Input() projectaltImage: string;
  @Input() projectRemainingDays: string;
  @Input() projectTitle: string;
  @Input() projectStart: string;
  @Input() projectEnd: string;
  @Input() projectIntro: string;
  @Input() projectIndex: number;
  @Input() projectExternalLink: string;
  constructor() { }

  ngOnInit(): void {
  }

}
