import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from 'src/app/models/page/page.model';
import { ContactRequest } from 'src/app/models/user/contact-request';
import { User } from 'src/app/models/user/user';
import { ContactsService } from 'src/app/services/contacts/contacts.service';

@Component({
  selector: 'dep-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  @Input() contacts: User[];

  // pages: number[] = [];
  // startIndex: number = 0;

  visiblePages: Observable<number[]> =  this.constactsServce.pages$

  public pageContacts$: Observable<Page<User>> = this.constactsServce.contacts$;

  public pageContact: Page<User> ;
  
  constructor(
    private constactsServce: ContactsService
  ) { 
    this.pageContacts$.subscribe(
      response => {
        if(response !=null){
          this.contacts = response.content;
          this.pageContact = response;
        }
      }
    )
  }


  ngOnInit(): void {
    let contactRequest: ContactRequest = {
      page: 0,
      pageSize: 20
    }

    this.constactsServce.contactsRequestSubject$.next(contactRequest);
  }

  prevPage() {
    this.constactsServce.prevPage();
  }

  nextPage() {
    this.constactsServce.nextPage();
  }

  goToPage(page: number) {
    this.constactsServce.goToPage(page-1);
  }

}