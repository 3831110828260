<div class="select-multiple" [ngClass]="{'select-multiple-s': isSmallSelect, 'select-form': isForm}">
    <label>{{label}}</label>
    <div class="select-wrapper" #selectWrapper [ngClass]="{'select-wrapper-s': isSmallSelect ,  'select-form': isForm }">
        <div class="select-header" [ngClass]="{ 'selected': isAllSelected, 'placeholder': selectedOptions.length === 0 ,'select-header-s': isSmallSelect,  'select-form': isForm}" (click)="toggleOptions()">
          <span *ngIf="selectedOptions.length === 0" class="placeholder">{{ placeholder }}</span>
          <span id="selected" *ngIf="selectedOptions.length > 0">{{ getSelectedOptionsDisplay() }}</span>
          <button class="customised" (click)="toggleOptions(); $event.stopPropagation()">
            <img src="/assets/images/icones/arrow.svg" alt="arrow" [class.rotate]="isOptionsOpen" class="arrow customised"/>
         </button>
        </div>
        <div class="select-options" [class.open]="isOptionsOpen" >
            <div class="select-all option" [ngClass]="{ 'selected': isAllSelected }" (click)="toggleSelectAllOptions()">
                {{ isAllSelected ? 'RÉINITIALISER' : 'TOUT SELECTIONNER' }}
              </div>
          <div class="option" [ngClass]="{ 'selected': isSelected(option) }" *ngFor="let option of options" (click)="selectOption(option)">
            {{ option.description }} <span *ngIf="showNumbers">({{option.total}})</span>
          </div>
        </div>
      </div>
  </div>