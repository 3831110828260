<section class="video-player" *ngIf="videoUrl && videoType">
  <video
    [id]="playerId"
    class="video-player__video"
    preload="metadata"
    controls
    (timeupdate)="updatePlayTime()"
    (ended)="end()"
    (durationchange)="getVideoDuration()"
    #player
  >
    <source [src]="videoUrl" [type]="videoType">
    <!-- <source src="source.webm" type="video/webm"> -->
    <p>Votre navigateur ne supporte pas la lecture de vidéos. Voici un <a [href]="videoUrl">lien vers la video</a> en remplacement.</p>
  </video>

  <!-- Video title, timer and buttons -->
  <div class="video-player__controls" [class.is-visible]="playerStatus !== 'playing'" #controls>
    <!-- Title -->
    <p class="video-player__title">{{videoTitle}}</p>

    <!-- Source -->
    <p class="video-player__source">{{videoSource}}</p>

    <!-- Timer -->
    <div class="video-player__timer"><time>{{currentTimeString}}</time> - <time>{{durationString}}</time></div>

    <!-- Buttons -->
    <div class="video-player__buttons">
      <!-- Play / pause button -->
      <button
        class="video-player__button"
        (click)="togglePlayPause()"
        (focus)="showControls()"
        [title]="player.paused ? 'Lire' : 'Pause'"
        [attr.aria-controls]="playerId"
        area-label="play/pause button"
      >
        <ng-container *ngIf="player.paused">
          <dep-icon class="icon" [icon]="'icon-play'"></dep-icon>
          <div class="sr-only">Lire</div>
        </ng-container>
        <ng-container *ngIf="!player.paused">
          <dep-icon class="icon" [icon]="'icon-pause'"></dep-icon>
          <div class="sr-only">Pause</div>
        </ng-container>
      </button>

      <!-- Stop button -->
      <button
        class="video-player__button"
        (click)="stop()"
        (focus)="showControls()"
        [title]="'Arrêter'"
        [attr.aria-controls]="playerId"
        area-label="stop button"
      >
        <dep-icon class="icon" [icon]="'icon-stop'"></dep-icon>
        <div class="sr-only">Arrêter</div>
      </button>

      <!-- Mute / unmute button -->
      <button
        class="video-player__button"
        (click)="toggleMute()"
        (focus)="showControls()"
        [title]="player.muted ? 'Activer le son' : 'Désactiver le son'"
        [attr.aria-controls]="playerId"
        area-label="mute/unmute button"
      >
        <ng-container *ngIf="player.muted">
          <dep-icon class="icon" [icon]="'icon-volume_up'"></dep-icon>
          <div class="sr-only">Activer le son</div>
        </ng-container>
        <ng-container *ngIf="!player.muted">
          <dep-icon class="icon" [icon]="'icon-volume_off'"></dep-icon>
          <div class="sr-only">Désactiver le son</div>
        </ng-container>
      </button>
    </div>
  </div>
</section>
