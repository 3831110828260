import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {
  @Input('resourceTitle') title: string;
  @Input('resourceAlignOffset') offset: string;
  @Input() resource: string;
  @Input() noDecoration: boolean;
  @Input() externalRedirection: string;
  @Input() titleLink: string;


  constructor() { }

  ngOnInit(): void {
  }

}
