import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { Dashboard } from 'src/app/models/observatory/dashboard/dashboard';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private ObsUrl = '';


  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService,
    globalUrls: GlobalURLs
  ) {
    this.ObsUrl = environment.apiUrlAws + globalUrls.apiObsDashboard;
  }

  /**
   * getDashboard
   */
  public getDashboard(): Observable<Dashboard> {

    const httpOptions = this.sharedService.getSecureHeaders();

    return this.httpClient.get<Dashboard>(this.ObsUrl, httpOptions);
  }


}