import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/models/events/events.model';

@Component({
  selector: 'dep-event-thumbnail',
  templateUrl: './event-thumbnail.component.html',
  styleUrls: ['./event-thumbnail.component.scss']
})
export class EventThumbnailComponent implements OnInit {

  @Input() event: Event;
  @Input() eventIndex: number;
  displayStyle: number = 1;

  constructor() { }

  ngOnInit(): void {
    if(this.event!=null && this.event.endDate!=null){
      let start = new Date(this.event.date);
      let end = new Date(this.event.endDate);
      if(start.getFullYear()==end.getFullYear() && start.getMonth()==end.getMonth()){
        this.displayStyle = 2;
      }
      else{
        this.displayStyle = 3;
      }
    }
  }


}
