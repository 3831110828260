import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Faq } from 'src/app/models/faqs/faq.model';
import { DesireUndertakeService } from 'src/app/services/desire-undertake/desire-undertake.service';


@Component({
  selector: 'dep-desire-undertake-header',
  templateUrl: './desire-undertake-header.component.html',
  styleUrls: ['./desire-undertake-header.component.scss']
})
export class DesireUndertakeHeaderComponent implements OnInit {

  @Input() faqCategories : Faq[];

  public selectedFaqCategory$ = this.desireUndertakeService.selectedFaq$

  constructor( 
    private location: Location,
    private desireUndertakeService: DesireUndertakeService
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterContentChecked(){
    if(this.faqCategories.length>0){
      this.selectedFaqCategory$.subscribe((faq)=>{
        this.refreshFaqColor(faq);
      })
    }
  }

  back(): void {
    this.location.back();
  }
  
  setFaqCategory(faq : Faq ): void{
    this.desireUndertakeService.setSelectedFaq(faq);
  }

  refreshFaqColor(faq : Faq): void{
    this.faqCategories.forEach((f)=>{
      let selector = "#faq"+f.id;
      let faqSelected = document.querySelector(selector);
      if(faq.id == f.id){
        faqSelected?.classList.add('is-active');
      }
      else{
        faqSelected?.classList.remove('is-active');
      }      
    })
  }

}
