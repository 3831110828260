<div class="pagination-container">
    <div class="pagination-info">
        <p>{{ getStartIndex() }} - {{ getEndIndex() }} sur {{ totalItems }} {{itemsName}}</p>
    </div>

    <div class="pagination-wrapper">
        <button (click)="onPrevious()" area-label="previous button"  [disabled]="currentPage === 1">
            <img src="/assets/images/icones/arrow.svg" class="pagination-icon previous customised" alt="icon previous">
        </button>
        <button *ngFor="let page of getPagesWithEllipsis()" (click)="onPageClick(page)"
            [class.active]="currentPage === page">
            <p>{{ page }}</p>
        </button>
        <button (click)="onNext()" area-label="next button"  [disabled]="currentPage === totalPages">
            <img src="/assets/images/icones/arrow.svg" class="pagination-icon customised" alt="icon next">
        </button>
    </div>
</div>