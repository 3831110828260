import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dep-article-youtube-video',
  templateUrl: './article-youtube-video.component.html',
  styleUrls: ['./article-youtube-video.component.scss']
})
export class ArticleYoutubeVideoComponent implements OnInit {
  @Input() videoUrl: string;
  @Input() videoTitle: string;

  /*********VARIABLES *****/
  public safeUrl: any;

  constructor(private _sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    this.videoUrl = this.videoUrl.replace("watch?v=", "embed/");
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);    
  }

}
