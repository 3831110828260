import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dep-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input('inputName') name: string;
  @Input('inputLabel') label: string;
  @Input('inputPlaceholder') placeholder: string;
  @Input('inputMaxlength') maxlength: string;
  @Input('inputInfo') info: string;
  @Input('inputRequired') required: boolean;
  @Input('inputError') error: boolean;
  @Input('inputErrorMessage') errorMessage: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
