<article>
    <a [routerLink]="urlOffer" class="offer-list-item">
        <div class="offer-list-item__image">
            <img *ngIf="offer.logo" [src]="offer.logo" [alt]="'Logo de context'">
            <img *ngIf="offer.logo === null" src="assets/images/logo_tandem.jpg" alt="logo tandem">
        </div>
        <div class="info">
        <p class="offer-list-item__info offer-list-item__date">
            <dep-icon class="icon" [icon]="'icon-time'"></dep-icon>{{(offer.creationDate| nbDaysElapsed)}}
        </p>
        <p class="offer-list-item__info offer-list-item__location">
            <dep-icon class="icon" [icon]="'icon-place'"></dep-icon>{{offer.city}}
        </p>
        </div>
        <h3 class="offer-list-item__title">{{offer.projectName}}</h3>
        <p class="offer-list-item__intro" *ngIf="offer.jobDescription" [innerHTML]="truncateDescription(offer.jobDescription) | markdown"></p>
    </a>
</article>