<div
  class="events-list"
  [class.events-list_responsive-carousel]="responsiveDisplay == 'carousel'"
  aria-live="polite"
>
  <ng-container *ngFor="let event of events; let i = index">
    <dep-event-thumbnail
    [event]="event"
    [eventIndex]="i"
    >
    </dep-event-thumbnail>
  </ng-container>
</div>
