<article class="briefs-list-item">
  <p class="briefs-list-item__date">
    <dep-icon class="icon" [icon]="'icon-time'"></dep-icon>
    <span>{{(brief.publishDate | nbDaysElapsed)}}</span>
  </p>
  <div class="briefs-list-item__source">
    <img src="{{brief.logoUrl}}" alt="">
  </div>
  <div class="briefs-list-item__image">
    <div class="briefs-list-item__image-wrapper"><img src="{{brief.imageUrl}}" alt="{{brief.altImage}}" loading="lazy"></div>
  </div>
  <p class="briefs-list-item__categories">
    <span class="briefs-list-item__category" *ngFor="let tag of brief.tags">{{tag.name}}</span>
  </p>
  <h3 class="briefs-list-item__title"><a href="{{brief.externalLink}}" target="_blank" rel="noopener noreferrer">{{brief.title}}</a></h3>
  <a href="{{brief.externalLink}}" target="_blank" rel="noopener noreferrer" class="link briefs-list-item__link">
    <span>Lire</span>
    <!-- hidden text to complete the link label (for screen readers and bots) -->
    <span class="sr-only">la brève : {{brief.title}}</span>
    <dep-icon class="icon" [icon]="'icon-call_made'"></dep-icon>
  </a>
</article>
