import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { BreadcrumbItemDirective } from '../../../shared/directives/breadcrumb-item.directive';

@Component({
  selector: 'dep-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbLabel: string;
  @Input() btnLabel: string;
  @Input() isBtn : boolean = false;
  @ContentChildren(BreadcrumbItemDirective, { descendants: true }) breadcrumbItems: QueryList<BreadcrumbItemDirective>;

  constructor() { }

  ngOnInit(): void {
  }
}
