import { HabilitationStrapiCopy } from "../user/user"
import { Link } from "./link.model"


export class DirectAccess {
    id: number
    title: string
    isAuthorized?: boolean
    directAccessLinks: Link[]
    habilitations: HabilitationStrapiCopy[]
}