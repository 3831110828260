<div
  class="partners-list"  [ngClass]="{'tree-columns': isHomePage, 'four-columns': !isHomePage}"
  [class.partners-list_responsive-carousel]="responsiveDisplay == 'carousel'"
  aria-live="polite"
>
  <ng-container *ngFor="let partner of partners">
    <dep-partner-thumbnail
      [partner]="partner"
    >
    </dep-partner-thumbnail>
  </ng-container>
</div>